import { EnvUtil } from '../../utils/env-util';

import { request, createPostBody } from './hubspot-request-util';

const HUBSPOT_FORM_API = 'HUBSPOT_FORM_API';

export const postAccessOurNetworkInquireWithAxiom = async fields =>
  request(
    HUBSPOT_FORM_API,
    EnvUtil.hubspotAccessOurNetworkInquireWithAxiom,
    'POST',
    createPostBody(fields, 'Access Our Network')
  );

export const postExternalSearchReachOut = async fields => {
  return request(
    HUBSPOT_FORM_API,
    EnvUtil.hubspotExternalSearchReachOut,
    'POST',
    createPostBody(fields, 'Talent Page')
  );
};

export const postDigitalBenchRequestRate = async fields =>
  request(
    HUBSPOT_FORM_API,
    EnvUtil.hubspotDigitalBenchRequestRate,
    'POST',
    createPostBody(fields, 'Digital Bench')
  );

export const postDigitalBenchRequestUpdate = async fields =>
  request(
    HUBSPOT_FORM_API,
    EnvUtil.hubspotDigitalBenchRequestUpdate,
    'POST',
    createPostBody(fields, 'Digital Bench')
  );

export const postDigitalBenchAskUsHow = async fields =>
  request(
    HUBSPOT_FORM_API,
    EnvUtil.hubspotDigitalBenchAskUsHow,
    'POST',
    createPostBody(fields, 'Digital Bench')
  );

export const postAccessOurNetworkConsult = async fields =>
  request(
    HUBSPOT_FORM_API,
    EnvUtil.hubspotAccessOurNetworkConsult,
    'POST',
    createPostBody(fields, 'Access Our Network')
  );

export const postInterstitialDataCollection = async fields =>
  request(
    HUBSPOT_FORM_API,
    EnvUtil.hubspotAltInterstitialFormUrl,
    'POST',
    createPostBody(fields, 'ALT Interstitial')
  );
