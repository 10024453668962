import styled from 'styled-components';

import { PALETTE } from '../../themes/constants';

export const TSROMColoredText = styled.span`
  color: ${PALETTE.theme.primary} !important;
`;

export const TSROMCustomError = styled.div`
  color: ${PALETTE.theme.danger};
  font-size: 0.875rem;
  font-weight: bold;
`;
