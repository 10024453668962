import { LocalStorage } from '@axiom/ui';

const STORE_NAMES = {
  paginationActionDate: 'pagination_action_date',
};

export const localStorageUtil = {
  paginationActionDate: (defaults = null) =>
    LocalStorage(STORE_NAMES.paginationActionDate, defaults),
};
