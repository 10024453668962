import { generateCandidateSlug } from '@axiom/utils';

import { EnvUtil } from './env-util';

export const getShareUrl = (candidate, practiceAreas) =>
  `/access-legal-talent/${generateCandidateSlug(candidate, practiceAreas)}`;

export const getSharePath = (candidate, practiceAreas) => {
  return `/access-legal-talent/${generateCandidateSlug(
    candidate,
    Object.values(practiceAreas)
  )}`;
};

export const generateBenchUrl = accountId => `/bench?account=${accountId}`;

export const generateClientProjectsUrl = accountId =>
  `/projects/account/${accountId}`;

export const generateAHQOpportunityURL = opportunityId =>
  `${EnvUtil.envoyUrl}/opportunity-detail/${opportunityId}`;

export const LinkUtil = {
  getSharePath,
};
