import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
  ModalHeader,
  ModalSection,
  ModalFooter,
} from '@axiom/ui';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { ShareLinkModalStore } from '../../stores/share-link-modal-store';

import { ShareLinkModalStyledText } from './ShareLinkModalStyles';

const ShareLinkModalComponent = ({ shareLinkModalState }) => (
  <StoreStateTreatment
    name="SHARE_LINK_MODAL_STORE"
    isModalState
    storeState={shareLinkModalState}
    renderLoadedView={({ data: { copyText, success } }) => (
      <Modal name="SHARE_LINK" size="confirm">
        <ModalHeader onClose={ShareLinkModalStore.closeModal}>
          {success ? 'Link Copied' : 'Share Profile'}
        </ModalHeader>
        <ModalSection>
          {success
            ? 'Paste the link into the communication you want to share it in.'
            : 'Copy and paste the following link to share this profile'}
          <ShareLinkModalStyledText name="LINK">
            {copyText}
          </ShareLinkModalStyledText>
        </ModalSection>
        <ModalFooter>
          <Button name="CLOSE" onClick={ShareLinkModalStore.closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )}
  />
);

ShareLinkModalComponent.propTypes = {
  shareLinkModalState: ShareLinkModalStore.getStateShape().isRequired,
};

export const ShareLinkModal = connect(state => ({
  shareLinkModalState: ShareLinkModalStore.select(state),
}))(ShareLinkModalComponent);
