import React from 'react';
import { SubmissionExperienceModel } from '@axiom/ui-models';
import { Experience, PracticeArea } from '@axiom/validation';

import { Badge } from '../../element/Badge/Badge';
import { DateUtil } from '../../../utils/date-util';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';
import { CondensedHeader } from '../../content/CondensedHeader/CondensedHeader';

import { TalentExperiencesItemDescription } from './TalentExperiencesItemDescription';
import { ItemBlock } from './TalentExperiencesItemStyles';

interface TalentExperiencesItemProps {
  experience: Experience;
  groupSize: number;
  lastOfGroup: boolean;
  practiceAreaName?: PracticeArea['name'];
}

export const TalentExperiencesItem = ({
  experience,
  groupSize,
  lastOfGroup,
  practiceAreaName,
}: TalentExperiencesItemProps) => {
  const description = new SubmissionExperienceModel(
    experience
  ).calculatedDescription();

  return (
    <ItemBlock
      data-test="EXPERIENCE_ITEM"
      className="experienceItem"
      groupSize={groupSize}
      lastOfGroup={lastOfGroup}
      isAxiom={experience.isAxiom}
    >
      {experience.isAxiom && (
        <ParagraphHeader name="AXIOM_EXPERIENCE_CLIENT_NAME">
          {experience.isAxiom && (
            <Gutter as="span" right="4px" name="EXPERIENCE_AXIOM_BLOCK">
              <Badge background="orange" name="AXIOM_BADGE">
                Axiom
              </Badge>
            </Gutter>
          )}
          {experience.calculatedDisplayName}
        </ParagraphHeader>
      )}
      {!experience.isAxiom && (
        <>
          {groupSize === 1 && (
            <ParagraphHeader name="EXPERIENCE_COMPANY_NAME">
              {experience.calculatedDisplayName}
            </ParagraphHeader>
          )}
          {experience.externalOpportunityName && (
            <Gutter bottom="4px">
              <CondensedHeader name="EXPERIENCE_JOB_NAME">
                {experience.externalOpportunityName}
              </CondensedHeader>
            </Gutter>
          )}
        </>
      )}

      <Gutter bottom="4px" />

      {practiceAreaName && (
        <Gutter bottom="4px">
          <Paragraph name="EXPERIENCE_PRACTICE_AREA">
            {practiceAreaName}
          </Paragraph>
        </Gutter>
      )}

      <Paragraph name="EXPERIENCE_DATE_RANGE">
        {DateUtil.displayDateRange(experience.startDate, experience.endDate)}
      </Paragraph>
      <Gutter bottom="4px" />

      <Paragraph name="EXPERIENCE_LOCATION">
        {experience.locationName}
      </Paragraph>

      {description && (
        <Gutter top="16px" bottom={lastOfGroup ? '0' : '24px'}>
          <TalentExperiencesItemDescription descriptionText={description} />
        </Gutter>
      )}
    </ItemBlock>
  );
};
