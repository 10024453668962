import { arrayOf, shape, string, number } from 'prop-types';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';

import GenericStore from '../classes/generic-store';

class PreloadedAppErrorsStoreClass extends GenericStore {
  /**
   * displayMessage = string || [] || {errorMessage, errorCode}
   */
  show(displayMessages) {
    if (Array.isArray(displayMessages) && displayMessages.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      displayMessages = displayMessages[0];
    }
    const setError = {
      errorMessage: 'An error has occurred. Please refresh.',
      errorCode: null,
    };

    if (isPlainObject(displayMessages)) {
      if (displayMessages.errorMessage) {
        setError.errorMessage = displayMessages.errorMessage;
        setError.errorCode = displayMessages.errorCode || null;
      } else {
        try {
          setError.errorMessage = JSON.stringify(displayMessages);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    } else if (isString(displayMessages)) {
      setError.errorMessage = displayMessages;
    }

    /**
     * We don't want to show the word candidate to users and api always returns candidate
     * so this is a word fix
     */

    setError.errorMessage = setError.errorMessage.replace(
      /candidate/g,
      'talent'
    );
    setError.errorMessage = setError.errorMessage.replace(
      /Candidate/g,
      'Talent'
    );

    return this.setDataFromStateData((data = []) => {
      if (data.find(item => item.errorMessage === setError.errorMessage)) {
        return data;
      }

      return [...data, { ...setError }];
    });
  }

  getDataShape() {
    return arrayOf(
      shape({
        errorMessage: string.isRequired,
        errorCode: number,
      })
    );
  }

  select(state) {
    return super.select(state) ? super.select(state).data || [] : [];
  }
}

export const PreloadedAppErrorsStore = new PreloadedAppErrorsStoreClass();
