import styled from 'styled-components';

const AnimatedLoadingBlock = styled.div`
  background-color: #bfc6cd;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 2px;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      to right,
      transparent 0%,
      #d1d9e0 70%,
      transparent 80%
    );
    animation: shimmer 3000ms infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;

export const LoadingBarStyled = styled(AnimatedLoadingBlock)`
  width: ${props => props.width};
  height: ${props => props.height};
`;
