import { shape, arrayOf, string, number, oneOfType, exact } from 'prop-types';
import { maxFiltersErrorMessage, MAX_SEARCH_FILTER_COUNT } from '@axiom/const';
import { GoogleTagManagerUtil } from '@axiom/ui';

import FormStore from '../classes/form-store';
import { EnvUtil } from '../utils/env-util';
import { SearchUtils } from '../utils/search';
import { organizeData } from '../utils/talent-search-filter-util';
import { searchFiltersFullShape } from '../models/search-filters';

import { SearchStore } from './search-store';
import { PreloadedAppErrorsStore } from './preloaded-app-errors-store';

const SORT_BY_OPTIONS = [
  {
    label: 'Relevance',
    value: 'relevance',
  },
  {
    label: 'Hourly rate low to high',
    value: 'hourlyRateLowToHigh',
  },
  {
    label: 'Hourly rate high to low',
    value: 'hourlyRateHighToLow',
  },
  {
    label: 'Experience least to most',
    value: 'yoeLowToHigh',
  },
  {
    label: 'Experience most to least',
    value: 'yoeHighToLow',
  },
];

export class FormTalentSearchFiltersClass extends FormStore {
  getDataShape() {
    return searchFiltersFullShape;
  }

  getSortOptions() {
    return SORT_BY_OPTIONS;
  }

  getSelectedFiltersShape() {
    return shape({
      practiceAreaId: arrayOf(string),
      industries: arrayOf(string),
      yearsOfExperience: arrayOf(
        exact({
          start: oneOfType([string, number]),
          end: oneOfType([string, number]),
        })
      ),
      addressCountryCode: arrayOf(string),
      state: arrayOf(string),
      profession: arrayOf(string),
      weeklyAvailability: arrayOf(string),
      barredLocations: arrayOf(string),
      offer: arrayOf(string),
    });
  }

  load(filters, gridState, user) {
    this.setState(
      organizeData(
        filters,
        SORT_BY_OPTIONS[0].value,
        gridState?.data?.meta?.searchConfig?.filters,
        user
      )
    );
  }

  selectData(state) {
    return this.select(state)?.data || null;
  }

  selectFilterData(state) {
    const data = this.selectData(state);
    return this.getSelectedFilters(data);
  }

  getSelectedFilters(formData) {
    const reducer = data =>
      Object.values(data).reduce((ary, items) => {
        // all the items

        items.forEach(item => {
          // all alwaysShow and showHide

          if (item?.checked) ary.push(item.id);
        });

        return ary;
      }, []);

    const categories = formData?.categories?.reduce((obj, data) => {
      // all the categories

      if (data.name.toUpperCase() === 'BARREDLOCATIONS') {
        Object.values(data.items).forEach(items => {
          if (!Array.isArray(obj[data.name])) obj[data.name] = [];
          obj[data.name] = [...obj[data.name], ...reducer(items)];
        });
      } else {
        obj[data.name] = reducer(data.items);
      }

      // removing unused filters
      if (!obj[data.name].length) obj[data.name] = undefined;

      return obj;
    }, {});

    const data = categories;

    if (formData?.search) {
      data.search = formData.search;
    }
    if (formData?.sort && formData.sort !== SORT_BY_OPTIONS[0].value) {
      data.sort = formData.sort;
    }

    return data;
  }

  removeFilter(filter, formData) {
    const formDataTemp = { ...formData };

    formDataTemp.categories.forEach(category => {
      if (category.items.alwaysShow) {
        category.items.alwaysShow
          .concat(category.items.showHide)
          .forEach(item => {
            if (JSON.stringify(item.id) === filter.id) {
              item.checked = false;
            }
          });
      } else {
        const admissionItems = Object.entries(category.items).map(e => e[1]);
        admissionItems.forEach(admissionItem => {
          admissionItem.alwaysShow
            .concat(admissionItem.showHide)
            .forEach(item => {
              if (JSON.stringify(item.id) === filter.id) {
                item.checked = false;
              }
            });
        });
      }
    });

    this.submit(null, formDataTemp);
  }

  validate() {
    return true;
  }

  submit(_, formData) {
    const payload = this.getSelectedFilters(formData);
    const filterCount = SearchUtils.countFilters(payload);

    if (filterCount > MAX_SEARCH_FILTER_COUNT) {
      GoogleTagManagerUtil.newEvent(
        'gtm-talent-search-filters-too-many-error',
        { filterCount }
      );
      return PreloadedAppErrorsStore.show(maxFiltersErrorMessage);
    }

    // DO NOT EDIT unless for analytical reasons === START
    GoogleTagManagerUtil.newEvent('gtm-talent-search-filters-change', {
      'talent-search-key-word': payload?.search,
      'talent-search-filters-barred-locations': payload?.barredLocations,
      'talent-search-filters-practice-areaId': payload?.practiceAreaId,
      'talent-search-filters-industries': payload?.industries,
      'talent-search-filters-address-country-code': payload?.addressCountryCode,
      'talent-search-filters-state': payload?.state,
      'talent-search-filters-profession': payload?.profession,
      'talent-search-filters-years-of-experience':
        payload?.yearsOfExperience?.map(yoe => JSON.stringify(yoe)),
      'talent-search-filters-weekly-availability':
        payload?.weeklyAvailabilityLabel,
      'talent-search-filters-offer': payload?.offer,
      'talent-search-sort-by': payload?.sort,
    });
    // DO NOT EDIT unless for analytical reasons === END

    const newUrl = `${EnvUtil.clientOurNetworkDetailsUrl}/page/1`;

    if (formData) {
      return this.setState(formData).then(() => {
        window.history.pushState({}, '', newUrl);
        SearchStore.changeFilters(formData, payload);
      });
    }

    window.history.pushState({}, '', newUrl);

    return SearchStore.changeFilters();
  }
}

export const FormTalentSearchFiltersStore = new FormTalentSearchFiltersClass();
