import { arrayOf, bool, number, shape, string } from 'prop-types';

import { TagShape } from './tags';

export const ExperienceShape = shape({
  id: string.isRequired,
  candidateId: string, // missing in GET /candidates/:id
  client: string.isRequired,
  clientDomain: string,
  description: string,
  endDate: string,
  externalOpportunityName: string,
  formattedEndDate: string,
  formattedStartDate: string,
  isAxiom: bool,
  isConfidential: bool,
  isHighlighted: bool,
  industryValue: string,
  experienceVisible: bool,
  locationAddressComponents: shape({}),
  locationLatitude: number,
  locationLongitude: number,
  locationName: string,
  locationPlaceId: string,
  locationPoint: shape({}),
  opportunityId: string,
  sectorId: string,
  skills: arrayOf(TagShape),
  startDate: string.isRequired,
  submissionDescription: string,
  tags: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      parentId: string,
      type: string,
      createdAt: string,
      updatedAt: string,
    })
  ),
  createdAt: string,
  updatedAt: string,
});
