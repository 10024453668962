import React, { useEffect } from 'react';
import {
  ImageCircle,
  ContextMenu,
  ContextMenuItem,
  ContextMenuDivider,
  IconButton,
  Gutter,
  ContextMenuHeader,
  useBreakpoint,
} from '@axiom/ui';
import { Link, useNavigate, useParams } from 'react-router-dom';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import { QueryParamUtil } from '../../utils/query-param-util';
import { isEnvoyUser, isClientUser } from '../../utils/roles';
import { WindowUtil } from '../../utils/window-util';
import { EnvUtil } from '../../utils/env-util';
import { generateClientProjectsUrl } from '../../utils/link-util';
import { FormNotificationPreferencesStore } from '../../stores/form-notification-preferences-store';
import { NotificationPreferencesModal } from '../NotificationPreferencesModal/NotificationPreferencesModal';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';

export const SharedHeaderMenus = ({ user }) => {
  const contact = user?.contact;

  const { accountId: pathParamAccountId } = useParams();
  const { accountId: queryParamAccountId } = QueryParamUtil.getQueryParams();
  const accountId = pathParamAccountId ?? queryParamAccountId;
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();

  const account = (() => {
    let acc;

    if (!contact?.accounts?.length) {
      return null;
    }

    if (accountId) {
      acc = contact.accounts.find(a => a.id === accountId) || null;
    } else {
      [acc] = contact.accounts;
    }

    return acc;
  })();

  const openNotificationPreferences = () => {
    if (isEnvoyUser(user)) return;

    FormNotificationPreferencesStore.load({
      accountId: account.id,
      availabilityNotificationOptOut: contact.availabilityNotificationOptOut,
      benchNotificationOptOut: contact.benchNotificationOptOut,
      contactId: contact.id,
    });

    WindowUtil.setUrlQuery(
      { account: account.id, modal: 'notifications' },
      true
    );
  };

  const closedNotificationPreferences = () => {
    WindowUtil.setUrlQuery({ account: account.id }, true);
  };

  useEffect(() => {
    const { modal } = WindowUtil.getUrlQuery();
    if (modal === 'notifications' && user) openNotificationPreferences();
  }, [user]);

  const renderUserLoggedIn = () => {
    const showCompanies = (contact?.accounts?.length ?? 0) >= 2;
    return (
      <ContextMenu
        anchor={
          <>
            <Gutter right="12px">
              <ImageCircle imageName={user.fullName} size="thumbnail" />
            </Gutter>
            <IconButton
              size="inline"
              pattern="secondary"
              variation="minimal"
              icon="arrow-down"
            />
          </>
        }
        direction={isMobile ? 'right' : 'left'}
      >
        {showCompanies && (
          <>
            <ContextMenuHeader>Switch Companies</ContextMenuHeader>
            {contact?.accounts.map(a => (
              <ContextMenuItem
                key={a.id}
                name="SWITCH_COMPANY_LINK"
                onClick={() => {
                  navigate(generateClientProjectsUrl(a.id));
                }}
                selected={a.id === accountId}
              >
                {a.calculatedName}
              </ContextMenuItem>
            ))}
            <ContextMenuDivider />
          </>
        )}
        {account && isClientUser(user) && (
          <>
            <ContextMenuHeader>My Account</ContextMenuHeader>

            <ContextMenuItem
              onClick={openNotificationPreferences}
              name="NOTIFICATION_PREFERENCES_LINK"
            >
              Notification Preferences
            </ContextMenuItem>
          </>
        )}
        <ContextMenuItem
          onClick={() => {
            window.location.href =
              'mailto:client.answers@axiomlaw.com?subject=Bench Support Request';
          }}
          name="REPORT_ISSUE_LINK"
        >
          Report Issue
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            window.location.href = `${EnvUtil.loginUrl}/logout`;
          }}
          name="SIGN_OUT_LINK"
        >
          Sign Out
        </ContextMenuItem>
      </ContextMenu>
    );
  };

  const renderUserLoggedOut = () => {
    return <Link to={WindowUtil.getLoginLink()}>Sign In</Link>;
  };

  return (
    <>
      <div data-test="SHARED_HEADER">
        {user ? renderUserLoggedIn(user) : renderUserLoggedOut()}
      </div>
      <FormStateTreatment
        isModalState
        name="NOTIFICATION_PREFERENCES_TREATMENT"
        formStore={FormNotificationPreferencesStore}
        renderLoadedView={({ formData, fireSubmit, fireCancel }) => (
          <NotificationPreferencesModal
            formData={formData}
            onClose={() => {
              fireCancel();
              closedNotificationPreferences();
            }}
            onSubmit={() => {
              fireSubmit();
              closedNotificationPreferences();
            }}
          />
        )}
      />
    </>
  );
};

SharedHeaderMenus.propTypes = {
  user: PreloadedUserStore.getDataShape(),
};

SharedHeaderMenus.defaultProps = {
  user: null,
};
