import { arrayOf, bool, number, shape, string } from 'prop-types';

import { ExperienceShape } from './experiences';
import { BarredLocationsShape } from './barred-locations';
import { CertificationShape } from './certifications';
import { DegreeShape } from './degrees';
import { CandidateLanguageShape } from './languages';
import { PositionShape } from './positions';

export const AccountSubmissionShape = shape({
  id: string,
  jobName: string,
  submissionId: string,
  submissionLastUpdatedAt: string,
  candidates: arrayOf(
    shape({
      id: string,
      calculatedDisplayName: string,
    })
  ),
});

export const SubmissionShape = shape({
  accountId: string,
  accountName: string,
  currency: string,
  isClosed: bool,
  jobName: string,
  ownerUserId: string,
  stage: string,
  submissionId: string,
  submissionLastUpdatedAt: string,
  ownerUser: shape({
    email: string,
    firstName: string,
    fullName: string,
    lastName: string,
  }),
  candidates: arrayOf(
    shape({
      id: string,
      calculatedDisplayName: string,
      calculatedFirstName: string,
      calculatedLastName: string,
      displayBillingRate: string,
      isActiveForSubmission: bool,
      proposedHourlyRate: number,
      submissionDescription: string,
      submissionRank: number,
      barredLocations: arrayOf(BarredLocationsShape),
      certifications: arrayOf(CertificationShape),
      degrees: arrayOf(DegreeShape),
      languages: arrayOf(CandidateLanguageShape),
      position: PositionShape,
      submissionExperiences: arrayOf(ExperienceShape),
    })
  ),
});
