import { PracticeArea } from '@axiom/validation';

const practiceAreaTypeValues = {
  PRACTICE_AREA: 'practicearea',
  SPECIALTY: 'specialty',
};

type PracticeAreaAndSpecialty = {
  practiceArea?: PracticeArea;
  specialty?: PracticeArea;
};

const getPracticeAreaAndSpecialty = (
  practiceAreas: PracticeArea[],
  practiceAreaId: PracticeArea['id']
): PracticeAreaAndSpecialty => {
  const getPracticeArea = (paid = practiceAreaId): PracticeArea | null => {
    return practiceAreas?.find(pa => pa.id === paid) ?? null;
  };
  const practiceArea = getPracticeArea();
  const response: PracticeAreaAndSpecialty = {
    practiceArea: null,
    specialty: null,
  };

  if (practiceArea) {
    if (practiceArea.type === practiceAreaTypeValues.PRACTICE_AREA) {
      response.practiceArea = practiceArea;
    } else if (practiceArea.type === practiceAreaTypeValues.SPECIALTY) {
      response.practiceArea = getPracticeArea(practiceArea.parentId);
      response.specialty = practiceArea;
    }
  }

  return response;
};

export const PracticeAreaUtil = {
  getPracticeAreaAndSpecialty,
};
