import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
`;

export const SectionBodyItemBodyRow = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.text.error};
`;
