import { useApi } from '@axiom/ui';
import { Account, Submission } from '@axiom/validation';
import { useParams } from 'react-router-dom';

import { AccountSubmissionsApi } from '../../api/account/account-submissions';

interface Args {
  accountId?: Account['id'];
  submissionId?: Submission['id'];
}

export const useClientEngagementsInterviewSubmissionData = ({
  accountId: argAccountId,
  submissionId: argSubmissionId,
}: Args = {}) => {
  const { submissionId: urlSubmissionId, accountId: urlAccountId } =
    useParams();
  const accountId = urlAccountId ?? argAccountId;
  const submissionId = urlSubmissionId ?? argSubmissionId;

  const [response] = useApi(
    accountId &&
      submissionId &&
      AccountSubmissionsApi.readAccountSubmissionDetails(
        accountId,
        submissionId,
        { ctaFilters: ['Interview'] }
      )
  );
  const currentInterviewSubmission: Submission = response?.data;
  const interviewingCandidateIds = currentInterviewSubmission?.positions
    .map(position => position.candidateOpportunities)
    .flat()
    .map(candidateOpportunity => candidateOpportunity?.candidate?.id);

  const interviewCount = interviewingCandidateIds?.length;

  return {
    interviewingCandidateIds,
    currentInterviewSubmission,
    interviewCount,
  };
};
