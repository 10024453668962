import { Api } from '@axiom/ui';
import {
  Account,
  AccountExpansions,
  ClientRequest,
  Contact,
  Opportunity,
  Submission,
} from '@axiom/validation';
import { AccountConst } from '@axiom/const';
import { stringify } from 'qs';

import { EnvUtil } from '../../utils/env-util';
import ApiHelper from '../../lib/api-helper';

const api = new ApiHelper('ACCOUNTS');

export const getAccount = async (accountId: Account['id']) => {
  const { data } = await api.GET(`/accounts/${accountId}`, {
    expand: [AccountConst.Expansions.teamUserInfo],
  });

  return data;
};

class AccountLegacyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  getAccount(accountId: Account['id'], body: AccountExpansions = {}) {
    return super.read<{ data: Account }>({
      endpoint: `/accounts/${accountId}/?${stringify(body)}`,
      method: 'GET',
    });
  }

  updateClientRequest(accountId: Account['id'], body: ClientRequest) {
    return super.write<{ data: { id: Opportunity['id'] } }>({
      endpoint: `/accounts/${accountId}/clientRequests`,
      method: 'PUT',
      body,
    });
  }

  createShareAccount(
    accountId: Account['id'],
    body: {
      contacts: Contact[];
      message: string;
      email?: Contact['email'];
      submissionId: Submission['id'];
    }
  ) {
    return super.write<{ data: { email: string; statusCode: number }[] }>({
      endpoint: `/accounts/${accountId}/share`,
      method: 'POST',
      body,
    });
  }

  refreshAccount(accountId: Account['id'], body: AccountExpansions = {}) {
    return super.invalidate(`/accounts/${accountId}/?${stringify(body)}`);
  }
}

export const AccountLegacyApi = new AccountLegacyApiClass();
