import React, { useState } from 'react';
import {
  Card,
  CardSection,
  SmallHeader,
  Paragraph,
  Gutter,
  CondensedLarge,
  CondensedHeader,
  CardHeader,
  Button,
  Icon,
  CardFooter,
  Modal,
  ModalSection,
  ModalHeader,
  ModalFooter,
  Flashy,
  ClientEngagementsCandidateHeader,
  CandidateDescription,
  TalentExperiences,
  TalentEducation,
  TalentCertifications,
  TalentQualifications,
  TalentLanguages,
  useApi,
  CandidateHighlightExperienceSummary,
  CandidateHighlightExperienceDetails,
  ToastUtil,
  CandidateHighlightsUtil,
  DateUtil,
} from '@axiom/ui';
import {
  ClientRequestConst,
  ClientRequestTypesValueTypes,
  CandidateStatusType,
  CandidateOpportunitiesConst,
  ContactsOpportunitiesConst,
  AccountConst,
} from '@axiom/const';
import { ClientRequest, CalendarEventType } from '@axiom/validation';
import { useNavigate, Link } from 'react-router-dom';
import { SubmissionCandidateModel } from '@axiom/ui-models';

import { AccountSubmissionsLegacyApi } from '../../api/account/account-submissions-legacy';
import { AccountSubmissionsApi } from '../../api/account/account-submissions';
import { AccountLegacyApi } from '../../api/account/account-legacy';
import { PracticeAreaApi } from '../../api/practiceAreas';
import { TaxonomyApi } from '../../api/taxonomy/taxonomy';
import { EnvUtil } from '../../utils/env-util';
import { CalendarApi } from '../../api/calendar-api';
import { useUserAccountData } from '../../hooks/useUserAccountData';

import { useClientEngagementsCurrentSubmissionData } from './useClientEngagementsCurrentSubmissionData';
import { useClientEngagementsCandidateSubmissionData } from './useClientEngagementsCandidateSubmissionData';
import { ClientEngagementsEngagementPassModal } from './ClientEngagementsEngagementPassModal';
import { ClientEngagementsTalentInterviewConfirmModalLegacy } from './ClientEngagementsTalentInterviewConfirmModalLegacy';
import { ClientEngagementsTalentInterviewScheduleModal } from './ClientEngagementsTalentInterviewScheduleModal';
import {
  ClientEngagementsTalentInterviewConfirmModal,
  InterviewContactsType,
} from './ClientEngagementsTalentInterviewConfirmModal';

export const ClientEngagementsEngagementTalentDetails = () => {
  const navigate = useNavigate();
  const { user, account } = useUserAccountData();
  const [showPassModal, setShowPassModal] = useState(false);
  const [showHireConfirmPrompt, setShowHireConfirmPrompt] = useState(false);
  const [showInterviewAuto, setShowInterviewAuto] = useState(false);
  const [showInterviewConfirmModal, setShowInterviewConfirmModal] =
    useState(false);

  const {
    sortedPositions,
    currentSubmissionCandidateToDisplay,
    groupedExperiences,
    submissionId,
    currentSubmission,
    talentSlug,
  } = useClientEngagementsCurrentSubmissionData({ account });

  const { currentCandidateSubmission } =
    useClientEngagementsCandidateSubmissionData({
      accountId: account?.id,
      submissionId,
      candidateId: currentSubmissionCandidateToDisplay?.candidate?.id,
    });

  const [{ data: calendarData } = { data: {} }] = useApi(
    currentCandidateSubmission?.calendar?.id &&
      CalendarApi.readCalendar(currentCandidateSubmission.calendar.id)
  );

  const getBaseForApiBody = () => ({
    submissionId,
    candidateId: currentSubmissionCandidateToDisplay?.candidate?.id,
    clientUserId: user.id,
  });

  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );

  const handleDataRefresh = () => {
    return Promise.all([
      AccountLegacyApi.refreshAccount(account.id, {
        expand: [AccountConst.Expansions.accountTeamUserInfo],
      }),
      AccountSubmissionsApi.refreshAccountSubmissionDetails(
        account.id,
        submissionId,
        { ctaFilters: ['None', 'Hire'] }
      ),
      AccountSubmissionsApi.refreshAccountSubmissionDetails(
        account.id,
        submissionId,
        { ctaFilters: ['Interview'] }
      ),
      AccountSubmissionsLegacyApi.refreshCandidateAccountSubmissionDetails(
        account.id,
        submissionId,
        currentSubmissionCandidateToDisplay?.candidate?.id
      ),
    ]);
  };
  const handlePassClick = () => {
    setShowPassModal(!showPassModal);
  };

  const handleClosePassModal = () => {
    setShowPassModal(!showPassModal);
  };

  const handleAfterPassedOrInterviewNavigation = () => {
    const allCandidateIds: string[] = sortedPositions.reduce((data, pos) => {
      data = data.concat(
        pos.candidateOpportunities.map(item => item.candidate.id)
      );

      return data;
    }, []);

    const talentIndex = allCandidateIds.findIndex(
      id => id === currentSubmissionCandidateToDisplay.candidate.id
    );
    const nonPassedTalent = [...allCandidateIds];
    nonPassedTalent.splice(talentIndex, 1);

    let nextTalent: string | null = null;

    if (nonPassedTalent.length > 0) {
      if (talentIndex === allCandidateIds.length - 1) {
        nextTalent = allCandidateIds[talentIndex - 1];
      } else {
        nextTalent = allCandidateIds[talentIndex + 1];
      }
    }

    if (nextTalent) {
      const to =
        talentIndex === 0 && !talentSlug
          ? `./${nextTalent}`
          : `../${nextTalent}`;
      navigate(to);
    }
  };

  const handleSubmitPassModal = (formData: {
    rejectionLossCode: string;
    notes?: string;
  }) => {
    const body: ClientRequest = {
      ...getBaseForApiBody(),
      requestType: ClientRequestConst.RequestTypes.ClientPassed,
      rejectionLossCodes: [formData.rejectionLossCode],
      notes: formData.notes,
    };

    handleAfterPassedOrInterviewNavigation();

    return AccountLegacyApi.updateClientRequest(account.id, body).then(
      async () => {
        await handleDataRefresh();
        handleClosePassModal();
      }
    );
  };

  const handleInterviewRequestClick = async () => {
    const hasFreeTime = calendarData?.events?.find(event => {
      return (
        !event.busy &&
        DateUtil.isDateAfterDate(event.startTime, new Date(), true, 'hour')
      );
    });

    if (EnvUtil.enableInterviewAuto && hasFreeTime) {
      setShowInterviewAuto(true);
    } else {
      setShowInterviewConfirmModal(true);
    }
  };

  const loadToast = () => {
    ToastUtil.add({
      name: `INTERVIEW_REQUESTED`,
      type: 'info',
      children: (
        <>
          <CondensedHeader>Interview Requested</CondensedHeader>
          <CondensedLarge>
            {currentCandidateSubmission?.calculatedFirstName} has been moved to
            the{' '}
            <Link
              data-test="TO_INTERVIEW_TAB"
              to={`${window.location.pathname.split('matches')[0]}interviews`}
            >
              Interviews tab.
            </Link>
          </CondensedLarge>
        </>
      ),
    });
  };

  const requestAction = async (requestType: ClientRequestTypesValueTypes) => {
    const body = {
      submissionId,
      candidateId: currentSubmissionCandidateToDisplay?.candidate?.id,
      clientUserId: user.id,
      requestType,
    };

    if (requestType === ClientRequestConst.RequestTypes.InterviewRequested) {
      loadToast();
      handleAfterPassedOrInterviewNavigation();
    }

    return AccountLegacyApi.updateClientRequest(account.id, body).then(
      async () => {
        await handleDataRefresh();
      }
    );
  };

  const confirmInterview = async (
    data?: InterviewContactsType
  ): Promise<unknown> => {
    if (EnvUtil.enableInterviewAuto) {
      const payload = {
        adhocAttendees: data.adHocContacts,
        contactIds: data.contacts,
        preferredVideoClient: data.isZoomNotPreferred
          ? null
          : AccountConst.PreferredVideoClient.zoom,
        candidateId: currentCandidateSubmission.id,
        submissionId,
      };

      const res = await CalendarApi.createManualInterview(payload);
      await handleDataRefresh();
      loadToast();
      setShowInterviewConfirmModal(false);
      return res;
    }
    return requestAction(
      ClientRequestConst.RequestTypes.InterviewRequested
    ).then(() => setShowInterviewConfirmModal(false));
  };

  const scheduleInterview = async (
    data?: InterviewContactsType,
    scheduledEvent?: CalendarEventType
  ): Promise<unknown> => {
    if (EnvUtil.enableInterviewAuto) {
      const { title, startTime, endTime, busy } = scheduledEvent;
      const payload = {
        adhocAttendees: data.adHocContacts,
        contactIds: data.contacts,
        preferredVideoClient: data.isZoomNotPreferred
          ? null
          : AccountConst.PreferredVideoClient.zoom,
        candidateId: currentCandidateSubmission.id,
        submissionId,
        title,
        startTime,
        endTime,
        busy,
      };

      const res = await CalendarApi.createScheduledCalendarInterview(payload);
      await handleDataRefresh();
      loadToast();
      setShowInterviewAuto(false);
      return res;
    }
    return requestAction(
      ClientRequestConst.RequestTypes.InterviewRequested
    ).then(() => setShowInterviewAuto(false));
  };

  const handleHireRequestClick = () => {
    setShowHireConfirmPrompt(!showHireConfirmPrompt);
  };

  const confirmHire = async () => {
    await requestAction(ClientRequestConst.RequestTypes.HireRequested);
    setShowHireConfirmPrompt(!showHireConfirmPrompt);
  };

  const showRequestMessage = (candidateStatus?: CandidateStatusType) => {
    switch (candidateStatus) {
      case CandidateOpportunitiesConst.CandidateStatuses.Selected:
        return (
          <CondensedLarge name="PENDO_REQUESTED_HIRE">
            <Gutter right="8px" as="span">
              Engagement Requested
            </Gutter>
            <Flashy color="dataVizGreen">
              <Icon name="circle-check-filled" />
            </Flashy>
          </CondensedLarge>
        );
      default:
        return null;
    }
  };
  const showCtaButtons = (
    submission: {
      isQualificationComplete?: boolean;
    },
    submissionCandidate: {
      candidateStatus?: string;
    }
  ) => {
    return (
      submission.isQualificationComplete &&
      submissionCandidate?.candidateStatus !==
        CandidateOpportunitiesConst.CandidateStatuses.Engaged
    );
  };

  const candidateDescription = new SubmissionCandidateModel(
    currentCandidateSubmission
  ).calculatedDescription();

  const primaryContactId = currentSubmission?.contacts.find(
    contact => contact.displayRole === ContactsOpportunitiesConst.Roles.Owner
  )?.id;

  return (
    <div data-test="talent-details">
      <Card>
        <CardHeader>
          <ClientEngagementsCandidateHeader
            candidate={currentCandidateSubmission}
          />
        </CardHeader>
        {candidateDescription && (
          <CardSection divider>
            <CandidateDescription
              description={candidateDescription}
              calculatedFirstName={
                currentCandidateSubmission?.calculatedFirstName
              }
            />
          </CardSection>
        )}
        {showCtaButtons(currentSubmission, currentCandidateSubmission) && (
          <CardFooter stackableOn="mobile" name="CTA_FOOTER">
            {showRequestMessage(
              currentCandidateSubmission?.candidateStatus as CandidateStatusType
            ) || (
              <>
                <Button
                  name="CLIENT_PASS"
                  pattern="primary"
                  variation="outline"
                  onClick={handlePassClick}
                >
                  Pass
                </Button>
                <Button
                  name="PENDO_REQUEST_INTERVIEW_CTA"
                  pattern="primary"
                  onClick={handleInterviewRequestClick}
                >
                  Interview
                </Button>
                <Button
                  name="HIRE"
                  pattern="primary"
                  onClick={handleHireRequestClick}
                >
                  Engage
                </Button>
              </>
            )}
          </CardFooter>
        )}
      </Card>
      <Gutter bottom="16px" />
      {CandidateHighlightsUtil.calculateNumberOfHighlights(
        currentCandidateSubmission,
        currentSubmission
      ) > 0 && (
        <Card>
          <CardHeader>
            <SmallHeader>Highlights</SmallHeader>
          </CardHeader>
          <CardSection>
            <CandidateHighlightExperienceSummary
              candidate={currentCandidateSubmission}
              submission={currentSubmission}
            />
            <Gutter bottom="16px" />
            <CandidateHighlightExperienceDetails
              candidate={currentCandidateSubmission}
              practiceAreas={practiceAreas}
              taxonomy={taxonomy}
            />
          </CardSection>
        </Card>
      )}
      <Gutter bottom="16px" />
      {groupedExperiences.length > 0 && (
        <Gutter bottom="16px">
          <Card>
            <CardHeader>
              <SmallHeader>Experience</SmallHeader>
            </CardHeader>
            <TalentExperiences
              groupedExperiences={groupedExperiences}
              practiceAreas={practiceAreas}
            />
          </Card>
        </Gutter>
      )}

      {currentCandidateSubmission?.degrees?.length > 0 && (
        <Gutter bottom="16px">
          <Card name="degrees">
            <CardHeader>
              <SmallHeader>Education</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentEducation candidate={currentCandidateSubmission} />
            </CardSection>
          </Card>
        </Gutter>
      )}
      {currentCandidateSubmission?.certifications?.length > 0 && (
        <Gutter bottom="16px">
          <Card name="certification">
            <CardHeader>
              <SmallHeader>Accomplishments</SmallHeader>
            </CardHeader>
            <CardSection divider>
              <TalentCertifications candidate={currentCandidateSubmission} />
            </CardSection>
            <CardSection>
              <TalentQualifications candidate={currentCandidateSubmission} />
            </CardSection>
          </Card>
        </Gutter>
      )}
      {currentCandidateSubmission?.languages?.length > 0 && (
        <Gutter bottom="16px">
          <Card>
            <CardHeader>
              <SmallHeader>Languages</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentLanguages candidate={currentCandidateSubmission} />
            </CardSection>
          </Card>
        </Gutter>
      )}
      {showPassModal && (
        <ClientEngagementsEngagementPassModal
          candidate={currentCandidateSubmission}
          closeModal={handleClosePassModal}
          submitModal={handleSubmitPassModal}
        />
      )}

      {showInterviewConfirmModal && EnvUtil.enableInterviewAuto && (
        <ClientEngagementsTalentInterviewConfirmModal
          loggedInUser={user}
          submission={currentSubmission}
          candidate={currentCandidateSubmission}
          onClose={setShowInterviewConfirmModal}
          onConfirm={confirmInterview}
          primaryContactId={primaryContactId}
          account={account}
        />
      )}
      {showInterviewConfirmModal && !EnvUtil.enableInterviewAuto && (
        <ClientEngagementsTalentInterviewConfirmModalLegacy
          candidate={currentCandidateSubmission}
          onClose={setShowInterviewConfirmModal}
          onConfirm={confirmInterview}
        />
      )}
      {showInterviewAuto && (
        <ClientEngagementsTalentInterviewScheduleModal
          account={account}
          user={user}
          submission={currentSubmission}
          candidate={currentCandidateSubmission}
          calendarData={calendarData}
          onConfirm={scheduleInterview}
          onClose={() => setShowInterviewAuto(false)}
          onOpenSecondFlow={() => {
            setShowInterviewAuto(false);
            setShowInterviewConfirmModal(true);
          }}
          primaryContactId={primaryContactId}
        />
      )}
      {showHireConfirmPrompt && (
        <Modal size="confirm" name="HIRE_PROMPT">
          <ModalHeader
            name="PROMPT_HEADER"
            onClose={() => setShowHireConfirmPrompt(!showHireConfirmPrompt)}
          >
            Confirm your engagement request
          </ModalHeader>
          <ModalSection>
            <Paragraph name="PROMPT_BODY_COPY">
              Confirming your engagement request lets you skip right to the best
              part - engaging {currentCandidateSubmission.calculatedFirstName}{' '}
              for your Axiom engagement. We will reach out shortly to get things
              rolling.
            </Paragraph>
          </ModalSection>
          <ModalFooter stackableOn="mobile">
            <Button
              name="CANCEL"
              variation="outline"
              onClick={() => setShowHireConfirmPrompt(!showHireConfirmPrompt)}
            >
              Cancel
            </Button>
            <Button name="CONFIRM" onClick={confirmHire}>
              Engage {currentCandidateSubmission.calculatedFirstName}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};
