import React, { useState } from 'react';
import {
  Button,
  CondensedLarge,
  ContextMenu,
  ContextMenuItem,
  DarkTheme,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  LargeHeader,
  Layout,
  LayoutBleed,
  Suspense,
  ToastUtil,
  Visible,
} from '@axiom/ui';
import { useLocation, useParams } from 'react-router-dom';

import bannerBackgroundImg from '../../public/images/AFC_ContextBanner_Working.webp';
import { SubmissionPdfDownload } from '../SubmissionPdfDownload/SubmissionPdfDownload';
import { isEnvoyUser } from '../../utils/roles';
import { generateAHQOpportunityURL } from '../../utils/link-util';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useSubmissionsData } from '../../hooks/useSubmissionsData';

import { useClientEngagementsCurrentSubmissionData } from './useClientEngagementsCurrentSubmissionData';
import { ClientEngagementsShareModal } from './ClientEngagementsShareModal';

export const ClientEngagementsHeaderBanner = () => {
  // TEMP ============================================ START
  // TODO: remove this bit and hard code the const usage in the button as part of (FR-4550)
  const location = useLocation();
  const backUrlTempOverride = location.pathname.includes('my-axiom')
    ? 'engagements'
    : 'dashboard';
  // TEMP ============================================ END

  const [downloadPdf, setDownloadPdf] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const params = useParams();
  const { account, user } = useUserAccountData();
  const { allSubmissions } = useSubmissionsData({ account });
  const submission = params?.submissionId
    ? allSubmissions.find(sub => sub.submissionId === params.submissionId)
    : allSubmissions?.[0];
  const { accountId, currentSubmission, invalidSubmissionRequest } =
    useClientEngagementsCurrentSubmissionData({
      account,
      submissionId: submission?.submissionId,
    });
  return invalidSubmissionRequest ? null : (
    <>
      <DarkTheme>
        <Layout
          background={{
            color: 'panelDark',
            image: bannerBackgroundImg,
            repeat: 'no-repeat',
            position: 'center top',
          }}
          name="CLIENT_ACTIONS_BANNER"
        >
          <LayoutBleed>
            <Gutter vertical="24px">
              <Grid stackable>
                <GridRow gutterBottom="16px">
                  <GridColumn width={12}>
                    <Button
                      to={`../../${backUrlTempOverride}`}
                      variation="minimal"
                      pattern="secondary"
                      name="BACK_TO_ENGAGEMENTS"
                      icon="arrow-left"
                      iconPosition="left"
                      inverse
                    >
                      All Engagements
                    </Button>
                    <Visible only="mobile">
                      <Gutter bottom="8px" />
                    </Visible>
                  </GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn
                    largeScreenWidth={10}
                    tabletWidth={10}
                    mobileWidth={12}
                  >
                    <LargeHeader>{currentSubmission.jobName}</LargeHeader>
                    <Visible only="mobile">
                      <Gutter bottom="16px" />
                    </Visible>
                  </GridColumn>
                  <GridColumn
                    largeScreenWidth={2}
                    tabletWidth={2}
                    mobileWidth={12}
                    verticalAlign="bottom"
                    textAlign="right"
                  >
                    <ContextMenu
                      name="ACTIONS_MENU"
                      direction="left"
                      anchor={
                        <Button
                          variation="minimal"
                          pattern="secondary"
                          name="ACTION_BUTTON"
                          icon="open"
                          inverse
                        >
                          Share
                        </Button>
                      }
                    >
                      <ContextMenuItem
                        name="share-option"
                        onClick={() => setShowShareModal(!showShareModal)}
                      >
                        Sharing and Confidentiality
                      </ContextMenuItem>
                      <ContextMenuItem
                        name="download-pdf"
                        onClick={() => {
                          setDownloadPdf(!downloadPdf);

                          ToastUtil.add({
                            name: 'DOWNLOAD_PDF',
                            type: 'info',
                            children: (
                              <CondensedLarge>
                                Your PDF is being created - it will download
                                automatically when ready.
                              </CondensedLarge>
                            ),
                          });
                        }}
                      >
                        Download All Matches
                      </ContextMenuItem>
                      {isEnvoyUser(user) && (
                        <ContextMenuItem
                          name="AHQ_LINK"
                          onClick={() => {
                            window.open(
                              generateAHQOpportunityURL(submission.id)
                            );
                          }}
                        >
                          Open in AHQ
                        </ContextMenuItem>
                      )}
                    </ContextMenu>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Gutter>
          </LayoutBleed>
        </Layout>
      </DarkTheme>
      {showShareModal && (
        <ClientEngagementsShareModal
          accountName={account.calculatedName}
          accountId={accountId}
          currentSubmission={currentSubmission}
          onClose={() => setShowShareModal(!showShareModal)}
        />
      )}
      {downloadPdf && (
        <Suspense fallback={null}>
          <div className="k-pdf-export">
            <SubmissionPdfDownload />
          </div>
        </Suspense>
      )}
    </>
  );
};
