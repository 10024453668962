import React from 'react';

import { Paragraph } from '../../content/Paragraph/Paragraph';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { Button } from '../../element/Button/Button';
import { Card } from '../../layout/Card/Card';
import { CardFooter } from '../../layout/Card/CardFooter';
import { CardHeader } from '../../layout/Card/CardHeader';
import { CardSection } from '../../layout/Card/CardSection';

export const ClientEngagementStartNewProject = ({
  talentFinderUrl,
}: {
  talentFinderUrl: string;
}) => {
  return (
    <Card name="NEW_ENGAGEMENT_CARD" stretched className="with-borders">
      <CardHeader>
        <SmallHeader name="NEW_ENGAGEMENT_HEADER">
          Need more support
        </SmallHeader>
      </CardHeader>
      <CardSection divider>
        <Paragraph>
          Axiom has world-class legal talent with experience spanning all types
          of legal work, practice areas, and industries. If you have more legal
          work coming up, start a new engagement now and let Axiom help you get
          ahead of it.
        </Paragraph>
      </CardSection>
      <CardFooter>
        <Button
          name="NEW_ENGAGEMENT_NAVIGATE_BUTTON"
          toTab={talentFinderUrl}
          variation="outline"
        >
          Start a new engagement
        </Button>
      </CardFooter>
    </Card>
  );
};
