import { string, bool, arrayOf, shape } from 'prop-types';

export const userModel = {
  email: string,
  firstName: string,
  id: string,
  isActive: bool,
  isSSO: bool,
  lastName: string,
  role: arrayOf(string),
  team: arrayOf(shape({})),
};

export const userModelShape = shape(userModel);

export const accountModel = {
  id: string,
  displayName: string,
  name: string,
  calculatedName: string,
  cxPartnerId: string,
  ownerUserId: string,
};

export const contactModelShape = {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  address: string,
  city: string,
  country: string,
  state: string,
  zip: string,
  mobileNumber: string,
  phoneNumber: string,
  accounts: arrayOf(shape(accountModel)),
};

export const userClientModelShape = shape({
  id: string,
  candidateId: string,
  email: string,
  firstName: string,
  lastName: string,
  fullName: string,
  role: arrayOf(string),
  hasAcceptedTermsAndConditions: bool,
  termsAndConditionsAcceptedAt: string,
  contact: shape(contactModelShape),
});
