import React from 'react';
import { string } from 'prop-types';

import { LoadingSquareStyled } from './LoadingSquareStyles';

export const LoadingSquare = ({ width }) => {
  return (
    <LoadingSquareStyled width={width}>
      <div />
    </LoadingSquareStyled>
  );
};

LoadingSquare.propTypes = {
  width: string.isRequired,
};
