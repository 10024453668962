/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

import AspectRatio from '../AspectRatio/AspectRatio';

export const ProfileImageSquare = styled(AspectRatio)`
  height: ${({ size }) => size || 'initial'};
  width: ${({ size }) => size || 'initial'};
  max-height: 10.5rem;
  max-width: 10.5rem;
  flex-shrink: 0; // fixes flex-box from shrinking the image width
`;

const ProfileImageInitialsComponent = ({ initials, bgColor, ...props }) => (
  <div data-test="PROFILE_INITIALS" {...props}>
    <svg
      width="45%"
      height="45%"
      viewBox="0 0 16 16"
      focusable="false"
      aria-hidden="true"
    >
      <text x="50%" y="55%" dominantBaseline="central" textAnchor="middle">
        {initials.toUpperCase()}
      </text>
    </svg>
  </div>
);

ProfileImageInitialsComponent.propTypes = {
  bgColor: string.isRequired,
  initials: string.isRequired,
};

export const ProfileImageInitials = styled(ProfileImageInitialsComponent)`
  && {
    svg {
      overflow: visible;
      fill: currentColor;
    }
  }

  border-radius: 50%;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: ${props => props.bgColor};
`;

export const ProfileImagePhoto = styled.div`
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-image: url(${props => props.src});
  background-color: #1c222e;
  background-size: cover;
`;
