import styled from 'styled-components';

import { PALETTE } from '../../../themes/constants';

export const RadioButtonContainer = styled.div`
  align-items: flex-start;
  ${props => !props.$disabled && 'cursor: pointer;'}
  display: flex;
  font-size: 1rem;
  margin-bottom: initial !important;
  vertical-align: middle;
  align-items: center;

  // TEMP BOOTSTRAP YANK CARRYOVER
  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }

  .form-check-input {
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  input[type='checkbox'],
  input[type='radio'] {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }
`;

export const RadioButtonLabel = styled.label`
  margin-top: 0.1rem;

  label {
    color: ${props => (props.$disabled ? PALETTE.grays.gray3 : 'inherit')};
    font-size: 1rem;
    font-weight: ${props => (props.$bold ? 'bold' : 'normal')};
    margin-bottom: 0;
    padding-left: 0.5rem;
  }
`;

export const RadioButtonStyledIcon = styled.svg`
  cursor: pointer;
  display: inline-block;
  margin-right: 1.25rem;
  width: 25px;
  height: 25px;
`;

export const RadioButtonIconWrapper = styled.div`
  &:focus-within {
    ${RadioButtonStyledIcon} {
      outline: 3px solid ${PALETTE.colors.gold};
    }
  }

  color: ${({ theme, highlighted }) =>
    highlighted ? theme.colors.axiomOrange : 'initial'};
`;
