import React from 'react';
import {
  Card,
  CardSection,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  LargeHeader,
  Paragraph,
  StopwatchIcon,
} from '@axiom/ui';

export const TalentDetailsRateLimitInfo = () => (
  <Card name="TALENT_DETAILS_RATE_LIMIT_CARD">
    <CardSection>
      <Grid stackable verticalAlign="middle">
        <GridRow>
          <GridColumn largeScreenWidth={2} tabletWidth={3} mobileWidth={12}>
            <StopwatchIcon width={110} height={110} />
          </GridColumn>
          <GridColumn largeScreenWidth={10} tabletWidth={9} mobileWidth={12}>
            <LargeHeader>Same time tomorrow?</LargeHeader>
            <Gutter bottom="16px" />

            <Paragraph>
              We’re excited that you’ve checked out so many Axiom talent bios
              and want to see more. We get it — we love our talent too! Due to a
              daily viewing limit of 25 bios, we’re not able to show you more
              right now. But don’t worry, if you didn’t find everything you were
              looking for, just come back tomorrow.
            </Paragraph>
          </GridColumn>
        </GridRow>
      </Grid>
    </CardSection>
  </Card>
);
