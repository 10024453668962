import flow from 'lodash/flow';

const alberta = /alberta/i;
const ontario = /ontario/i;

const replaceCanadaAlberta = (name: string) =>
  name.match(alberta)
    ? 'Licensed to practice law by the Law Society of Alberta'
    : name;

const replaceCanadaOntario = (name: string) =>
  name.match(ontario)
    ? 'Licensed to practice law by the Law Society of Ontario'
    : name;

export const replaceCanadian = flow([
  replaceCanadaAlberta,
  replaceCanadaOntario,
]);

export const BarredLocationsUtil = {
  replaceCanadian,
};
