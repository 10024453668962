import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardFooter,
  CardSection,
  CondensedHeader,
  CondensedLarge,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  HeaderLink,
  ImageCircle,
  Layout,
  Divider,
  SmallHeader,
  Well,
  OccupationTypeUtil,
  useApi,
} from '@axiom/ui';
import { CandidateImageConst } from '@axiom/const';
import {
  CandidateIndustryModel,
  CandidateYearsOfExperienceModel,
} from '@axiom/ui-models';
import { ExternalCandidate, PracticeArea, User } from '@axiom/validation';

import { FormInquireWithAxiomModalStore } from '../../stores/form-inquire-with-axiom-modal-store';
import { isClientUser, isLoggedInUser, isLpUser } from '../../utils/roles';
import { BarredLocationsUtil } from '../../utils/barredLocations-util';
import { CandidateUtil } from '../../utils/candidate-util';
import { LinkUtil } from '../../utils/link-util';
import { TalentRateAndAvailabilityRow } from '../TalentRateAndAvailabilityRow/TalentRateAndAvailabilityRow';
import { FavoriteButton } from '../FavoriteButton/FavoriteButton';
import { CandidateBadges } from '../CandidateBadges/CandidateBadges';
import { TaxonomyApi } from '../../api/taxonomy/taxonomy';

const { PossibleImageSizes } = CandidateImageConst;

export const TalentSearchCard = ({
  candidate,
  practiceAreas,
  user,
}: {
  candidate: ExternalCandidate;
  practiceAreas: PracticeArea[];
  user: User;
}) => {
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());
  const displayableIndustries = new CandidateIndustryModel(
    candidate.industries
  ).getDisplayableIndustries();
  const formattedBarredLocationNames = candidate.barredLocations.map(d =>
    BarredLocationsUtil.replaceCanadian(d.name)
  );
  const formattedPracticeAreaSpecialty =
    CandidateUtil.formatPracticeAreaSpecialty(
      practiceAreas,
      candidate.practiceArea?.id
    );
  const yearsOfExperienceRange = new CandidateYearsOfExperienceModel(
    candidate?.yearsOfExperience
  ).getYearsOfExperienceRange();
  const formattedLawFirms = CandidateUtil.getFormattedLawFirms(candidate);
  const formattedLegalRole = OccupationTypeUtil.professionFromOccupationType(
    candidate.occupationType,
    user
  );
  const formattedLocation = CandidateUtil.formatCandidateLocation(
    candidate,
    isLoggedInUser(user)
  );

  return (
    <Card name="TALENT_SEARCH_CARD">
      <CardSection divider>
        <Gutter bottom="16px">
          <Layout position="middle">
            <div>
              <ImageCircle
                name="TALENT_SEARCH_CARD_IMAGE_CIRCLE"
                imageName={candidate.calculatedDisplayName}
                src={CandidateUtil.getProfileImageUri(
                  candidate,
                  PossibleImageSizes.W_100
                )}
              />
            </div>
            <Gutter left="16px">
              <HeaderLink>
                <SmallHeader name="TALENT_SEARCH_CARD_SHARE_LINK">
                  <Link to={LinkUtil.getSharePath(candidate, practiceAreas)}>
                    {candidate.calculatedDisplayName}
                  </Link>
                </SmallHeader>
              </HeaderLink>
              {candidate.badges?.length > 0 && (
                <>
                  <Gutter bottom="12px" />
                  <CandidateBadges badges={candidate.badges} />
                </>
              )}
            </Gutter>
          </Layout>
        </Gutter>
        <Gutter bottom="16px">
          <TalentRateAndAvailabilityRow candidate={candidate} user={user} />
        </Gutter>
        <Grid>
          {formattedPracticeAreaSpecialty && (
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                <CondensedHeader>Practice Area</CondensedHeader>
              </GridColumn>
              <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
                <CondensedLarge name="TALENT_SEARCH_CARD_PRACTICE_AREA">
                  {formattedPracticeAreaSpecialty}
                </CondensedLarge>
              </GridColumn>
            </GridRow>
          )}
          {displayableIndustries.length > 0 && (
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                <CondensedHeader>Industries</CondensedHeader>
              </GridColumn>
              <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
                <CondensedLarge name="TALENT_SEARCH_CARD_INDUSTRIES">
                  <Divider>
                    {displayableIndustries.map(
                      i => taxonomy.industries[i.industryValue].displayValue
                    )}
                  </Divider>
                </CondensedLarge>
              </GridColumn>
            </GridRow>
          )}
          {yearsOfExperienceRange !== '--' && (
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                <CondensedHeader>Years of Experience</CondensedHeader>
              </GridColumn>
              <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
                <CondensedLarge name="TALENT_SEARCH_CARD_YEARS_OF_EXPERIENCE">
                  {yearsOfExperienceRange}
                </CondensedLarge>
              </GridColumn>
            </GridRow>
          )}
          {formattedLawFirms?.length > 0 && (
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                <CondensedHeader>Law Firms</CondensedHeader>
              </GridColumn>
              <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
                <CondensedLarge name="TALENT_SEARCH_CARD_LAW_FIRMS">
                  <Divider>{formattedLawFirms.map(l => l.client)}</Divider>
                </CondensedLarge>
              </GridColumn>
            </GridRow>
          )}
          {candidate.lawDegreeSchools?.length > 0 && (
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                <CondensedHeader>Law Schools</CondensedHeader>
              </GridColumn>
              <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
                <CondensedLarge name="TALENT_SEARCH_CARD_LAW_DEGREE_SCHOOLS">
                  <Divider>
                    {candidate.lawDegreeSchools.map(e => e.institution)}
                  </Divider>
                </CondensedLarge>
              </GridColumn>
            </GridRow>
          )}
          {formattedLocation && (
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                <CondensedHeader>Location</CondensedHeader>
              </GridColumn>
              <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
                <CondensedLarge name="TALENT_SEARCH_CARD_LOCATION">
                  {formattedLocation}
                </CondensedLarge>
              </GridColumn>
            </GridRow>
          )}
          {formattedBarredLocationNames?.length > 0 && (
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                <CondensedHeader>Admissions</CondensedHeader>
              </GridColumn>
              <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
                <CondensedLarge name="TALENT_SEARCH_CARD_BARRED_LOCATIONS">
                  <Divider>{formattedBarredLocationNames}</Divider>
                </CondensedLarge>
              </GridColumn>
            </GridRow>
          )}
          {formattedLegalRole && formattedLegalRole !== 'Lawyer' && (
            <GridRow gutterBottom="16px">
              <GridColumn largeScreenWidth={4} smallScreenWidth={12}>
                <CondensedHeader>Legal Role</CondensedHeader>
              </GridColumn>
              <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
                <CondensedLarge name="TALENT_SEARCH_CARD_LEGAL_ROLE">
                  {formattedLegalRole}
                </CondensedLarge>
              </GridColumn>
            </GridRow>
          )}
          {candidate.publicCandidateSummary && (
            <GridRow gutterBottom="16px">
              <GridColumn width={12}>
                <Well>
                  <CondensedLarge name="TALENT_SEARCH_CARD_SUMMARY">
                    {candidate.publicCandidateSummary}
                  </CondensedLarge>
                </Well>
              </GridColumn>
            </GridRow>
          )}
        </Grid>
      </CardSection>
      <CardFooter stackableOn="mobile">
        <FavoriteButton user={user} candidate={candidate} />
        <Button
          disabled={isLpUser(user)}
          name="TELL_ME_MORE_BUTTON"
          onClick={() => {
            FormInquireWithAxiomModalStore.load(
              candidate,
              isClientUser(user) ? user : null
            );
          }}
        >
          Request Info
        </Button>
      </CardFooter>
    </Card>
  );
};
