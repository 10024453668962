import React from 'react';
import { CandidateOpportunitiesConst } from '@axiom/const';
import { Flashy } from '@axiom/ui';

import { CandidateAccountShape } from '../../models/candidate';
import { CandidateUtil } from '../../utils/candidate-util';

export const BCAdditionalAvailability = ({ candidate }) => {
  const isEngaged =
    candidate.accountCandidateStatus ===
    CandidateOpportunitiesConst.CandidateStatuses.Engaged;
  const billingHoursPerWeekCalculation =
    CandidateUtil.calculateBillingHoursPerWeek(candidate);

  if (isEngaged) {
    if (
      !candidate.weeklyAvailability ||
      (billingHoursPerWeekCalculation >= 40 &&
        candidate.weeklyAvailability <= 0) ||
      (billingHoursPerWeekCalculation < 40 && candidate.weeklyAvailability <= 0)
    ) {
      return 'Not available for more work';
    }

    return <Flashy bold>Available for more work</Flashy>;
  } else if (
    !candidate.weeklyAvailability ||
    candidate.weeklyAvailability === 0
  ) {
    return 'Not available';
  } else if (candidate.weeklyAvailability < 40) {
    return <Flashy bold>Available part time</Flashy>;
  }

  return <Flashy bold>Available full-time</Flashy>;
};

BCAdditionalAvailability.propTypes = {
  candidate: CandidateAccountShape.isRequired,
};
