import React, { useState } from 'react';
import moment from 'moment-timezone';
import { z } from 'zod';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  GridColumn,
  Grid,
  GridRow,
  Paragraph,
  Gutter,
  CalendarTimeZoneUtil,
  Dropdown,
  Form,
  Calendar,
  CalendarPermissionsUtil,
  Layout,
  LayoutItem,
  ImageCircle,
  Card,
  CardSection,
  Checkbox,
  DateUtil,
  CandidateProfileUtil,
  CalendarEventsUtil,
} from '@axiom/ui';
import {
  PossibleImageSizes,
  PermissionImpersonationRoles,
  NO_TIMEZONE,
} from '@axiom/const';
import {
  SubmissionCandidate,
  Calendar as CalendarApiType,
  User,
  CalendarEventType,
  Contact,
  Submission,
  Account,
  CalendarEventSchema,
} from '@axiom/validation';

import { CalendarApi } from '../../api/calendar-api';
import { CandidateApi } from '../../api/candidate/candidate';

import {
  ClientEngagementsTalentInterviewConfirmModal,
  InterviewContactsType,
} from './ClientEngagementsTalentInterviewConfirmModal';
import { LegendKey } from './ClientEngagementsTalentInterviewScheduleModalStyles';

export interface InterviewScheduleModalType {
  account: Account;
  submission: Submission;
  candidate: SubmissionCandidate;
  onClose: () => void;
  onConfirm?: (
    arg: InterviewContactsType,
    scheduledEvent: CalendarEventType
  ) => Promise<unknown>;
  calendarData: CalendarApiType;
  onOpenSecondFlow: () => void;
  user: User;
  primaryContactId?: Contact['id'];
}

const NAME = 'CALENDAR_COMPONENT' as const;
const CalendarSchema = z.object({
  [NAME]: CalendarEventSchema.array(),
  TIMEZONE_DROPDOWN: z.string().uuid(),
  NO_AVAILABILITY: z.boolean().default(false),
});
type CalendarType = z.infer<typeof CalendarSchema>;

export const ClientEngagementsTalentInterviewScheduleModal = ({
  account,
  submission,
  candidate,
  calendarData,
  onClose,
  onConfirm,
  onOpenSecondFlow,
  user,
  primaryContactId,
}: InterviewScheduleModalType) => {
  const dayWeekMode = {
    slots: 2,
    increments: 30,
  };
  const { userPermissions } = CalendarPermissionsUtil(
    user,
    PermissionImpersonationRoles.client
  );

  const calendarPermissions = userPermissions();
  const { timezone: talentIana } = candidate.calendar || {};
  const guessIana = moment.tz.guess();
  const timezone = CalendarTimeZoneUtil.getZoneFromMomentZone(
    moment.tz(new Date(), talentIana || guessIana)
  );
  const timezoneOptions = CalendarTimeZoneUtil.getDropDownOptions();
  const [confirmModalOpen, setConfirmModalOpen] = useState([]);
  const [calculatedTimezone, setCalculatedTimezone] = useState(timezone);

  const onNextClick = (formData: CalendarType) => {
    if (formData?.NO_AVAILABILITY) {
      onOpenSecondFlow();
    } else if (formData[NAME].length > 0) {
      setConfirmModalOpen(formData[NAME]);
    }
  };

  const backgroundEvents = (calendarData?.events || []).filter(
    event => !event.busy && moment().diff(event.startTime, 'days') > -90
  );

  const refreshData = async () => {
    await Promise.all([
      CalendarApi.refreshCalendar(calendarData.id),
      CalendarApi.refreshCalendarEvents(calendarData.id),
      CandidateApi.refreshCandidate(candidate.id),
    ]);
  };

  const handleUpdateOnClick = (
    formData: CalendarType,
    {
      setFieldError,
    }: { setFieldError: (field: string, message: string) => void }
  ) => {
    const hasError = CalendarEventsUtil.getError(
      formData?.[NAME],
      formData?.TIMEZONE_DROPDOWN,
      formData?.NO_AVAILABILITY
    );
    if (hasError) {
      setFieldError(NAME, hasError);

      return;
    }

    setCalculatedTimezone(
      CalendarTimeZoneUtil.getZoneFromId(
        formData?.TIMEZONE_DROPDOWN ?? timezone.id
      )
    );
    onNextClick(formData);
  };

  return (
    <>
      {confirmModalOpen.length === 0 ? (
        <Form
          name="INTERVIEW_AUTO_FORM"
          schema={CalendarSchema}
          initialValues={{
            [NAME]: calendarData?.events?.filter(e => e.busy) || [],
            TIMEZONE_DROPDOWN: timezone === NO_TIMEZONE ? null : timezone.id,
            NO_AVAILABILITY: false,
          }}
          onSubmit={handleUpdateOnClick}
        >
          {({ fireSubmit, values }) => {
            return (
              <Modal size="large" name="INTERVIEW_SCHEDULE_MODAL">
                <ModalHeader
                  onClose={onClose}
                  name="INTERVIEW_SCHEDULE_MODAL_HEADER"
                >
                  <Layout position="middle" wrap>
                    <LayoutItem rightGutter="16px">
                      <ImageCircle
                        imageName={candidate.calculatedDisplayName}
                        src={CandidateProfileUtil.getProfileImageUri(
                          candidate,
                          PossibleImageSizes.W_100
                        )}
                        size="small"
                        name="TALENT_IMAGE"
                      />
                    </LayoutItem>
                    <LayoutItem fluid name="HEADER_TEXT">
                      {`Interview ${candidate.calculatedFirstName}`}
                    </LayoutItem>
                  </Layout>
                </ModalHeader>
                <ModalSection>
                  <Grid>
                    <GridRow>
                      <GridColumn widescreenWidth={4} smallScreenWidth={12}>
                        <Paragraph name="SCHEDULE_GREETING">
                          Hello! Let us know when you’re available to interview{' '}
                          {candidate.calculatedFirstName} and we’ll set
                          everything up. We’ve highlighted{' '}
                          {candidate.calculatedFirstName}’s availability in the
                          calendar. Please select one 30-minute time slot that
                          will work for you over the next 2 weeks.
                        </Paragraph>
                        <Gutter bottom="24px" />
                        <Dropdown
                          label="Time zone"
                          options={timezoneOptions}
                          name="TIMEZONE_DROPDOWN"
                          displayKey="label"
                          valueKey="value"
                        />
                        <Gutter bottom="16px" />
                      </GridColumn>
                      <GridColumn widescreenWidth={8} smallScreenWidth={12}>
                        <Card>
                          <CardSection divider>
                            <Calendar
                              name={NAME}
                              permissions={calendarPermissions}
                              backgroundEvents={backgroundEvents || []}
                              dayMode={dayWeekMode}
                              weekMode={dayWeekMode}
                              timezone={
                                values.TIMEZONE_DROPDOWN
                                  ? CalendarTimeZoneUtil.getZoneFromId(
                                      values.TIMEZONE_DROPDOWN
                                    ).iana
                                  : timezone.iana
                              }
                            />
                            <Gutter bottom="8px" />
                            <Layout
                              position="left middle"
                              horizontalGutter="8px"
                            >
                              <LegendKey />
                              <Paragraph name="LEGEND_TEXT">{`${candidate.calculatedFirstName}'s availability`}</Paragraph>
                            </Layout>
                          </CardSection>
                          <CardSection divider>
                            <Checkbox
                              name="NO_AVAILABILITY"
                              displayValue="None of these times work for me, please call me to discuss."
                            />
                          </CardSection>
                        </Card>
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </ModalSection>
                <ModalFooter>
                  <Button
                    name="CANCEL_BUTTON"
                    variation="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button name="NEXT_BUTTON" onClick={fireSubmit}>
                    Next
                  </Button>
                </ModalFooter>
              </Modal>
            );
          }}
        </Form>
      ) : (
        <ClientEngagementsTalentInterviewConfirmModal
          onBackHandler={async () => {
            await refreshData();
            setConfirmModalOpen([]);
          }}
          account={account}
          loggedInUser={user}
          submission={submission}
          candidate={candidate}
          onClose={onClose}
          onConfirm={onConfirm}
          scheduledEvent={confirmModalOpen[0]}
          interviewDate={`${DateUtil.displayBannerTimestamp(
            confirmModalOpen[0].startTime,
            confirmModalOpen[0].endTime,
            calculatedTimezone
          )} ${calculatedTimezone.abbr} ${calculatedTimezone.name}`}
          primaryContactId={primaryContactId}
        />
      )}
    </>
  );
};
