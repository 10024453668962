import React from 'react';
import pickBy from 'lodash/pickBy';
import isObjectLike from 'lodash/isObjectLike';
import { func, instanceOf, shape, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';

import FormStore from '../../classes/form-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { ObjectUtil } from '../../utils/object-util';
import { GenericStoreStructure } from '../../classes/generic-store';
import { Mutators } from '../../utils/form-mutators';

const FormStateTreatment = ({
  formStore,
  renderLoadedView,
  renderNonLoadedView,
  submit,
  validate,
  onSubmit,
  onValidate,
  dataState,
  isModalState,
  name,
  allowOverflow,
  showRefetchLoader,
}) => (
  <StoreStateTreatment
    name={name}
    isModalState={isModalState}
    storeState={dataState}
    allowOverflow={allowOverflow}
    renderNonLoadedView={() => renderNonLoadedView()}
    showRefetchLoader={showRefetchLoader}
    renderLoadedView={({ data }) => (
      <Form
        initialValues={data}
        mutators={Mutators}
        onSubmit={(formData, form) => {
          const changedData = ObjectUtil.getStructureChanges(
            form.getState().initialValues,
            formData
          );

          formData = isObjectLike(formData)
            ? pickBy(formData, v => v !== undefined)
            : formData;

          const result = submit
            ? submit(changedData, formData)
            : formStore.submit(changedData, formData);

          if (result && typeof result.then === 'function') {
            return result.then(promiseData => {
              onSubmit(promiseData);
              return promiseData;
            });
          }
          onSubmit(result);
          return result;
        }}
        validate={formData => {
          const result = validate
            ? validate(formData)
            : formStore.validate(formData);
          if (result && typeof result.then === 'function') {
            return result.then(promiseData => {
              onValidate(formData, promiseData);
              return promiseData;
            });
          }

          onValidate(formData, result);
          return result;
        }}
        render={({ handleSubmit, values, form }) => (
          <form noValidate onSubmit={handleSubmit}>
            {renderLoadedView({
              formData: values,
              fireSubmit: event => handleSubmit(event),
              fireCancel: () => formStore.cancel(),
              fireReset: () => form.reset(),
              form,
            })}
          </form>
        )}
      />
    )}
  />
);

/* eslint-disable no-unused-vars */
// form is from https://final-form.org/docs/final-form/types/FormApi
FormStateTreatment.defaultProps = {
  allowOverflow: false,
  isModalState: false,
  name: undefined,
  onSubmit: (changedData, formData) => changedData,
  onValidate: (formData, result) => result,
  renderLoadedView: ({ form, formData, fireCancel, fireSubmit }) => {},
  renderNonLoadedView: () => {},
  showRefetchLoader: true,
  submit: null,
  validate: null,
};
/* eslint-enable no-unused-vars */

FormStateTreatment.propTypes = {
  allowOverflow: bool,
  dataState: shape(GenericStoreStructure).isRequired,
  formStore: instanceOf(FormStore).isRequired,
  isModalState: bool,
  name: string,
  onSubmit: func,
  onValidate: func,
  renderLoadedView: func,
  renderNonLoadedView: func,
  showRefetchLoader: bool,
  submit: func,
  validate: func,
};

export default connect((state, ownProps) => ({
  dataState: ownProps.formStore.select(state),
}))(FormStateTreatment);
