// @Kevin TODO: Write tests for these once fully baked
// eslint-disable-next-line max-classes-per-file
export const createDeveloperErrorString = ({
  HTTPErrorCode,
  HTTPMethod,
  apiName,
  response,
  extras = {},
}) => [
  `[ ${apiName} ${HTTPMethod} ] API ${HTTPErrorCode} ENCOUNTERED. Response was ${JSON.stringify(
    response
  )}`,
  extras,
];

export const convertAPINameToUserFriendlyText = (apiName, HTTPMethod) => {
  return HTTPMethod;
};

export const convertHTTPErrorCodeToUserErrorCode = (apiName, HTTPMethod) => {
  return HTTPMethod;
};

export const createAPIErrorObject = ({
  HTTPErrorCode,
  HTTPMethod,
  apiName,
  response,
  extras,
  errors,
}) => ({
  id: `${Date.now()}.${apiName}.${JSON.stringify(response)}.${JSON.stringify(
    extras
  )}`,
  userErrorText: `There was a problem ${convertAPINameToUserFriendlyText(
    apiName,
    HTTPMethod
  )}. Error Code: ${convertHTTPErrorCodeToUserErrorCode(
    apiName,
    HTTPMethod
  )}${HTTPErrorCode}`,
  developerErrorText: createDeveloperErrorString({
    HTTPErrorCode,
    HTTPMethod,
    apiName,
    response,
    extras,
  }),
  apiName,
  response,
  errors,
});

export const HIDDEN_FIELD_ERROR_CODES = [
  'extra_field',
  'requires_at_least_one',
];

export const fieldErrorFormatters = {
  enum_mismatch: ({ expectedValues = [] }) =>
    `Must be one of (${expectedValues.join(', ')}).`,
  extra_field: ({ field }) =>
    `Field "${field}" is unknown or cannot be edited.`,
  missing_required: () => `This field is required.`,
  requires_at_least_one: () => 'At least one field must be filled in.',
  too_long: ({ maxLength }) => `Can't be longer than ${maxLength} characters.`,
  too_short: ({ minLength }) =>
    `Can't be shorter than ${minLength} characters.`,
  wrong_format: ({ expectedFormat = '' }) =>
    `Must be a${/^[aeio]/i.test(expectedFormat) ? 'n' : ''} ${expectedFormat}.`,
  wrong_type: ({ expectedType = '' }) =>
    `Must be a${/^[aeio]/i.test(expectedType) ? 'n' : ''} ${expectedType}.`,
};

export const formatFieldError = e => {
  if (typeof e === 'string') return e;
  const formatter = fieldErrorFormatters[e.code];
  if (formatter) return formatter(e);
  return e.message || JSON.stringify(e);
};

export class BadRequestError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('Bad Request Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      body,
      errors: body.errors,
      extras: { endpoint, headers, body },
      response,
    });
    this.applicationError = errorObj;
  }

  getReduxFormErrors = () => {
    const { errors } = this.applicationError;
    const formErrors = {};
    try {
      let apiErrors = 0;
      errors.forEach(e => {
        let { field } = e;
        if (!field || HIDDEN_FIELD_ERROR_CODES.includes(e.code)) {
          apiErrors += 1;
          field = `__error${apiErrors}`;
        }
        formErrors[field] = formatFieldError(e);
      });
    } catch (e) {
      console.error('Error parsing API error', e); // eslint-disable-line no-console
    }
    return formErrors;
  };
}

export class APIError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('API Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      response,
      errors: body.errors,
      body,
      extras: { endpoint, headers, body },
    });
    this.applicationError = errorObj;
  }
}

export class AuthorizationError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('Authorization Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      response,
      errors: body.errors,
      body,
      extras: { endpoint, headers, body },
    });
    this.applicationError = errorObj;
  }
}

export class AuthenticationError extends Error {
  constructor({ name, endpoint, method, body, headers, response }) {
    super('Authentication Error');
    const errorObj = createAPIErrorObject({
      HTTPErrorCode: response.status,
      HTTPMethod: method,
      apiName: name,
      response,
      errors: body.errors,
      body,
      extras: { endpoint, headers, body },
    });
    this.applicationError = errorObj;
  }
}
