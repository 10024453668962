import { shape, string } from 'prop-types';

export const CandidateLanguageShape = shape({
  id: string.isRequired,
  languageCEFR: string,
  languageProficiency: string.isRequired,
  languageSkill: string.isRequired,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});
