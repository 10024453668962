import React from 'react';
import { IconButton } from '@axiom/ui';
import { bool, func, shape, string } from 'prop-types';

import { Input } from '../FormElements/Input/Input';

import { TSFSearchContainer } from './TalentSearchSearchBoxStyles';

export const TalentSearchSearchBox = ({
  data,
  fireSubmit,
  onBlur,
  form,
  disabled,
}) => {
  return (
    <TSFSearchContainer>
      <Input
        name="search"
        type="text"
        placeholder="Search"
        onBlur={() => !disabled && onBlur()}
        hideCharCount
        disabled={disabled}
        onChange={() => {
          if (!disabled && data.hasSearched) {
            form.change('hasSearched', false);
          }
        }}
      />
      {data.hasSearched ? (
        <IconButton
          pattern="secondary"
          variation="minimal"
          icon="x-mark"
          type="submit"
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              data.search = '';
              data.hasSearched = false;
              fireSubmit();
            }
          }}
        />
      ) : (
        <IconButton
          pattern="secondary"
          variation="minimal"
          icon="search"
          type="submit"
          disabled={disabled}
          onClick={() => {
            if (!disabled) data.hasSearched = true;
          }}
        />
      )}
    </TSFSearchContainer>
  );
};

TalentSearchSearchBox.defaultProps = {
  onBlur: () => {},
  form: { change: () => {} },
  disabled: null,
};

TalentSearchSearchBox.propTypes = {
  data: shape({
    hasSearched: bool,
    search: string,
  }).isRequired,
  fireSubmit: func.isRequired,
  onBlur: func,
  form: shape({ change: func }),
  disabled: bool,
};
