import styled from 'styled-components';

export const GhostCard = styled.div`
  background: ${({ theme }) => theme?.neutrals?.lightest};
  border-radius: 8px;
  padding: 22px 30px;
  margin-bottom: 32px;
  width: 100%;
`;

export const GhostHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;

  div:first-child {
    margin-right: 16px;
  }
`;
