import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  GridColumn,
  GridRow,
  Suspense,
  Loader,
  ClientEngagementNoTalentMessage,
} from '@axiom/ui';

import { EnvUtil } from '../../utils/env-util';
import { useUserAccountData } from '../../hooks/useUserAccountData';

import { useClientEngagementsCurrentSubmissionData } from './useClientEngagementsCurrentSubmissionData';
import { ClientEngagementsEngagementTalentList } from './ClientEngagementsEngagementTalentList';
import { usePreloadCandidates } from './usePreloadCandidates';

export const ClientEngagementsEngagementTalent = () => {
  const navigate = useNavigate();
  const { talentSlug } = useParams();
  const { account } = useUserAccountData();

  const {
    currentSubmissionCandidateToDisplay,
    currentSubmissionCandidateWithSlug,
    currentSubmission,
    invalidSubmissionRequest,
    groupedCandidateOpps,
  } = useClientEngagementsCurrentSubmissionData({ account });

  usePreloadCandidates(
    account.id,
    currentSubmission,
    currentSubmissionCandidateToDisplay?.candidate?.id
  );

  const currentSubmissionCandidatesIsNotEmpty = groupedCandidateOpps.length > 0;
  const multipleRolesPresentInPositions = groupedCandidateOpps.length > 1;

  useEffect(() => {
    // currently, navigating to no one selected when a bad slug is present,
    // future work will show a not-found message in that case instead
    // e.g. link to talent detail was shared but talent has since been removed
    if (
      groupedCandidateOpps &&
      talentSlug &&
      !currentSubmissionCandidateWithSlug
    ) {
      navigate('..');
    }
  }, [groupedCandidateOpps, talentSlug]);

  useEffect(() => {
    if (invalidSubmissionRequest) {
      navigate(`/projects/account/${account.id}`);
    }
  }, [invalidSubmissionRequest]);

  return invalidSubmissionRequest ? null : (
    <div data-test="matches-tab">
      <Suspense fallback={<Loader type="bars" />}>
        {currentSubmissionCandidatesIsNotEmpty ? (
          <Grid>
            <GridRow name="talent-detail-row">
              <GridColumn largeScreenWidth={4} tabletWidth={4} mobileWidth={12}>
                {groupedCandidateOpps.map(group => (
                  <div key={group.title}>
                    <ClientEngagementsEngagementTalentList
                      hasMultipleRoles={multipleRolesPresentInPositions}
                      candidateOpportunities={group.data}
                      selectedCandidateId={
                        currentSubmissionCandidateToDisplay.candidate.id
                      }
                      submission={currentSubmission}
                      title={group.title}
                    />
                  </div>
                ))}
              </GridColumn>
              <GridColumn largeScreenWidth={8} tabletWidth={8} mobileWidth={12}>
                <Outlet />
              </GridColumn>
            </GridRow>
          </Grid>
        ) : (
          <ClientEngagementNoTalentMessage
            talentFinderUrl={EnvUtil.hubspotTalentFinder}
          />
        )}
      </Suspense>
    </div>
  );
};
