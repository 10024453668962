import qs from 'qs';

export const WindowUtil = {
  exists() {
    return typeof window !== 'undefined';
  },

  parseQuery() {
    return qs.parse(window.location.search.slice(1));
  },
};
