/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import styled from 'styled-components';
import {
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { Field } from 'react-final-form';

import {
  FormElementError,
  isErroring,
} from '../FormElementError/FormElementError';
import { FormElementMaxChars } from '../FormElementMaxChars/FormElementMaxChars';
import { Label } from '../Label/Label';
import { formatDataTestId } from '../../../utils/dataTest';

export const Input = ({
  className,
  disabled,
  extraSubText,
  hideCharCount,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onMouseOver,
  onMouseLeave,
  placeholder,
  type,
}) => {
  const renderLabel = () => {
    if (!label) {
      return null;
    }

    return typeof label === 'string' ? <Label>{label}</Label> : label;
  };

  return (
    <>
      {renderLabel()}
      <Field name={name}>
        {props => (
          <RawInput
            {...props}
            className={className}
            disabled={disabled}
            extraSubText={extraSubText}
            hideCharCount={hideCharCount}
            maxLength={maxLength}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            placeholder={placeholder}
            type={type}
          />
        )}
      </Field>
    </>
  );
};

Input.propTypes = {
  className: string,
  disabled: bool,
  extraSubText: string,
  hideCharCount: bool,
  label: oneOfType([string, shape(Label.propTypes)]),
  maxLength: number,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onClick: func,
  onFocus: func,
  onMouseOver: func,
  onMouseLeave: func,
  placeholder: oneOfType([string, number]),
  type: oneOf(['text', 'number', 'email', 'tel', 'url', 'textarea']),
};

Input.defaultProps = {
  className: null,
  disabled: false,
  extraSubText: '',
  hideCharCount: false,
  label: null,
  maxLength: 255,
  onBlur: () => null,
  onChange: () => null,
  onClick: () => null,
  onFocus: () => null,
  onMouseOver: () => null,
  onMouseLeave: () => null,
  placeholder: null,
  type: 'text',
};

// overriding the default error indicator
const InputWrapper = styled.div`
  // TEMP BOOTSTRAP YANK CARRYOVER
  input {
    margin: 0;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.175rem 0.55rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
    background-clip: padding-box;
    border-radius: 0;
    -webkit-transition: border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    -moz-transition: border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .k-widget,
  .form-control:not(.is-invalid):not(textarea) {
    border: none !important;
    border-bottom: solid 1px #ccc !important;
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background: none;
    border-color: #c62a00;
    padding-right: 0.55rem;
  }
`;

export const FlexLeftWrapper = styled.div`
  flex: initial;
  display: inline-flex;
  flex-direction: column;
`;

export const FlexRightWrapper = styled.div`
  flex: 1 auto;
  width: auto;
  text-align: end;

  .below-input-text {
    color: ${({ theme }) => theme.neutrals.neutral50};
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
`;

const RawInput = ({
  className,
  disabled,
  hideCharCount,
  input,
  maxLength,
  meta,
  onBlur,
  onChange,
  onFocus,
  onMouseLeave,
  onMouseOver,
  onClick,
  placeholder,
  type,
}) => {
  const InputElm = React.createElement(
    type === 'textarea' ? 'textarea' : 'input',
    {
      name: input.name,
      onChange: e => {
        input.onChange(e);
        onChange(e.target.value);
      },
      onBlur: e => {
        input.onBlur(e);
        onBlur(e.target.value);
      },
      onFocus: e => {
        input.onFocus(e);
        onFocus(e.target.value);
      },
      onMouseOver: e => {
        onMouseOver(e);
      },
      onMouseLeave: e => {
        onMouseLeave(e);
      },
      className: `form-control ${isErroring(meta) && 'is-invalid'}`,
      type,
      maxLength,
      value: input.value,
      placeholder,
      disabled,
      'data-test': formatDataTestId(`${input.name}_INPUT`),
    }
  );

  return (
    <InputWrapper
      className={className}
      data-test={formatDataTestId(input.name)}
      onClick={onClick}
    >
      {InputElm}
      <FlexWrapper className="flex-wrapper">
        <FlexLeftWrapper>
          <FormElementError finalFormElementMeta={meta} />
        </FlexLeftWrapper>
        <FlexRightWrapper>
          {maxLength && !hideCharCount && type !== 'number' && (
            <FormElementMaxChars maxLength={maxLength} value={input.value} />
          )}
        </FlexRightWrapper>
      </FlexWrapper>
    </InputWrapper>
  );
};

RawInput.propTypes = {
  disabled: bool,
  className: string,
  hideCharCount: bool,
  input: shape({
    value: oneOfType([string, number]),
    name: string,
    onChange: func,
    onClick: func,
    onBlur: func,
    onFocus: func,
  }).isRequired,
  maxLength: number,
  meta: shape({}).isRequired,
  onBlur: () => null,
  onChange: () => null,
  onClick: () => null,
  onFocus: () => null,
  onMouseOver: () => null,
  onMouseLeave: () => null,
  placeholder: oneOfType([string, number]),
  type: oneOf(['text', 'number', 'email', 'tel', 'url', 'textarea']),
};

RawInput.defaultProps = {
  className: null,
  disabled: false,
  hideCharCount: false,
  maxLength: FormElementMaxChars.defaultProps.maxLength,
  onBlur: func,
  onChange: func,
  onClick: func,
  onFocus: func,
  onMouseOver: func,
  onMouseLeave: func,
  placeholder: null,
  type: 'text',
};
