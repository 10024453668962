import styled, { css } from 'styled-components';
import { DataTestUtil, MediaQueryUtil } from '@axiom/ui';

import { getThemeProp } from '../../../themes';
import { PALETTE } from '../../../themes/constants';

const getBorderColor = (invalid, readOnly, theme) => {
  if (invalid) return `0.0625rem solid ${PALETTE.theme.danger}`;
  if (readOnly) return 'none';
  return `solid 1px ${theme.neutrals.malignant30}`;
};

export const RTEditorWrapper = styled.div`
  border: ${({ readOnly, invalid, theme }) =>
    getBorderColor(invalid, readOnly, theme)};
  padding: ${({ readOnly }) => (readOnly ? '0' : '0.5rem 1rem')};
  font-size: 1rem;
  line-height: 1.5rem;

  ol,
  ul {
    color: inherit;
    list-style: initial;
    padding-left: 40px;
    li {
      margin-bottom: 0;
    }
  }

  .editors,
  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    height: ${({ readOnly }) => (readOnly ? 'auto' : '100%')};
    min-height: ${({ readOnly }) => (readOnly ? 'auto' : '8rem')};

    ${MediaQueryUtil.largeScreenOnly(css`
      min-height: ${({ readOnly }) => (readOnly ? 'auto' : '5rem')};
    `)}
  }

  .public-DraftEditorPlaceholder-root {
    position: relative;
  }
  .public-DraftEditorPlaceholder-inner {
    position: absolute;
    pointer-events: none;
    color: gray;
    height: 7.5rem;
    overflow-y: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;

    ${MediaQueryUtil.largeScreenOnly(css`
      height: 5rem;
    `)}
  }

  .below-input-text {
    font-size: 0.875rem;
  }
`;

export const RTButtonsWrapper = styled.div`
  background-color: ${getThemeProp('appBackground')};
  border: ${({ readOnly, invalid, theme }) =>
    getBorderColor(invalid, readOnly, theme)};
  border-top: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
`;

// Extends UnstyledButton component. However referencing the component from
// themes/components causes ui-automation to break
export const RTButton = styled.button.attrs(props => ({
  'data-test': DataTestUtil.format(props.name),
  name: null,
  type: props.type || 'button',
}))`
  appearance: none;
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  padding: 0;

  cursor: pointer;
  flex: none;
`;

export const RTResetButton = styled(RTButton)`
  justify-self: flex-end;
  margin-left: auto;
  padding-left: 0.75em;
  padding-right: 0.75rem;

  svg {
    margin-right: 0.5rem;
    margin-bottom: -2px;
  }
`;
