import React from 'react';
import { string, func, number, node } from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Button,
  Gutter,
  Label,
  Text,
} from '@axiom/ui';
import { Trans } from '@lingui/macro';

import { Input } from '../../FormElements/Input/Input';

import {
  DBSTAMWrapper,
  FlexRightWrapper,
} from './DigitalBenchSingleTextAreaModalStyles';

export const DigitalBenchSingleTextAreaModal = ({
  extraSubText,
  fieldName,
  header,
  inputLabel,
  maxLength,
  name,
  onClose,
  onSubmit,
  placeholder,
  submitText,
}) => {
  return (
    <Modal name={name} size="confirm">
      <ModalHeader onClose={onClose}>{header}</ModalHeader>
      <ModalSection>
        <DBSTAMWrapper>
          <Gutter bottom="1rem">
            <Label>
              <Trans>{inputLabel}</Trans>
            </Label>
          </Gutter>

          <Gutter className="input-gutter" vertical="1rem">
            <Input
              id={fieldName}
              name={fieldName}
              maxLength={maxLength}
              placeholder={placeholder}
              type="textarea"
              className="input-wrapper"
              extraSubText={extraSubText}
            />
            <FlexRightWrapper>
              <Text className="below-input-text" name="EXTRA_SUBTEXT">
                {extraSubText}
              </Text>
            </FlexRightWrapper>
          </Gutter>
        </DBSTAMWrapper>
      </ModalSection>
      <ModalFooter>
        <Button name="cancel" variation="outline" onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>

        <Button name="submit" onClick={onSubmit}>
          {submitText || <Trans>Submit</Trans>}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DigitalBenchSingleTextAreaModal.defaultProps = {
  extraSubText: Input.defaultProps.extraSubText,
  fieldName: '',
  inputLabel: '',
  maxLength: null,
  name: '',
  onClose: () => {},
  onSubmit: () => {},
  submitText: null,
};

DigitalBenchSingleTextAreaModal.propTypes = {
  extraSubText: string,
  fieldName: string,
  header: string.isRequired,
  inputLabel: string,
  maxLength: number,
  name: string,
  onClose: func,
  onSubmit: func,
  placeholder: string.isRequired,
  submitText: node,
};
