import styled from 'styled-components';

export const FiltersSidedrawerBlock = styled.div`
  & > * {
    height: 100vh;
  }
`;
export const TalentSearchWrapper = styled.div`
  max-width: 100%;
`;

export const SortByBlock = styled.div`
  position: relative;
  & > * {
    position: absolute;
    right: 0;
  }
`;
