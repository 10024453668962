import { UserSavedCandidatesConst } from '@axiom/const';
import { Api } from '@axiom/ui';
import { CandidateOpportunity } from '@axiom/validation';

import { EnvUtil } from '../../utils/env-util';
import ApiHelper from '../../lib/api-helper';

const api = new ApiHelper('candidates');

class CandidateLegacyApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  getFavoriteCandidates() {
    return super.read<{ data: CandidateOpportunity[] }>({
      endpoint: `/user/me/saved/candidate`,
      query: { 'expand[0]': 'fullCandidateInfo' },
      method: 'GET',
    });
  }
}
export const CandidateLegacyApi = new CandidateLegacyApiClass();

export const getCandidate = async (id: string) => {
  const { data } = await api.GET(`/candidates/${id}`);

  return data;
};

export const postFavoriteCandidates = async (candidateIds: string[]) => {
  const { data } = await api.POST(`/user/me/saved/candidate/`, {
    candidateIds,
  });
  return data;
};

export const postFavoriteCandidate = async (candidateId: string) => {
  return postFavoriteCandidates([candidateId]);
};

export const getFavoriteCandidates = async () => {
  const { data } = await api.GET(`/user/me/saved/candidate`, {
    'expand[0]': UserSavedCandidatesConst.Expansions.fullCandidateInfo,
  });
  return data;
};

export const deleteFavoriteCandidate = async (candidateId: string) => {
  const { data } = await api.DELETE(`/user/me/saved/candidate/${candidateId}`);
  return data;
};
