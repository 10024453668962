import styled from 'styled-components';

export const CATPNLabelWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const CATPNIconContainer = styled.span`
  margin-left: 0.5rem;
`;

export const CATPNColoredText = styled.span`
  color: ${({ theme }) => theme?.axiomOrange} !important;
`;
