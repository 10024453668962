import { Account, Candidate, Submission } from '@axiom/validation';
import { useEffect } from 'react';

import { AccountSubmissionsLegacyApi } from '../../api/account/account-submissions-legacy';
import { CandidateApi } from '../../api/candidate/candidate';

export const usePreloadCandidates = (
  accountId: Account['id'],
  submission: Submission,
  selectedCandidateId: Candidate['id'] = null
): null => {
  useEffect(() => {
    if (submission) {
      const { positions = [], submissionId } = submission;
      positions.forEach(p =>
        (p.candidateOpportunities || []).forEach(({ candidate }) => {
          if (candidate.id !== selectedCandidateId) {
            AccountSubmissionsLegacyApi.readCandidateAccountSubmissionDetails(
              accountId,
              submissionId,
              candidate.id
            );
            CandidateApi.readCandidateGroupedExperiences(candidate.id, {
              submissionId,
            });
          }
        })
      );
    }
  }, [submission]);
  return null;
};
