import React from 'react';
import {
  Button,
  Card,
  CardSection,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  ParagraphHeader,
} from '@axiom/ui';

import StandingGroup from '../../public/svg/standing-group.svg';

export const BrowseTalentCta = () => {
  return (
    <Card name="BROWSE_TALENT_CTA_CONTAINER">
      <CardSection>
        <Layout stackableOn="mobile">
          <LayoutItem>
            <Gutter right="24px" bottom="24px">
              <StandingGroup width="117px" height="117px" />
            </Gutter>
          </LayoutItem>
          <LayoutItem>
            <ParagraphHeader>Access legal talent</ParagraphHeader>
            <Gutter vertical="16px">
              <Paragraph>
                Explore Axiom's high-performing legal professionals. Let us
                assist you in finding talent tailored to your legal needs, in
                your chosen industry or practice areas.
              </Paragraph>
            </Gutter>
            <Button
              name="BROWSE_TALENT_CTA"
              pattern="secondary"
              to="/access-legal-talent"
            >
              Browse Talent
            </Button>
          </LayoutItem>
        </Layout>
      </CardSection>
    </Card>
  );
};
