import GenericStore from '../classes/generic-store';
import { getPracticeAreas } from '../api/practiceAreas';

class PreloadedPracticeAreasStoreClass extends GenericStore {
  load() {
    return this.setState(
      getPracticeAreas().then(response => {
        return response.data.filter(data => data.type);
      })
    );
  }

  selectData(state) {
    return this.select(state)?.data || [];
  }
}

export const PreloadedPracticeAreasStore =
  new PreloadedPracticeAreasStoreClass();
