import React from 'react';
import {
  Card,
  CardHeader,
  CardSection,
  HeaderLink,
  SmallHeader,
} from '@axiom/ui';
import { Link } from 'react-router-dom';
import { Submission } from '@axiom/validation';
import { OpportunitiesConst } from '@axiom/const';

import { EngagementCarouselInfo } from './EngagementCarouselInfo';

export const EngagementCarouselCardButton = ({
  submission,
}: {
  submission: Submission;
}): JSX.Element => {
  const isUpcomingTab =
    submission.projectState === OpportunitiesConst.ProjectStates.Submitted;

  return (
    <Card name="ENGAGEMENT_CARD" stretched>
      <CardHeader>
        <HeaderLink name="OPPORTUNITY_NAME">
          <SmallHeader>
            <Link
              data-test="LINK"
              to={`./submission/${submission.submissionId}/${
                isUpcomingTab ? 'matches' : 'engagement-details'
              }`}
            >
              {submission.jobName}
            </Link>
          </SmallHeader>
        </HeaderLink>
      </CardHeader>
      <CardSection>
        <EngagementCarouselInfo
          submission={submission}
          isUpcomingTab={isUpcomingTab}
        />
      </CardSection>
    </Card>
  );
};
