import React from 'react';
import { Trans } from '@lingui/macro';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Header1,
  Header2,
  NoSubmissionsIcon,
} from '@axiom/ui';

import { AxiomTeam } from '../AxiomTeam/AxiomTeam';
import { CandidateAccountShape } from '../../models/candidate';

import { BCEmptyBenchContainer, StickySidebar } from './NoSubmissionsStyles';

export const NoSubmissions = ({ account }) => {
  return (
    <Gutter bottom="2rem">
      <Grid>
        <GridRow>
          <GridColumn smallScreenWidth={12} largeScreenWidth={8}>
            <BCEmptyBenchContainer vertical="5rem" horizontal="2rem">
              <Gutter bottom="2.5rem">
                <NoSubmissionsIcon />
              </Gutter>
              <Gutter bottom="0.5rem">
                <Header1 as="div" name="NO_SUBMISSIONS_MESSAGE">
                  <Trans>No active submissions.</Trans>
                </Header1>
              </Gutter>
              <Gutter bottom="2rem">
                <Header2 as="div" subtle>
                  <Trans>
                    To find the right Axiom talent for your legal team, access
                    our network or reach out to your Axiom team.
                  </Trans>
                </Header2>
              </Gutter>
              <Button to="/access-legal-talent/page/1">
                <Trans>Access Legal Talent</Trans>
              </Button>
            </BCEmptyBenchContainer>
          </GridColumn>
          <GridColumn largeScreenWidth={1} />
          <GridColumn smallScreenWidth={12} largeScreenWidth={3}>
            <StickySidebar>
              <AxiomTeam
                accountName={account.calculatedName}
                ownerUser={account.ownerUser}
                cxPartner={account.cxPartner}
              />
            </StickySidebar>
          </GridColumn>
        </GridRow>
      </Grid>
    </Gutter>
  );
};

NoSubmissions.defaultProps = {
  account: {},
};

NoSubmissions.propTypes = {
  account: CandidateAccountShape,
};
