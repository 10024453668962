import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';

export const HeaderLayout = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
  & > :last-child {
    flex-grow: 1;
  }

  ${MediaQueryUtil.mobileOnly(css`
    display: block;
    & > :first-child {
      margin-bottom: 12px;
    }
  `)}
`;
