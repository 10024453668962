import ApiHelper from '../../lib/api-helper';

const api = new ApiHelper('ACCOUNTS_CONTACTS');

export const getContact = async contactId => {
  const { data } = await api.GET(`/contacts/${contactId}`);
  return data;
};

export const patchContact = async (contactId, body) => {
  const { data } = await api.PATCH(`/contacts/${contactId}`, body);
  return { data };
};
