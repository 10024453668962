import styled from 'styled-components';

import {
  blockingAppCurtainZIndex,
  blockingCurtainZIndex,
} from '../../themes/stacking-context';

export const LoadingCurtainCoverWrapper = styled.div`
  position: ${props => (props.isFullscreen ? 'fixed' : 'absolute')};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.71);
  z-index: ${props =>
    props.isFullscreen ? blockingAppCurtainZIndex : blockingCurtainZIndex};

  // TEMP BOOTSTRAP YANK CARRYOVER
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    -moz-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
  }

  .text-primary {
    color: #ff5a2d !important;
  }
`;

export const LoadingCurtainSpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -12px;
  width: 100%;
  text-align: center;
`;
