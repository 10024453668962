import styled from 'styled-components';

export const TSFSearchContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  > div:first-child {
    flex: 1;

    input {
      background-color: transparent;

      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  }
`;
