import { bool, number, shape, string } from 'prop-types';

export const DegreeShape = shape({
  id: string.isRequired,
  candidateId: string,
  degree: string.isRequired,
  institution: string.isRequired,
  isPrimary: bool,
  year: number.isRequired,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});
