import qs from 'qs';

export const HubspotUtil = {
  parseURLDataUtm() {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const possibleMarketingUTMs = new Set([
      'gclid',
      'hsa_acchsa_ad',
      'hsa_cam',
      'hsa_grp',
      'hsa_kw',
      'hsa_mt',
      'hsa_net',
      'hsa_src',
      'hsa_tgt',
      'hsa_ver',
      'utm_campaign',
      'utm_medium',
      'utm_source',
      'utm_term',
    ]);
    const keys = Object.keys(query);
    const UTMObject = {};
    for (let i = 0; i < keys.length; i++) {
      if (possibleMarketingUTMs.has(keys[i])) {
        UTMObject[keys[i]] = query[keys[i]];
      }
    }
    return UTMObject;
  },
};
