import React from 'react';
import { Text } from '@axiom/ui';
import { number, string } from 'prop-types';
import size from 'lodash/size';
import { Trans } from '@lingui/macro';

export const FormElementMaxChars = ({ maxLength, value }) => (
  <Text className="below-input-text">
    <Trans>{maxLength - size(value) || 0} characters remaining</Trans>
  </Text>
);

FormElementMaxChars.defaultProps = {
  maxLength: 255,
  value: '',
};

FormElementMaxChars.propTypes = {
  maxLength: number,
  value: string,
};
