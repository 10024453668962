import styled from 'styled-components';

import { COLORS } from '../../themes/constants';

export const SubmissionCardWrapper = styled.div`
  margin-bottom: 1rem;
  overflow-wrap: break-word;
`;

export const SubmissionCardBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${props => props.theme.backgrounds.primary};
  margin-top: 1px;
  padding: 1rem;
`;

export const SubmissionCardCandidates = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SubmissionCardCandidate = styled.li`
  margin-bottom: 11px;

  // HUBSPOT OVERRIDES
  & > a {
    color: ${COLORS.axiomOrange};

    :hover {
      text-decoration: underline;
    }
  }
`;

export const SubmissionCardChevronWrapper = styled.div`
  color: ${props => props.theme.text.highlight};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const SubmissionCardHeader = styled(SubmissionCardBody)`
  padding-right: 3.75rem;
  position: relative;

  & > a:hover {
    text-decoration: none;
  }

  ${SubmissionCardChevronWrapper} {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;
