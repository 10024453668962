import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { SchemaTypes } from '@axiom/types';

import { RawCheckbox } from '../../form/Checkbox/RawCheckbox';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

import { DataGridNoResults } from './DataGridNoResults';
import { DataGridHeaderProps } from './DataGridHeader';
import { RawDataGridHeader } from './RawDataGridHeader';
import { DataGridProps, SortStructure } from './data-grid-props';
import { DataGridInfinite } from './DataGridInfinite';
import { DataGridPagination } from './DataGridPagination';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  InfiniteRowModelModule,
]);

export const DataGrid = ({
  calculateRowBadge,
  children,
  dataSource,
  displayMode,
  name,
  noResultsMessage = 'No Results Found',
  onSelectionChanged,
  onCheckboxGroupChanged,
  rowsChecked = { allRowsChecked: false, checkedRows: [] },
  relaxed,
  simple,
  suppressRowClickForColumns,
}: DataGridProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const [sorting, setSorting] = useState<SortStructure>({});

  const formatColumns = useMemo<Array<Record<string, unknown>>>(() => {
    const formattedColumns: Array<Record<string, unknown>> = React.Children.map(
      children,
      (c: React.ReactElement<DataGridHeaderProps>) => {
        const childProps = c.props;
        return {
          cellRenderer: childProps.cellRender
            ? ({ data }: Record<string, unknown>) => {
                const cellData = data;

                if (cellData) {
                  return childProps.cellRender({ data: cellData });
                }

                return (
                  <div data-test="grid-fetching-data">Fetching Data...</div>
                );
              }
            : null,
          onCellClicked: childProps.onCellClicked,
          field: childProps.name,
          headerName: childProps.displayName,
          headerComponent: RawDataGridHeader,
          headerComponentParams: {
            ...childProps,
            onSortChange: (sortChange: SortStructure) => setSorting(sortChange),
            sorting,
          },
          sortable: !!childProps.sortingOrder,
          sortingOrder: childProps.sortingOrder,
          pinned: childProps.pinned,
          lockPinned: childProps.lockPinned,
          width: childProps.width,
          flex: childProps.stretched,
        };
      }
    );

    if (onCheckboxGroupChanged) {
      formattedColumns.unshift({
        field: 'column-checkbox',
        cellRenderer: ({
          data: cellData,
          node: { rowIndex },
        }: {
          data?: Record<string, unknown>;
          node: { rowIndex: number };
        }) => {
          if (cellData) {
            const hasCheckedRow =
              rowsChecked.allRowsChecked ||
              rowsChecked.checkedRows?.some(
                storedRow => storedRow === cellData
              );

            return (
              <div className="grid-checkbox">
                <RawCheckbox
                  dataType={SchemaTypes.ZodBoolean}
                  name={`row-check-${rowIndex}`}
                  value={hasCheckedRow}
                  option
                  onChange={value => {
                    const rowData: Array<Record<string, unknown>> = [];
                    gridRef.current?.api.forEachNode(
                      node => node.data && rowData.push(node.data)
                    );

                    let newCheckedRows = Array.isArray(rowsChecked.checkedRows)
                      ? [...rowsChecked.checkedRows]
                      : [];
                    if (value) {
                      newCheckedRows.push(cellData);
                    } else {
                      newCheckedRows = newCheckedRows.filter(
                        row => row !== cellData
                      );
                    }

                    const allCheckedRows =
                      rowData.length === newCheckedRows.length;

                    onCheckboxGroupChanged({
                      allRowsChecked: allCheckedRows,
                      checkedRows: newCheckedRows,
                    });
                  }}
                />
              </div>
            );
          }

          return null;
        },
        headerComponent: () => {
          return (
            <div className="grid-checkbox">
              <RawCheckbox
                dataType={SchemaTypes.ZodBoolean}
                name="grid-header-checkbox"
                option
                value={rowsChecked.allRowsChecked}
                onChange={(value: boolean) => {
                  const rowData: Array<Record<string, unknown>> = [];
                  gridRef.current?.api.forEachNode(
                    node => node.data && rowData.push(node.data)
                  );

                  onCheckboxGroupChanged({
                    allRowsChecked: value,
                    checkedRows: value !== true ? [] : rowData,
                  });
                }}
              />
            </div>
          );
        },
        headerComponentParams: {},
        sortable: false,
        pinned: 'left',
        width: 65,
      });
    }

    if (calculateRowBadge) {
      formattedColumns.unshift({
        field: 'row-badge-colors',
        cellStyle: ({ data }: { data?: Record<string, unknown> }) => {
          // Header cell gets called, but will not have data
          if (data) {
            const bgColor = calculateRowBadge(data);
            return { padding: 0, backgroundColor: bgColor };
          }

          return { padding: 0 };
        },
        suppressSizeToFit: true,
        suppressAutoSize: true,
        resizable: false,
        minWidth: 8,
        sortable: false,
        pinned: 'left',
        width: 8,
      });
    }

    return formattedColumns;
  }, [dataSource, sorting, rowsChecked]);

  useEffect(() => {
    const sort: SortStructure = {};
    React.Children.forEach(
      children,
      (child: React.ReactElement<DataGridHeaderProps>) => {
        if (child.props.initialSort) {
          sort[child.props.name] = child.props.initialSort;
        }
      }
    );
    setSorting(sort);
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return DataGridNoResults;
  }, []);
  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => noResultsMessage,
    };
  }, [noResultsMessage]);

  const GridComponent =
    displayMode === 'infinite' ? DataGridInfinite : DataGridPagination;

  return (
    <GridComponent
      ref={gridRef}
      className={AttrsHelper.formatClassname(
        relaxed && 'relax-rows',
        simple && 'simple-rows'
      )}
      dataSource={dataSource}
      displayMode={displayMode}
      name={name}
      noResultsComponent={noRowsOverlayComponent}
      noRowsOverlayComponentParams={noRowsOverlayComponentParams}
      onSelectionChanged={
        onSelectionChanged
          ? () => {
              // get the column which was clicked
              const currentColumnName = gridRef?.current?.api
                .getFocusedCell()
                ?.column.getUserProvidedColDef().headerComponentParams.name;

              // don't fire onSelectionChanged if column is suppressed
              if (suppressRowClickForColumns?.includes(currentColumnName)) {
                return;
              }
              if (gridRef?.current) {
                onSelectionChanged(gridRef?.current.api.getSelectedRows());
              }
            }
          : null
      }
      columns={formatColumns}
      sorting={sorting}
    >
      {children}
    </GridComponent>
  );
};
