import styled from 'styled-components';

export const DBSTAMWrapper = styled.div`
  color: ${({ theme }) => theme.neutrals.neutral50};
  label {
    color: inherit;
  }
  textarea {
    width: 100%;
    height: 100%;
    padding: 10px 12px;
    font-weight: normal;
    color: ${({ theme }) => theme.neutrals.neutral50};
  }
`;

export const FlexRightWrapper = styled.div`
  padding-top: 40px;
  flex: 1 auto;
  width: auto;
  text-align: end;
`;
