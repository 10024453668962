import React, { useCallback } from 'react';
import {
  Account,
  Candidate,
  GroupedExperiences,
  PracticeArea,
  Submission,
  SubmissionCandidate,
  Taxonomy,
} from '@axiom/validation';

import { Grid } from '../../layout/Grid/Grid';
import { GridRow } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { Gutter } from '../../layout/Gutter/Gutter';
import { ClientEngagementNoTalentMessage } from '../ClientEngagementNoTalentMessage/ClientEngagementNoTalentMessage';
import { BioPdfCandidate } from '../BiosPdf/BioPdfCandidate';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';

import { SubmissionPdfEngagementDetails } from './SubmissionPdfEngagementDetails';
import { SubmissionPdfDescription } from './SubmissionPdfDescription';
import { SubmissionPdfHeader } from './SubmissionPdfHeader';

export type SubmissionPdfTypes = {
  account: Account;
  submission: Submission;
  candidates: SubmissionCandidate[];
  allGroupedExperiences: GroupedExperiences[][];
  practiceAreas: PracticeArea[];
  talentFinderUrl: string;
  clientUrl: string;
  loginUrl: string;
  taxonomy: Taxonomy;
};

export const SubmissionPdf = ({
  account,
  submission,
  candidates,
  allGroupedExperiences,
  practiceAreas,
  talentFinderUrl,
  clientUrl,
  loginUrl,
  taxonomy,
}: SubmissionPdfTypes) => {
  const getExperiences = useCallback(
    (id: Candidate['id']): GroupedExperiences[] => {
      return (
        allGroupedExperiences.find(ge => ge?.[0]?.candidateId === id) || []
      );
    },
    [allGroupedExperiences, candidates]
  );

  return (
    <div className="k-pdf-export">
      <Layout background="panelLight" name="SUBMISSION_PDF">
        <LayoutItem>
          <Grid>
            <GridRow gutterBottom="24px">
              <GridColumn>
                <SubmissionPdfHeader
                  submission={submission}
                  clientUrl={clientUrl}
                />
              </GridColumn>
            </GridRow>
            <GridRow gutterBottom="32px">
              <GridColumn>
                <SubmissionPdfDescription loginUrl={loginUrl} />
              </GridColumn>
            </GridRow>
            <GridRow gutterBottom="32px">
              <GridColumn>
                <SubmissionPdfEngagementDetails
                  submission={submission}
                  practiceAreas={practiceAreas}
                />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                {candidates.length > 0 ? (
                  candidates.map(candidate => (
                    <Gutter bottom="32px" key={candidate.id}>
                      <BioPdfCandidate
                        candidate={candidate}
                        groupedExperiences={getExperiences(candidate.id)}
                        submission={submission}
                        accountId={account.id}
                        practiceAreas={practiceAreas}
                        clientUrl={clientUrl}
                        taxonomy={taxonomy}
                        removeLogo
                      />
                    </Gutter>
                  ))
                ) : (
                  <Gutter horizontal="24px" bottom="32px">
                    <ClientEngagementNoTalentMessage
                      talentFinderUrl={talentFinderUrl}
                    />
                  </Gutter>
                )}
              </GridColumn>
            </GridRow>
          </Grid>
        </LayoutItem>
      </Layout>
    </div>
  );
};
