/*
Takes any string value and converts it to all uppercase Alphanumeric and underscores
anything else a default value is returned.
*/
export const formatDataTestId = (dataTestValue, spacer = false) => {
  const defaultValue = 'DataTestIdGoesHere';
  const validTypes = { string: true, number: true };
  if (!validTypes[typeof dataTestValue]) return defaultValue;

  const reg = /[^\w-]+/g;
  const simpleStrings = dataTestValue
    .toString()
    .trim()
    .toUpperCase()
    .replace(reg, spacer ? '_' : '');

  return simpleStrings || defaultValue;
};
