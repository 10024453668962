import React from 'react';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
} from '@axiom/ui';
import { AfcSubmission, Account } from '@axiom/validation';

import { OpportunityApi } from '../../api/opportunity/opportunity';
import { AccountSubmissionsApi } from '../../api/account/account-submissions';

export const ClientEngagementsRemoveContactModal = ({
  accountId,
  contactId,
  name,
  opportunityId,
  submissionId,
  onClose,
}: {
  accountId: Account['id'];
  contactId: AfcSubmission['contacts'][0]['id'];
  name: AfcSubmission['contacts'][0]['fullName'];
  opportunityId: AfcSubmission['id'];
  submissionId: AfcSubmission['submissionId'];
  onClose: () => void;
}) => {
  return (
    <Modal size="confirm" name="DELETE_CONTACT_OPP_MODAL">
      <ModalHeader onClose={onClose} name="DELETE_CONTACT_MODAL_HEADER">
        Remove Access
      </ModalHeader>
      <ModalSection name="DELETE_CONTACT_CONTENT">
        Please confirm that you want to remove {name} from having access to this
        engagement.
      </ModalSection>
      <ModalFooter>
        <Button
          name="DELETE_CONTACT_CANCEL"
          variation="outline"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          name="DELETE_CONTACT_SUBMIT"
          onClick={async () => {
            await OpportunityApi.deleteContactOpp(opportunityId, contactId);
            await AccountSubmissionsApi.refreshAccountSubmissionDetails(
              accountId,
              submissionId,
              { ctaFilters: ['None', 'Hire'] }
            );
            onClose();
          }}
        >
          Yes, Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
};
