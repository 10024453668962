import { func, node, oneOfType, shape, string } from 'prop-types';

export const ComponentShape = oneOfType([string, node, func]);

export const I18nShape = shape({
  _: func.isRequired,
});

export const RFFormShape = shape({
  change: func.isRequired,
  batch: func.isRequired,
});
