import React from 'react';
import { string } from 'prop-types';
import { WarningIcon } from '@axiom/ui';

import { SectionBodyItemBodyRow } from '../../themes/components';
import { PALETTE } from '../../themes/constants';

export const ErrorBlurb = ({ errorMessage }) => (
  <SectionBodyItemBodyRow className="text-align-center">
    <WarningIcon
      backgroundColor={PALETTE.colors.crimson}
      textColor={PALETTE.grays.white}
    />
    <span>{errorMessage || 'An error occurred. Please refresh.'}</span>{' '}
  </SectionBodyItemBodyRow>
);

ErrorBlurb.propTypes = {
  errorMessage: string,
};

ErrorBlurb.defaultProps = {
  errorMessage: null,
};
