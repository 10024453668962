import { shape, string } from 'prop-types';

export const TagShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  parentId: string,
  type: string.isRequired,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});
