import { Api } from '@axiom/ui';
import { User } from '@axiom/validation';
import { UserConst } from '@axiom/const';

import { EnvUtil } from '../../utils/env-util';
import ApiHelper from '../../lib/api-helper';

const api = new ApiHelper('USERS');

export const getLoggedUser = async (queryParams = {}) => {
  const { data } = await api.GET('/user/me', queryParams);

  return data;
};

class UserApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readUser() {
    return super.read<{ data: User }>({
      endpoint: `/user/me`,
      method: 'GET',
      encodeQuery: false,
      query: {
        expand: [UserConst.Expansions.contactAccountInfo],
      },
    });
  }
}

export const UserApi = new UserApiClass();
