import { bool, func } from 'prop-types';
import React from 'react';
import { ChevronIcon, DataTest, DataTestUtil, Text } from '@axiom/ui';
import { Trans } from '@lingui/macro';

import { AccountSubmissionsStore } from '../../stores/account-submissions-store';
import { DateUtil } from '../../utils/date-util';

import { SubmissionCardDetail } from './SubmissionCardDetail';
import {
  SubmissionCardChevronWrapper,
  SubmissionCardWrapper,
  SubmissionCardHeader,
} from './SubmissionCardStyles';

export const SubmissionCard = ({
  download,
  expanded,
  setCurrentSubmissionId,
  accountSubmission,
}) => {
  const jobName = (
    <DataTest inline value="SUBMISSION_NAME">
      {accountSubmission.jobName}
    </DataTest>
  );

  const submissionLastUpdatedAt = (
    <time
      data-test={DataTestUtil.format('SUBMISSION_DATE')}
      dateTime={accountSubmission.submissionLastUpdatedAt}
    >
      {DateUtil.displayShortDate(accountSubmission.submissionLastUpdatedAt)}
    </time>
  );

  const content = (
    <>
      <Text bold size="large">
        <Trans>{jobName} Submission</Trans>
      </Text>

      <Text subtle>
        <Trans>Submitted on {submissionLastUpdatedAt}</Trans>
      </Text>

      <SubmissionCardChevronWrapper>
        <ChevronIcon
          direction={expanded ? 'down' : 'right'}
          height={20}
          width={20}
        />
      </SubmissionCardChevronWrapper>
    </>
  );

  return (
    <SubmissionCardWrapper
      key={accountSubmission.submissionId}
      data-test={DataTestUtil.format('SUBMISSION_CARD')}
    >
      <SubmissionCardHeader>
        <div
          data-test={DataTestUtil.format('OPEN_IN_TAB_LINK')}
          onClick={() => setCurrentSubmissionId(accountSubmission.submissionId)}
        >
          {content}
        </div>
      </SubmissionCardHeader>

      {expanded && <SubmissionCardDetail download={download} />}
    </SubmissionCardWrapper>
  );
};

SubmissionCard.defaultProps = {
  download: null,
  expanded: false,
};

SubmissionCard.propTypes = {
  download: func,
  expanded: bool,
  accountSubmission: AccountSubmissionsStore.getSingleDataShape().isRequired,
  setCurrentSubmissionId: func.isRequired,
};
