import React from 'react';
import {
  CondensedHeader,
  CondensedLarge,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Visible,
} from '@axiom/ui';
import memoize from 'lodash/memoize';
import { Submission } from '@axiom/validation';

import { DateUtil } from '../../utils/date-util';

export const ClientEngagementsEngagementStatus = ({
  submission,
  isUpcomingTab,
}: {
  submission: Submission;
  isUpcomingTab?: boolean;
}): JSX.Element => {
  const formatDate = memoize((date: string) => DateUtil.displayDate(date));
  const createdAtDisplay = submission.createdAt
    ? formatDate(submission.createdAt)
    : '--';
  const startAtDisplay = submission.startDate
    ? formatDate(submission.startDate)
    : '--';
  const endAtDisplay = submission.endDate
    ? formatDate(submission.endDate)
    : '--';

  return (
    <Grid stackable name="DATA_ROW">
      <GridRow columns={3}>
        <GridColumn largeScreenWidth={6}>
          <CondensedHeader name="CONTACT_NAME_LABEL">
            Primary Contact
          </CondensedHeader>
          <CondensedLarge name="CONTACT_NAME_VALUE">
            {submission.clientName ?? '--'}
          </CondensedLarge>

          <Visible only="mobile">
            <Gutter bottom="8px" />
          </Visible>
        </GridColumn>
        <GridColumn largeScreenWidth={3}>
          <CondensedHeader name="SUBMITTED_DATE_LABEL">
            {isUpcomingTab ? 'Engagement Submitted' : 'Start Date'}
          </CondensedHeader>
          <CondensedLarge name="SUBMITTED_DATE_VALUE">
            {isUpcomingTab ? createdAtDisplay : startAtDisplay}
          </CondensedLarge>

          <Visible only="mobile">
            <Gutter bottom="8px" />
          </Visible>
        </GridColumn>
        <GridColumn largeScreenWidth={3}>
          <CondensedHeader name="START_DATE_LABEL" as="span" maxLines={1}>
            {isUpcomingTab ? 'Target Start Date' : 'End Date'}
          </CondensedHeader>
          <CondensedLarge name="START_DATE_VALUE">
            {isUpcomingTab ? startAtDisplay : endAtDisplay}
          </CondensedLarge>

          <Visible only="mobile">
            <Gutter bottom="8px" />
          </Visible>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
