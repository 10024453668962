import React from 'react';
import { Grid, GridColumn, GridRow, Gutter } from '@axiom/ui';

import { LoadingBar } from '../LoadingBar/LoadingBar';
import { LoadingSquare } from '../LoadingSquare/LoadingSquare';

import {
  GhostCard,
  GhostHeader,
} from './AccessLegalTalentDetailsViewLoadingStyles';

export const AccessLegalTalentDetailsViewLoading = () => {
  return (
    <Grid stackable stretched>
      <GridRow>
        <GridColumn smallScreenWidth={12} largeScreenWidth={9}>
          <GhostCard>
            <Grid>
              <GridRow>
                <GridColumn width={12}>
                  <GhostHeader>
                    <LoadingSquare width="75px" />
                    <LoadingBar size="header" width="25%" />
                  </GhostHeader>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <LoadingBar size="text" width="99%" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <LoadingBar size="text" width="94%" />
                </GridColumn>
              </GridRow>
            </Grid>
          </GhostCard>
          <GhostCard>
            <Grid>
              <GridRow>
                <GridColumn>
                  <Gutter bottom="1rem">
                    <LoadingBar size="label" />
                  </Gutter>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <LoadingBar size="text" width="98%" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <LoadingBar size="text" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <LoadingBar size="text" width="93%" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <LoadingBar size="text" width="47%" />
                </GridColumn>
              </GridRow>
            </Grid>
          </GhostCard>
          <GhostCard>
            <Grid>
              <GridRow>
                <GridColumn>
                  <Gutter bottom="1rem">
                    <LoadingBar size="label" />
                  </Gutter>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={3}>
                  <Gutter bottom="0.5rem">
                    <LoadingBar size="text" />
                  </Gutter>
                </GridColumn>
                <GridColumn width={9}>
                  <Gutter bottom="0.5rem">
                    <LoadingBar size="text" />
                  </Gutter>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={3}>
                  <Gutter bottom="0.5rem">
                    <LoadingBar size="text" />
                  </Gutter>
                </GridColumn>
                <GridColumn width={9}>
                  <Gutter bottom="0.5rem">
                    <LoadingBar size="text" />
                  </Gutter>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={3}>
                  <Gutter bottom="0.5rem">
                    <LoadingBar size="text" />
                  </Gutter>
                </GridColumn>
                <GridColumn width={9}>
                  <Gutter bottom="0.5rem">
                    <LoadingBar size="text" />
                  </Gutter>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={3}>
                  <LoadingBar size="text" />
                </GridColumn>
                <GridColumn width={9}>
                  <LoadingBar size="text" />
                </GridColumn>
              </GridRow>
            </Grid>
          </GhostCard>
        </GridColumn>
        <GridColumn smallScreenWidth={12} largeScreenWidth={3}>
          <Grid>
            <GridRow>
              <GridColumn width={12}>
                <GhostHeader>
                  <LoadingSquare width="56px" />
                  <LoadingBar size="text" />
                </GhostHeader>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={12}>
                <GhostHeader>
                  <LoadingSquare width="56px" />
                  <LoadingBar size="text" />
                </GhostHeader>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={12}>
                <GhostHeader>
                  <LoadingSquare width="56px" />
                  <LoadingBar size="text" />
                </GhostHeader>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={12}>
                <GhostHeader>
                  <LoadingSquare width="56px" />
                  <LoadingBar size="text" />
                </GhostHeader>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={12}>
                <GhostHeader>
                  <LoadingSquare width="56px" />
                  <LoadingBar size="text" />
                </GhostHeader>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={12}>
                <LoadingBar size="text" />
                <LoadingBar size="text" />
                <LoadingBar size="text" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
