import debounce from 'lodash/debounce';

import { CookieUtil } from './cookie-util';

let logger = [];

const exclusionMsgTerms = ['ttl_dom_ready'];
const exclusionUrlTerms = ['_hcms'];

/**
 * loggly-jslogger references the window object unabashed, which is fine in
 * browser envs, but when you get into node specific envs that has no window,
 * the compiler errors.
 *
 * This is what was happening with hydra-seed, though as to how it's refing
 * @axiom/ui indirectly is beyond me
 */
try {
  // eslint-disable-next-line global-require
  const { LogglyTracker } = require('loggly-jslogger');
  logger = new LogglyTracker();
  // eslint-disable-next-line no-empty
} catch (e) {}

export const LoggerUtil = {
  appId: null,
  clientId: null,
  env: null,
  user: null,
  _fireError: debounce(error => {
    try {
      /**
       * In case we for some reason get a god-awfully large error
       */
      const errorStr = JSON.stringify(error);
      if (errorStr.length > 8000) {
        error = `${errorStr.substr(0, 8000)}...`;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
    logger.push({
      app: window?.navigator?.appVersion,
      href: window?.location?.href,
      syslog: {
        severity: 'Error',
        appName: `${LoggerUtil.appId}-${LoggerUtil.env}`,
      },
      user: LoggerUtil.user || null,
      error,
    });
  }, 500),
  initialize(clientId, appId, env) {
    LoggerUtil.clientId = clientId;
    LoggerUtil.appId = appId;
    LoggerUtil.env = env;
    LoggerUtil.user = CookieUtil.decodeUserCookie();

    if (LoggerUtil.clientId) {
      // eslint-disable-next-line no-console
      console.log('Initializing Error Logging');
      this.overrideError();
      logger.push({
        logglyKey: LoggerUtil.clientId,
        tag: `rnd-ui,${appId},${appId}-${env},rnd-ui-${env}`,
      });
    }
  },
  overrideError() {
    /**
     * This is what Loggly does if you set sendConsoleErrors
     */
    const _onerror = window.onerror;
    window.onerror = function (msg, url, line, col, err) {
      /**
       * Make sure we have a proper error, that the error comes from the app origin (rather than 3rd party like google)
       * and that it doesn't have certain terms that we can't do anything about because again 3rd party.
       */
      if (
        msg &&
        url.startsWith(window.location.origin) &&
        !exclusionMsgTerms.some(term => msg.includes(term)) &&
        !exclusionUrlTerms.some(term => url.includes(term))
      ) {
        LoggerUtil._fireError({
          category: 'BrowserJsException',
          exception: {
            message: msg,
            url,
            lineno: line,
            colno: col,
            stack: err ? err.stack : 'n/a',
          },
        });
      }

      if (_onerror && typeof _onerror === 'function') {
        // eslint-disable-next-line prefer-rest-params
        _onerror.apply(window, arguments);
      }
    };
  },
  fireError(error) {
    try {
      error = `${error.toString()}\n${error.stack}`;
      // eslint-disable-next-line no-empty
    } catch (e) {}
    if (LoggerUtil.clientId) {
      LoggerUtil._fireError(error);
    } else {
      // Good Logging
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
};
