import { arrayOf, number, shape, string, oneOfType } from 'prop-types';

export const candidateModel = {
  addressCountry: string,
  barredLocations: arrayOf(shape({ id: string, name: string })),
  calculatedDisplayName: string,
  calculatedFirstName: string,
  calculatedLastName: string,
  candidateSummary: string,
  id: string,
  practiceArea: oneOfType([
    shape({ id: string, name: string }), // TODO: practiceArea is currently an object in the API. It will eventually be switched over to an array of objects.
    arrayOf(shape({ id: string, name: string })),
  ]),
  profileImageKey: string,
  profileImageName: string,
  publicCandidateSummary: string,
  yearsOfExperience: number,
};

export const searchResultsShape = shape(candidateModel);
