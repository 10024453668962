import React from 'react';
import { Grid, GridRow, GridColumn, LargeHeader } from '@axiom/ui';

import { EngagementsCarousel } from '../EngagementsCarousel/EngagementsCarousel';
import { DiscoverTalentCarousel } from '../DiscoverTalentCarousel/DiscoverTalentCarousel';
import { BrowseTalent } from '../BrowseTalent/BrowseTalent';

export const MyAxiomDashboard = () => {
  return (
    <Grid name="DASHBOARD">
      <GridRow gutterBottom="24px">
        <GridColumn>
          <EngagementsCarousel />
        </GridColumn>
      </GridRow>

      <GridRow gutterBottom="24px">
        <GridColumn>
          <DiscoverTalentCarousel />
        </GridColumn>
      </GridRow>

      <GridRow gutterBottom="24px">
        <GridColumn>
          <LargeHeader>Saved Talent Section</LargeHeader>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh ipsum
          consequat nisl vel pretium lectus quam id. Elementum eu facilisis sed
          odio morbi quis commodo odio aenean. Suspendisse interdum consectetur
          libero id faucibus. Cras ornare arcu dui vivamus arcu felis bibendum
          ut. Diam vulputate ut pharetra sit amet aliquam. Et sollicitudin ac
          orci phasellus egestas tellus rutrum. Dui vivamus arcu felis bibendum
          ut. Sed risus pretium quam vulputate dignissim suspendisse in est.
          Odio morbi quis commodo odio aenean sed adipiscing diam donec. Vitae
          congue mauris rhoncus aenean vel elit scelerisque. Ipsum consequat
          nisl vel pretium lectus quam id leo. Aliquam purus sit amet luctus
          venenatis lectus. Neque aliquam vestibulum morbi blandit cursus risus
          at ultrices. Vitae ultricies leo integer malesuada nunc vel risus.
          Turpis tincidunt id aliquet risus feugiat in. Et ultrices neque ornare
          aenean euismod elementum nisi quis. Quis ipsum s
        </GridColumn>
      </GridRow>

      <BrowseTalent />
    </Grid>
  );
};
