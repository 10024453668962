import React from 'react';
import { connect } from 'react-redux';

import { PreloadedUserStore } from '../../stores/preloaded-user-store';

import { SharedHeaderMenus } from './SharedHeaderMenus';

export const SharedHeaderComponent = ({ user }) => {
  return <SharedHeaderMenus user={user} />;
};

SharedHeaderComponent.defaultProps = {
  user: null,
};

SharedHeaderComponent.propTypes = {
  user: PreloadedUserStore.getDataShape(),
};

export const SharedHeader = connect(state => ({
  user: PreloadedUserStore.selectData(state),
}))(SharedHeaderComponent);
