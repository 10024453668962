import APIHelper from '../lib/api-helper';

const api = new APIHelper('barred location');

export const getBarredLocations = async () => {
  const { data, _meta } = await api.GET('/barredLocations');
  return {
    data,
    meta: _meta,
  };
};

export default {
  getBarredLocations,
};
