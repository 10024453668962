import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, HeaderBarLinkSection, HeaderBarUserSection } from '@axiom/ui';

import { SharedHeader } from '../../components/SharedHeader/SharedHeader';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { QueryParamUtil } from '../../utils/query-param-util';
import { isClientUser } from '../../utils/roles';
import { EnvUtil } from '../../utils/env-util';

import { AuthenticatedHeaderMenuItem } from './AuthenticatedHeaderMenuItem';

const AuthenticatedHeaderMenuComponent = ({ user }) => {
  const contact = user?.contact;

  const { accountId: pathParamAccountId } = useParams();
  const { accountId: queryParamAccountId } = QueryParamUtil.getQueryParams();
  const accountId = pathParamAccountId ?? queryParamAccountId;
  const account = (() => {
    let acc;

    if (!contact?.accounts?.length) {
      return null;
    }

    if (accountId) {
      acc = contact.accounts.find(a => a.id === accountId) || null;
    } else {
      [acc] = contact.accounts;
    }

    return acc;
  })();

  return (
    <>
      <HeaderBarLinkSection>
        {isClientUser(user) && (
          <AuthenticatedHeaderMenuItem
            name="NAVBARPROFILE"
            url={`/projects/account/${account?.id}/dashboard`}
          >
            My Engagements
          </AuthenticatedHeaderMenuItem>
        )}
        {isClientUser(user) && account && (
          <AuthenticatedHeaderMenuItem
            name="NAVBARBENCH"
            url={`/bench?account=${account?.id}`}
          >
            My Bench
          </AuthenticatedHeaderMenuItem>
        )}
        <AuthenticatedHeaderMenuItem
          name="NAVBARBENCH"
          url="/access-legal-talent"
        >
          Browse Talent
        </AuthenticatedHeaderMenuItem>
        <Button pattern="brand" toTab={EnvUtil.hubspotTalentFinder}>
          New Engagement
        </Button>
      </HeaderBarLinkSection>
      <HeaderBarUserSection>
        <SharedHeader />
      </HeaderBarUserSection>
    </>
  );
};

AuthenticatedHeaderMenuComponent.defaultProps = {
  user: null,
};

AuthenticatedHeaderMenuComponent.propTypes = {
  user: PreloadedUserStore.getDataShape(),
};

const mapStateToProps = state => ({
  user: PreloadedUserStore.selectData(state),
});

export const AuthenticatedHeaderMenu = connect(mapStateToProps)(
  AuthenticatedHeaderMenuComponent
);
