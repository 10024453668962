import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { node } from 'prop-types';

import StoreStateTreatment from '../components/StoreStateTreatment/StoreStateTreatment';
import { PreloadedPracticeAreasStore } from '../stores/preloaded-practice-areas-store';
import { PreloadedSearchFiltersStore } from '../stores/preloaded-search-filters-store';

const AccessLegalTalentProviderComponent = ({
  children,
  practiceAreasState,
  filtersState,
}) => {
  useEffect(() => {
    PreloadedPracticeAreasStore.load();
    PreloadedSearchFiltersStore.load();
  }, []);

  return (
    <StoreStateTreatment
      storeState={[practiceAreasState, filtersState]}
      renderLoadedView={() => children}
    />
  );
};

AccessLegalTalentProviderComponent.propTypes = {
  practiceAreasState: PreloadedPracticeAreasStore.getStateShape().isRequired,
  filtersState: PreloadedSearchFiltersStore.getStateShape().isRequired,
  children: node.isRequired,
};

export const AccessLegalTalentProvider = connect(state => ({
  practiceAreasState: PreloadedPracticeAreasStore.select(state),
  filtersState: PreloadedSearchFiltersStore.select(state),
}))(AccessLegalTalentProviderComponent);
