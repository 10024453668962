import React from 'react';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

export type InterviewConfirmLegacyModalType = {
  candidate: Candidate;
  onClose: (arg0: boolean) => void;
  onConfirm: () => void;
};

export const ClientEngagementsTalentInterviewConfirmModalLegacy = ({
  candidate,
  onClose,
  onConfirm,
}: InterviewConfirmLegacyModalType) => {
  return (
    <Modal size="confirm" name="INTERVIEW_PROMPT">
      <ModalHeader name="PROMPT_HEADER" onClose={() => onClose(false)}>
        Confirm your interview request
      </ModalHeader>
      <ModalSection>
        <Paragraph name="PROMPT_BODY_COPY">
          Confirming your interview request lets Axiom know you'd like to speak
          with {candidate.calculatedFirstName} about your project. We will reach
          out within 2 business days to arrange this interview.
        </Paragraph>
      </ModalSection>
      <ModalFooter stackableOn="mobile">
        <Button
          name="CANCEL"
          variation="outline"
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
        <Button name="CONFIRM" onClick={onConfirm}>
          Confirm Interview
        </Button>
      </ModalFooter>
    </Modal>
  );
};
