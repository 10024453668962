import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';

import { PALETTE } from '../../themes/constants';
import { Label } from '../FormElements/Label/Label';
import { Input } from '../FormElements/Input/Input';

export const IWAMLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${PALETTE.grays.gray5};
  padding-bottom: 0.25rem;
`;

export const IWAMLabel = styled(Label)`
  color: ${props => (props.muted ? PALETTE.theme.textMuted : 'inherit')};
  display: flex;
  align-items: center;
`;

export const IWAMColoredText = styled.span`
  color: ${PALETTE.theme.primary} !important;
`;

export const IWAMCustomError = styled.div`
  color: ${PALETTE.theme.danger};
  font-size: 0.875rem;
  font-weight: bold;
`;

export const IWAMPInputWrapper = styled(Input)`
  input {
    min-height: 2rem;
  }
  .form-control .disabled {
    background-color: ${PALETTE.grays.gray1};
    color: ${PALETTE.grays.gray5};
  }
`;

export const IWAMPRadioWrapper = styled.div`
  padding-bottom: 1.25rem;
`;

export const IWAMPRadioLabelWrapper = styled.div`
  padding-bottom: 1.25rem;

  ${MediaQueryUtil.mobileOnly(css`
    padding-bottom: 0;
  `)}
`;

export const IWAMRequiredNote = styled.div`
  float: right;
`;
