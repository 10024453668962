import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';
import { get } from 'lodash';
import { FORM_ERROR } from 'final-form';

import { DateUtil } from './date-util';

const ajv = new Ajv({
  allErrors: true,
  coerceTypes: 'string',
  format: 'full',
  jsonPointers: true,
});
AjvErrors(ajv);

ajv.addKeyword('lessThanEqualDate', {
  type: 'string',
  errors: true,
  validate: function validateLessThanEqualDate(
    maxDatePropNameAgainst,
    dateStr,
    parentSchema,
    currentDataPath,
    parentDataObject,
    parentPropName,
    rootData
  ) {
    const maxDateStr = get(rootData, maxDatePropNameAgainst);

    validateLessThanEqualDate.errors = [
      {
        keyword: 'lessThanEqualDateRequirement',
        message: `This must be earlier than or equal to end date`,
        params: { keyword: 'lessThanEqualDateRequirement' },
      },
    ];

    if (!maxDateStr || !dateStr) {
      return true;
    }

    return DateUtil.castAsMoment(maxDateStr)
      .startOf('day')
      .isSameOrAfter(DateUtil.castAsMoment(dateStr).startOf('day'));
  },
});

ajv.addKeyword('minDate', {
  type: 'string',
  errors: true,
  validate: function validateGreaterThanEqualDate(minDateStr, dateStr) {
    validateGreaterThanEqualDate.errors = [
      {
        keyword: 'greaterThanEqualDateRequirement',
        message: `Must be equal to or after ${DateUtil.displayShortDate(
          minDateStr
        )}`,
        params: { keyword: 'greaterThanEqualDateRequirement' },
      },
    ];

    if (!minDateStr || !dateStr) {
      return true;
    }

    return DateUtil.castAsMoment(minDateStr)
      .startOf('day')
      .isSameOrBefore(DateUtil.castAsMoment(dateStr).startOf('day'));
  },
});

/**
 * Here we check for digit count and non-approved-special characters
 * That was good enough for now (per TPM)
 * This isn't 100% for every pattern since we don't have that kinda time.
 * */
ajv.addKeyword('basicPhoneNumber', {
  type: 'string',
  errors: true,
  validate: function validateBasicPhoneNumber(_, value) {
    validateBasicPhoneNumber.errors = [
      {
        keyword: 'basicPhoneNumberRequirement',
        message:
          'Phone numbers must be 7-21 characters and can include only numbers, spaces, and these special characters: +, -, (, ), x.',
        params: { keyword: 'basicPhoneNumberRequirement' },
      },
    ];

    if (!value || value.length < 7 || value.length > 21) {
      return false;
    }

    const approveSpecialCharacters = new Set([' ', '(', ')', '-', '+', 'x']);
    const numBits = value.split('');

    const { numberCount, characters } = numBits.reduce(
      (crt, bit) => {
        if (parseInt(bit, 10) >= 0) {
          crt.numberCount++;
          return crt;
        }

        crt.characters.push(bit);
        return crt;
      },
      { numberCount: 0, characters: [] }
    );

    let isGood = numberCount >= 7 && numberCount <= 17;
    if (isGood && characters.length) {
      isGood = characters.every(character =>
        approveSpecialCharacters.has(character.toLowerCase())
      );
    }

    return isGood;
  },
});

ajv.addKeyword('basicCurrency', {
  type: 'string',
  errors: true,
  validate: function validateBasicCurrency(_, value) {
    validateBasicCurrency.errors = [
      {
        keyword: 'basicCurrencyRequirement',
        message: `Rates can contain only numbers, commas, and periods.`,
        params: { keyword: 'basicCurrencyRequirement' },
      },
    ];

    const bits = value.split(/[.,]/);

    const invalid = bits.some((bit, index, ary) => {
      let isInvalid = /(.*\D.*)/.test(bit);

      if (!isInvalid) {
        if (ary.length > 2) {
          if (index === 0) {
            isInvalid = bit.length < 1 || bit.length > 3;
          } else if (index === ary.length - 1) {
            isInvalid = bit.length !== 2;
          } else {
            isInvalid = bit.length !== 3;
          }
        } else if (ary.length > 1) {
          if (index === 1) {
            isInvalid = bit.length < 1;
          } else if (index === ary.length - 1) {
            isInvalid = bit.length !== 2;
          }
        }
      }

      return isInvalid;
    });

    return !invalid;
  },
});

const translateMessages = error => {
  const { message } = error;
  switch (true) {
    case message === 'should NOT be shorter than 1 characters':
      return 'Required';
    default:
      return message;
  }
};

export const AjvUtil = {
  compileSchema(ajvSchema) {
    return ajv.compile(ajvSchema);
  },
  /**
   * Need to deal with following error messages with array items
   *
   *   // let vObj = {
    //   name: "Required",
    //   testers: [ ce
    //     undefined,
    //     {
    //       name: 'Jip'
    //     }
    //   ],
    // };
   // return vObj;
   */
  formatValidationForFinalForm: (compiledAjvSchema, data) => {
    /**
     * React Final Form is expecting undefined if form is valid
     */
    if (!compiledAjvSchema || compiledAjvSchema(data)) {
      return undefined;
    }

    return AjvUtil.formatValidationErrorsForFinalForm(compiledAjvSchema.errors);
  },
  formatValidationErrorsForFinalForm: errors =>
    errors.reduce((returnObj, error) => {
      let dataPath = error.dataPath.replace(/^[./]/, '');
      if (error.keyword === 'required') {
        const path =
          dataPath.length > 0
            ? `${dataPath}.${error.params.missingProperty}`
            : error.params.missingProperty;
        returnObj[path] = 'Required';
      } else {
        if (dataPath.length === 0) {
          dataPath = FORM_ERROR;
        }
        returnObj[dataPath] = translateMessages(error);
      }
      return returnObj;
    }, {}),
};
