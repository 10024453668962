import React from 'react';
import { Modal } from 'semantic-ui-react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { IconButton } from '../Button/IconButton';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';

export const ModalHeaderBackgrounds = {
  light: 'light',
  dark: 'dark',
  blue: 'blue',
} as const;

export type ModalHeaderBackgrounds =
  typeof ModalHeaderBackgrounds[keyof typeof ModalHeaderBackgrounds];

export interface ModalHeaderProps {
  background?: ModalHeaderBackgrounds;
  children?: React.ReactNode;
  onClose: () => void;
  name?: string;
  className?: string;
}

export const ModalHeader = ({
  background = 'light',
  children,
  name,
  className,
  onClose,
}: ModalHeaderProps) => {
  const displayChildren = [...React.Children.toArray(children)];
  return (
    <Modal.Header
      data-test={name || 'MODAL_HEADER'}
      name={name}
      className={AttrsHelper.formatClassname(
        `background-${background}`,
        className
      )}
    >
      <SmallHeader className="modal-header-text">{displayChildren}</SmallHeader>
      <IconButton
        name={name ? `${name}-close-button` : 'close-modal-button'}
        pattern="secondary"
        variation="minimal"
        icon="x-mark"
        aria-label={
          typeof children === 'string'
            ? `Close ${children} modal`
            : 'Close modal'
        }
        className={`${name}-close-button gtm-${name}-close-modal close-button fixed-dimension`}
        onClick={onClose}
        type="button"
        inverse={background !== 'light'}
      />
    </Modal.Header>
  );
};
