import React from 'react';
import { Field } from 'react-final-form';
import { func, string, shape } from 'prop-types';
import { Header5, Gutter, Text } from '@axiom/ui';

import { RadioButton } from '../FormElements/RadioButton/RadioButton';

import {
  CardRadioWrapper,
  CardRadioTextWrapper,
  CardRadioRadioButtonWrapper,
} from './CardRadioStyles';

export const CardRadio = ({ domID, input, inputOnChange, label, subtext }) => {
  return (
    <CardRadioWrapper
      highlighted={input.checked}
      onClick={e => {
        e.stopPropagation();
        input.onChange(input.value);
        inputOnChange(input.value);
      }}
    >
      <CardRadioTextWrapper>
        <Gutter bottom="13px">
          <Header5>{label}</Header5>
        </Gutter>
        <Text subtle>{subtext}</Text>
      </CardRadioTextWrapper>
      <CardRadioRadioButtonWrapper>
        <RadioButton
          input={input}
          domID={domID}
          inputOnChange={inputOnChange}
        />
      </CardRadioRadioButtonWrapper>
    </CardRadioWrapper>
  );
};

CardRadio.propTypes = {
  domID: string.isRequired,
  input: shape({}).isRequired,
  inputOnChange: func,
  label: string.isRequired,
  subtext: string.isRequired,
};

CardRadio.defaultProps = {
  inputOnChange: () => {},
};

export const CardRadioField = ({
  inputOnChange,
  label,
  name,
  subtext,
  value,
}) => {
  return (
    <Field
      name={name}
      label={label}
      component={CardRadio}
      domID={`${name}_${value}`}
      type="radio"
      inputOnChange={inputOnChange}
      value={value}
      subtext={subtext}
    />
  );
};

CardRadioField.propTypes = {
  inputOnChange: func,
  label: string.isRequired,
  name: string.isRequired,
  subtext: string.isRequired,
  value: string.isRequired,
};

CardRadioField.defaultProps = {
  inputOnChange: () => {},
};
