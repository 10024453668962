import { arrayOf, number, shape, string } from 'prop-types';

import {
  SavedFilterDefault,
  SavedFilterShapeStructure,
} from '../models/saved-filters';
import { WindowUtil } from '../utils/window-util';

import GenericStore from './generic-store';

export default class AbstractTableStore extends GenericStore {
  constructor({ filterShape, dataShape }) {
    super();

    this.filterShape = filterShape;
    this.dataShape = dataShape;

    /**
     * DO NOT REPLICATE THIS PATTERN
     * There is a wonky race condition this is a work around for
     * Thanks Redux!
     * */
    this.dataRequestCount = {
      called: 0,
      received: 0,
    };
  }

  // eslint-disable-next-line no-unused-vars
  formatMetaForRequest(meta) {
    throw new Error('Must override this function');
  }

  // eslint-disable-next-line no-unused-vars
  getResults(sendMeta) {
    throw new Error('Must override this function');
  }

  load(
    storeData = {
      meta: { searchConfig: SavedFilterDefault(), serverMeta: {} },
      results: [],
    },
    loadData
  ) {
    /**
     * We only want to do filters because name/id is in searchConfig and that'll mess up the route
     */
    WindowUtil.setUrlQuery(storeData?.meta?.searchConfig?.filters || {}, true);
    this._sendRequest(storeData, loadData);
  }

  loadContent(pageNumber, filterDefaults = {}) {
    const params = WindowUtil.getUrlQuery();

    if (Object.keys(params).length) {
      this._sendRequest({
        meta: {
          serverMeta: {
            currentPage: pageNumber || 1,
          },
          searchConfig: {
            filters: { ...params },
          },
        },
      });
    } else {
      this._sendRequest({
        meta: {
          serverMeta: {
            currentPage: pageNumber || 1,
          },
          searchConfig: {
            filters: {
              ...filterDefaults,
            },
          },
        },
      });
    }
  }

  _sendRequest(storeData, loadData) {
    if (!storeData.meta.searchConfig) {
      storeData.meta.searchConfig = SavedFilterDefault();
    }
    if (!storeData.meta.serverMeta) {
      storeData.meta.serverMeta = {};
    }

    const sendMeta = this.formatMetaForRequest(storeData.meta);

    this.dataRequestCount.called += 1;

    return this.setState(
      this.getResults(sendMeta, loadData).then(({ meta, data }) => {
        this.dataRequestCount.received += 1;

        return {
          meta: {
            ...storeData.meta,
            serverMeta: meta,
          },
          results: [...(storeData.results || [])].concat(data || []),
        };
      })
    );
  }

  getDataRequestCount() {
    return this.dataRequestCount;
  }

  _paginate(storeData, incremental) {
    this.load({
      meta: {
        ...storeData.meta,
        serverMeta: {
          currentPage: storeData.meta.serverMeta.currentPage + incremental,
        },
      },
      results: storeData.results,
    });
  }

  loadNextPage(storeData) {
    if (
      storeData.meta.serverMeta.currentPage <
      storeData.meta.serverMeta.pageCount
    ) {
      this._paginate(storeData, 1);
    }
  }

  loadPreviousPage(storeData) {
    if (storeData.meta.serverMeta.currentPage > 1) {
      this._paginate(storeData, -1);
    }
  }

  changeFilters(meta, newFilters) {
    this.load({
      meta: {
        searchConfig: {
          ...meta?.searchConfig,
          filters: {
            ...meta?.searchConfig?.filters,
            ...newFilters,
          },
        },
      },
    });
  }

  changeSort(meta, sortBy) {
    this.load({
      meta: {
        searchConfig: meta.searchConfig,
        sort: sortBy,
      },
    });
  }

  getMetaShape() {
    return shape({
      sort: string,
      searchConfig: this.getMetaConfigShape(),
      serverMeta: shape({
        currentPage: number,
        pageCount: number,
        resultCount: number,
      }),
    });
  }

  getMetaConfigShape() {
    return shape(SavedFilterShapeStructure(this.filterShape));
  }

  getDataShape() {
    return shape({
      meta: this.getMetaShape(),
      results: arrayOf(this.dataShape),
    });
  }
}
