import React from 'react';
import { func } from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Gutter,
  Paragraph,
} from '@axiom/ui';

import { Checkbox } from '../FormElements/Checkbox/Checkbox';

export const NotificationPreferencesModal = ({ onClose, onSubmit }) => {
  return (
    <Modal name="NOTIFICATION_PREFERENCES">
      <ModalHeader onClose={onClose}>Notification Preferences</ModalHeader>
      <ModalSection>
        <form
          onSubmit={e => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Gutter bottom="16px">
            <Paragraph>
              Choose the bench notificatons you want to receive and get relevant
              updates sent directly to your email.
            </Paragraph>
          </Gutter>
          <Gutter vertical="8px">
            <Checkbox
              bold
              name="benchNotifications"
              label="Bench Updates"
              subtext="Let me know when there are additions or changes to talent on my bench."
            />
          </Gutter>
          <Gutter vertical="8px">
            <Checkbox
              bold
              name="availabilityNotifications"
              label="Availability Updates"
              subtext="Let me know when availability changes for talent on my bench."
            />
          </Gutter>
          <Gutter vertical="8px">
            <Checkbox
              bold
              disabled
              name="marketingCommunications"
              label="Marketing Communications"
              subtext={
                <a
                  data-test="MARKETING_UNSUBSCRIBE_LINK"
                  href="https://www.axiomlaw.com/unsubscribe"
                  target="_blank"
                  rel="noreferrer"
                >
                  Manage your marketing email preferences on AxiomLaw.com.
                </a>
              }
            />
          </Gutter>
        </form>
      </ModalSection>
      <ModalFooter>
        <Button name="cancel" variation="outline" onClick={onClose}>
          Cancel
        </Button>

        <Button name="submit" onClick={onSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

NotificationPreferencesModal.propTypes = {
  onClose: func.isRequired,
  onSubmit: func.isRequired,
};
