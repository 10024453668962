import React from 'react';
import { Page } from '@axiom/ui';
import { UserConst } from '@axiom/const';

import { PageLayout } from '../layouts/PageLayout';
import { ClientEngagementsRedirectClientUser } from '../components/ClientEngagements/ClientEngagementsRedirectClientUser';
import { AuthRoute } from '../routes/AuthRoute';
import AppContainer from '../components/AppContainer/AppContainer';

// TODO remove as part of (FR-4550)
export const EngagementsPage = () => (
  <Page title="AxiomLaw - Engagements">
    <AuthRoute
      roles={[
        UserConst.Roles.ClientUser,
        UserConst.Roles.EnvoyAdmin,
        UserConst.Roles.EnvoyUser,
        UserConst.Roles.EnvoySales,
      ]}
    >
      <AppContainer>
        <PageLayout showLoggedUser>
          <ClientEngagementsRedirectClientUser />
        </PageLayout>
      </AppContainer>
    </AuthRoute>
  </Page>
);
