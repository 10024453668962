/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import size from 'lodash/fp/size';
import { bool, func, string, number, node } from 'prop-types';
import { Trans } from '@lingui/macro';
import { BulletsIcon, Gutter, ResetIcon } from '@axiom/ui';

import { FormElementMaxChars } from '../FormElementMaxChars/FormElementMaxChars';

import {
  RTButtonsWrapper,
  RTEditorWrapper,
  RTButton,
  RTResetButton,
} from './RichTextEditorStyles';

let draftConvert;
let draftJs;
let convertFromHTML;
let convertToRaw;
let Editor;
let EditorState;
let SelectionState;
let RichUtils;

class RawRichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.editorRef = React.createRef();
    this.isFocused = false;
    this.state = {
      plainText: props.initialValue || '',
      editorState: null,
    };
  }

  componentDidMount() {
    draftConvert = require('draft-convert'); // eslint-disable-line
    draftJs = require('draft-js'); // eslint-disable-line
    convertFromHTML = draftConvert.convertFromHTML; // eslint-disable-line
    convertToRaw = draftJs.convertToRaw; // eslint-disable-line
    Editor = draftJs.Editor; // eslint-disable-line
    EditorState = draftJs.EditorState; // eslint-disable-line
    SelectionState = draftJs.SelectionState; // eslint-disable-line
    RichUtils = draftJs.RichUtils; // eslint-disable-line
    this.setState({
      editorState: this.newEditorState(this.props.initialValue),
    });
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.dirty && !this.props.dirty) ||
      (prevProps.initialValue !== this.props.initialValue &&
        this.props.initialValue !== this.state.plainText)
    ) {
      this.setState({
        plainText: this.props.initialValue,
        editorState: this.moveSelectionToEnd(
          this.newEditorState(this.props.initialValue)
        ),
      });
    }

    if (this.props.isFocused !== prevProps.isFocused) {
      if (this.props.isFocused) {
        if (!this.isFocused) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState(
            state => ({
              editorState: this.moveSelectionToEnd(state.editorState),
            }),
            () => {
              this.isFocused = true;
            }
          );
        }
      } else {
        this.editorRef.current.blur();
        this.isFocused = false;
      }
    }
  }

  onChange = editorState => {
    const plainText = this.buildPlainText(editorState);
    this.setState(
      {
        editorState,
        plainText,
      },
      () => {
        this.props.onChange(plainText);
      }
    );
  };

  onFocus = () => {
    this.isFocused = true;
    this.props.onFocus(this.state.plainText);
  };

  onBlur = () => {
    this.isFocused = false;
    this.props.onChange(this.state.plainText);
    this.props.onBlur();
  };

  onKeyCommand = (command, editorState) => {
    const allowedCommands = new Set([
      'split-block',
      'backspace',
      'backspace-to-start-of-line',
      'backspace-word',
      'redo',
    ]);

    if (allowedCommands.has(command)) {
      const updatedState = RichUtils.handleKeyCommand(editorState, command);

      if (updatedState) {
        this.setState({ editorState: updatedState });
        return 'handled';
      }
    }

    return 'not-handled';
  };

  onUlToggleButtonClick = () => {
    const updatedState = RichUtils.toggleBlockType(
      this.state.editorState,
      'unordered-list-item'
    );

    if (updatedState) this.onChange(updatedState);
  };

  handleReset = () => {
    if (this.props.onReset) {
      this.props.onReset();
    } else {
      const editorState = this.newEditorState(this.props.initialValue);
      this.setState({ editorState });
    }
  };

  handleBeforeInput = () => {
    const {
      props: { maxLength },
    } = this;

    return maxLength && size(this.state.plainText) > maxLength - 1
      ? 'handled'
      : null;
  };

  handlePastedText = pastedText => {
    const {
      props: { maxLength },
    } = this;

    return maxLength &&
      size(this.state.plainText) + size(pastedText) > maxLength
      ? 'handled'
      : null;
  };

  moveSelectionToEnd = editorState => {
    // https://github.com/brijeshb42/medium-draft/issues/71#issuecomment-399411065
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });

    return EditorState.forceSelection(editorState, selection);
  };

  newEditorState = plainText => {
    const htmlText = (plainText || '')
      .split('\n')
      .map(plainTextLine => {
        let data = `<p>${plainTextLine}</p>`;

        if (plainTextLine.indexOf('- ') === 0) {
          data = `<ul><li>${plainTextLine.slice(2)}</li></ul>`;
        } else if (/^(\d+\.\s)/.test(plainTextLine)) {
          data = `<ol><li>${plainTextLine.slice(
            plainTextLine.indexOf('. ') + 2
          )}</li></ol>`;
        }

        return data;
      })
      .join('');

    const contentState = convertFromHTML(htmlText);
    const newEditorState = EditorState.createWithContent(contentState);

    return newEditorState;
  };

  buildPlainText = editorState => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    let listItemNumber = 1; // draft.js doesn't expose this

    return rawContent.blocks
      .map(block => {
        if (block.type === 'ordered-list-item') {
          return `${listItemNumber++}. ${block.text}`;
        }
        listItemNumber = 1;
        if (block.type === 'unordered-list-item') {
          return `- ${block.text}`;
        }

        return block.text;
      })
      .join('\n');
  };

  render() {
    const {
      dirty,
      errorMessageNode,
      initialValue,
      invalid,
      isFocused,
      maxLength,
      onBlur,
      onChange,
      onFocus,
      onReset,
      placeholder,
      readOnly,
      showButtons,
      showCharsRemainingLabel,
      showResetButton,
      ...rest
    } = this.props;

    return (
      <>
        <RTEditorWrapper
          invalid={invalid}
          readOnly={readOnly}
          data-test={`RICH_TEXT_EDITOR${readOnly ? '_DISABLED' : ''}`}
        >
          {this.state.editorState && (
            <Editor
              {...rest}
              ref={this.editorRef}
              editorState={this.state.editorState}
              handleBeforeInput={this.handleBeforeInput}
              handleKeyCommand={this.onKeyCommand}
              handlePastedText={this.handlePastedText}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.onFocus}
              readOnly={readOnly}
              placeholder={placeholder}
              stripPastedStyles
            />
          )}
        </RTEditorWrapper>
        {(showResetButton || (!readOnly && showButtons)) && (
          <RTButtonsWrapper>
            {!readOnly && showButtons && (
              <RTButton
                name="TOGGLE_BULLET_POINTS"
                onClick={this.onUlToggleButtonClick}
              >
                <BulletsIcon />
                <span className="sr-only">
                  <Trans>Bullet points</Trans>
                </span>
              </RTButton>
            )}
            {showResetButton && (
              <RTResetButton onClick={this.handleReset} name="RESET_TEXT">
                <ResetIcon />
                <Trans>Reset</Trans>
              </RTResetButton>
            )}
          </RTButtonsWrapper>
        )}
        {showCharsRemainingLabel && !readOnly && (
          <Gutter className="text-align-right" top="4px">
            <FormElementMaxChars
              maxLength={maxLength}
              value={this.state.plainText}
            />
          </Gutter>
        )}
        {!readOnly && errorMessageNode}
      </>
    );
  }
}

RawRichTextEditor.defaultProps = {
  dirty: false,
  errorMessageNode: null,
  initialValue: '',
  invalid: false,
  isFocused: false,
  maxLength: null,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onReset: null,
  placeholder: null,
  readOnly: false,
  showButtons: true,
  showCharsRemainingLabel: true,
  showResetButton: false,
};

RawRichTextEditor.propTypes = {
  dirty: bool,
  errorMessageNode: node,
  initialValue: string,
  invalid: bool,
  isFocused: bool,
  maxLength: number,
  onBlur: func,
  onChange: func,
  onFocus: func,
  onReset: func,
  placeholder: string,
  readOnly: bool,
  showButtons: bool,
  showCharsRemainingLabel: bool,
  showResetButton: bool,
};

export default RawRichTextEditor;
