import React from 'react';
import { Trans } from '@lingui/macro';
import { FlexiCardGridBody, Text } from '@axiom/ui';

import { CandidateAccountShape } from '../../models/candidate';
import { DateUtil } from '../../utils/date-util';

export const BCCardHidden = ({ candidate }) => (
  <FlexiCardGridBody className="hidden-external-body">
    <div data-test="CANDIDATE_CARD_HIDDEN">
      <Text className="font-weight-bold">
        <Trans>Not currently visible to clients.</Trans>
      </Text>
      <Text>
        <Trans>To edit, use the context menu above.</Trans>
      </Text>
      <Text>
        <Trans>
          This talent was hidden by{' '}
          {candidate.isHiddenExternalUpdatedByUser?.fullName} on{' '}
          {DateUtil.displayDate(candidate.isHiddenExternalUpdatedAt)}.
        </Trans>
      </Text>
    </div>
  </FlexiCardGridBody>
);

BCCardHidden.propTypes = {
  candidate: CandidateAccountShape.isRequired,
};
