import { shape, bool, string } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { WindowUtil } from '../utils/window-util';

class ShareLinkModalClass extends GenericStore {
  load(copyText) {
    return this.setState({
      success: WindowUtil.copyToClipboard(copyText),
      copyText,
      isModalOpen: true,
    });
  }

  getDataShape() {
    return shape({ isModalOpen: bool, copyText: string, success: bool });
  }

  closeModal = () => {
    this.clearState();
  };
}

export const ShareLinkModalStore = new ShareLinkModalClass();
