import { useParams } from 'react-router-dom';
import { useApi } from '@axiom/ui';
import { AccountConst } from '@axiom/const';

import { UserApi } from '../api/user/user';
import { AccountLegacyApi } from '../api/account/account-legacy';

export const useUserAccountData = () => {
  const { accountId } = useParams();
  const [{ data: user }] = useApi(UserApi.readUser());

  const [{ data: account }] = useApi(
    AccountLegacyApi.getAccount(accountId || user?.contact?.accounts?.[0]?.id, {
      expand: [AccountConst.Expansions.accountTeamUserInfo],
    })
  );

  return {
    user,
    account,
  };
};
