import { Statistics } from '@axiom/const';

import GenericStore from '../classes/generic-store';
import { CandidateApi } from '../api/candidate/candidate';

export const ENDOFTALENTSEARCHID = 'endOfTalentCount';

class EndOfTalentSearchStoreClass extends GenericStore {
  load(usedFilters) {
    const possibleMarketingUTMs = new Set([
      'gclid',
      'hsa_acchsa_ad',
      'hsa_cam',
      'hsa_grp',
      'hsa_kw',
      'hsa_mt',
      'hsa_net',
      'hsa_src',
      'hsa_tgt',
      'hsa_ver',
      'utm_campaign',
      'utm_medium',
      'utm_source',
      'utm_term',
    ]);
    const newUsedFilters = {};
    for (const key in usedFilters) {
      if (!possibleMarketingUTMs.has(key)) {
        newUsedFilters[key] = usedFilters[key];
      }
    }
    // TODO: use the filters util as a inclusion filter: TPR-1230
    const hasFilters = Object.entries(newUsedFilters).some(
      ([key, value]) =>
        !!key && !!value && key !== 'view' && key !== 'search' && key !== 'sort' // "view" is the key for home/saved query param and "search" and "sort" we just don't want
    );

    if (hasFilters) {
      const filters = {
        stats: [
          {
            id: ENDOFTALENTSEARCHID,
            type: Statistics.indexes.optedOutCount,
            filters: {
              ...newUsedFilters,
              search: undefined, // holing off on this till later
              view: undefined, // removing the home/saved query param
              sort: undefined,
            },
          },
        ],
      };

      return this.setState(
        CandidateApi.readCandidateStats(filters).then(({ data: response }) => {
          const data = response.reduce((acc, item) => {
            acc = {
              ...acc,
              [item.id]: item.count,
            };
            return acc;
          }, {});

          return data;
        })
      );
    }

    return this.setState({ end_of_talent: 0 });
  }
}

export const EndOfTalentSearchStore = new EndOfTalentSearchStoreClass();
