import React, { useMemo } from 'react';
import { Button, Gutter, Pill, Pills } from '@axiom/ui';

import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';

export const TalentSearchPills = ({ filters }) => {
  const pillsModel = useMemo(() => {
    return filters.categories
      .reduce((accum, category) => {
        let catItems = [];

        if (category.items.alwaysShow) {
          catItems = category.items.alwaysShow.concat(category.items.showHide);
        } else {
          const admissionItems = Object.entries(category.items).map(e => e[1]);

          admissionItems.forEach(admissionItem => {
            catItems = catItems.concat(
              admissionItem.alwaysShow,
              admissionItem.showHide
            );
          });
        }

        accum = [].concat(
          accum,
          catItems.filter(item => item.checked)
        );

        return accum;
      }, [])
      .map(item => {
        item = { ...item, id: JSON.stringify(item.id) };
        return item;
      });
  }, [filters.categories]);

  const onRemoveFilter = filter => {
    FormTalentSearchFiltersStore.removeFilter(filter, filters);
  };

  const handleClearAll = () => {
    FormTalentSearchFiltersStore.submit();
  };

  if (!pillsModel.length) {
    return null;
  }

  return (
    <Gutter bottom="16px">
      <Pills name="TALENT_SEARCH_FILTER_PILLS">
        {pillsModel.map(pill => (
          <Pill
            name="TALENT_SEARCH_FILTER_PILL"
            key={pill.id}
            pattern="secondary"
            onDismiss={() => {
              onRemoveFilter(pill);
            }}
          >
            {pill.name}
          </Pill>
        ))}
        {pillsModel.length > 0 && (
          <Button
            pattern="secondary"
            variation="minimal"
            name="clear_all"
            onClick={handleClearAll}
            data-test="CLEAR_ALL"
          >
            Clear All
          </Button>
        )}
      </Pills>
    </Gutter>
  );
};

TalentSearchPills.propTypes = {
  filters: FormTalentSearchFiltersStore.getDataShape().isRequired,
};
