import React from 'react';
import { Account, Candidate, User, Submission } from '@axiom/validation';

import { Card } from '../../layout/Card/Card';
import { CardHeader } from '../../layout/Card/CardHeader';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { CardSection } from '../../layout/Card/CardSection';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { ImageCircle } from '../../element/ImageCircle/ImageCircle';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';

const getUserName = (user: User) =>
  `${user.firstName || ''} ${user.lastName || ''}`;

export type ClientContactsTypes = {
  ownerUser?: Account['ownerUser'] | Candidate['ownerUser'];
  cxPartner?: Account['cxPartner'];
  opportunityOwner?: Submission['salesLead'];
};

export const ClientContacts = ({
  ownerUser,
  cxPartner,
  opportunityOwner,
}: ClientContactsTypes) => {
  return (
    (ownerUser || cxPartner || opportunityOwner) && (
      <Card name="CONTACTS_CARD" stretched className="with-borders">
        <CardHeader>
          <SmallHeader name="CONTACTS_HEADER">Axiom Contacts</SmallHeader>
        </CardHeader>
        <CardSection>
          <Layout verticalGutter="16px" horizontalGutter="16px" wrap>
            {opportunityOwner && (
              <LayoutItem fluid name="opp-owner-user">
                <Layout
                  position="middle"
                  horizontalGutter="16px"
                  verticalGutter="16px"
                >
                  <LayoutItem>
                    <ImageCircle
                      size="small"
                      imageName={getUserName(opportunityOwner)}
                      name="opp-owner-user-image"
                    />
                  </LayoutItem>
                  <LayoutItem>
                    <ParagraphHeader name="contact-name">
                      {getUserName(opportunityOwner)}
                    </ParagraphHeader>
                    <a
                      data-test="contact-email"
                      href={`mailto: ${opportunityOwner.email}`}
                    >
                      {opportunityOwner.email}
                    </a>
                  </LayoutItem>
                </Layout>
              </LayoutItem>
            )}
            {ownerUser && ownerUser.email !== opportunityOwner?.email && (
              <LayoutItem fluid name="owner-user">
                <Layout
                  position="middle"
                  horizontalGutter="16px"
                  verticalGutter="16px"
                >
                  <LayoutItem>
                    <ImageCircle
                      size="small"
                      imageName={getUserName(ownerUser)}
                      name="owner-user-image"
                    />
                  </LayoutItem>
                  <LayoutItem>
                    <ParagraphHeader name="contact-name">
                      {getUserName(ownerUser)}
                    </ParagraphHeader>
                    <a
                      data-test="contact-email"
                      href={`mailto: ${ownerUser.email}`}
                    >
                      {ownerUser.email}
                    </a>
                  </LayoutItem>
                </Layout>
              </LayoutItem>
            )}
            {cxPartner &&
              cxPartner.email !== opportunityOwner?.email &&
              cxPartner?.email !== ownerUser?.email && (
                <LayoutItem fluid name="cx-user">
                  <Layout position="middle" horizontalGutter="16px">
                    <LayoutItem>
                      <ImageCircle
                        size="small"
                        imageName={getUserName(cxPartner)}
                        name="cx-user-image"
                      />
                    </LayoutItem>
                    <LayoutItem>
                      <ParagraphHeader name="contact-name">
                        {getUserName(cxPartner)}
                      </ParagraphHeader>
                      <a
                        data-test="contact-email"
                        href={`mailto: ${cxPartner.email}`}
                      >
                        {cxPartner.email}
                      </a>
                    </LayoutItem>
                  </Layout>
                </LayoutItem>
              )}
          </Layout>
        </CardSection>
      </Card>
    )
  );
};
