import React from 'react';
import { Gutter } from '@axiom/ui';
import { arrayOf } from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';
import { FormExternalSearchReachOutModalStore } from '../../stores/form-external-search-reach-out-modal-store';
import { searchResultsShape } from '../../models/search';
import { SearchStore } from '../../stores/search-store';
import { PreloadedPracticeAreasStore } from '../../stores/preloaded-practice-areas-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';

import { TalentSearchEmpty } from './TalentSearchEmpty';
import { TalentSearchCard } from './TalentSearchCard';

export const TalentSearchFavorited = ({
  candidates,
  grid,
  practiceAreas,
  user,
}) => {
  const [, setSearchParams] = useSearchParams();
  return candidates.length > 0 ? (
    <>
      {candidates.map(c => (
        <Gutter bottom="16px" key={c.id}>
          <TalentSearchCard
            candidate={c}
            practiceAreas={practiceAreas}
            user={user}
          />
        </Gutter>
      ))}
    </>
  ) : (
    <Gutter bottom="16px">
      <TalentSearchEmpty
        onClick={view => {
          setSearchParams({ view });
        }}
        activeFilters={grid?.meta?.searchConfig?.filters}
        handleClearFiltersClick={() => {
          FormTalentSearchFiltersStore.submit();
        }}
        handleReachOutClick={() => {
          FormExternalSearchReachOutModalStore.load(user);
        }}
      />
    </Gutter>
  );
};

TalentSearchFavorited.propTypes = {
  candidates: arrayOf(searchResultsShape).isRequired,
  grid: SearchStore.getDataShape().isRequired,
  practiceAreas: PreloadedPracticeAreasStore.getDataShape().isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
};
