import styled from 'styled-components';

export const CardRadioWrapper = styled.div`
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  word-wrap: break-word;
  height: 100%;
  outline: ${({ theme, highlighted }) =>
    highlighted
      ? `2px solid ${theme.colors.axiomOrange}`
      : `1px solid ${theme.neutrals.neutral50}`};

  justify-content: space-between;
`;

export const CardRadioTextWrapper = styled.div`
  word-break: break-word;
`;

export const CardRadioRadioButtonWrapper = styled.div`
  justify-content: flex-end;
`;
