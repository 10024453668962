import { stringify } from 'qs';
import requestLib from 'superagent';
import { CookieTokenRefresher } from '@axiom/auth';
import FormData from 'form-data';
import { CookieUtil } from '@axiom/ui';

import {
  APIError,
  BadRequestError,
  AuthorizationError,
  AuthenticationError,
} from '../api/errors';
import { dataUriToBlob } from '../utils/data-uri';
import { EnvUtil } from '../utils/env-util';

// API calls that shouldn't reset the inactivity timeout.
const API_CALLS_TO_SKIP = [];

let cookieRefresher;
if (process.browser) {
  cookieRefresher = new CookieTokenRefresher({
    cookieDomain: () => `${EnvUtil.cookieDomain}`,
  });
}

async function request(name, endpoint, method, body, headers = {}) {
  try {
    if (process.browser && !API_CALLS_TO_SKIP.includes(name)) {
      cookieRefresher.resetTimeout();
    }

    const formattedEndpoint = endpoint.startsWith('https')
      ? endpoint
      : `${EnvUtil.clientApiBase || '/api'}${endpoint}`;

    const requestObj = requestLib[method.toLowerCase()](formattedEndpoint)
      .set({
        ...(body instanceof FormData
          ? {}
          : { 'Content-Type': 'application/json' }),
        ...headers,
      })
      .withCredentials();

    const response = await requestObj.send(body);
    if (response?.body?.redirect) {
      const rebody = await request(
        name,
        response.body.redirect,
        method,
        body,
        headers
      );
      rebody.redirected = response.body.redirect;
      return rebody;
    }
    return response.body;
  } catch (e) {
    if (!e || !e.response) {
      throw new Error(e);
    }

    const { response } = e;

    const { body: errBody } = response;
    const obj = {
      name,
      endpoint,
      method,
      body: errBody || {},
      headers,
      response,
    };

    let error;

    switch (response.status) {
      case 400:
        error = new BadRequestError(obj);
        break;
      case 403:
        error = new AuthenticationError(obj);
        break;
      case 401:
        CookieUtil.clearUserAndReload(EnvUtil.cookieDomain);
        throw new AuthorizationError(obj);
      default:
        error = new APIError(obj);
    }

    throw error;
  }
}

async function uploadImage(name, endpoint, method, imageName, imageUri) {
  try {
    if (typeof imageUri === 'string') {
      imageUri = dataUriToBlob(imageUri);
    }
    const formattedEndpoint = endpoint.startsWith('https')
      ? endpoint
      : `/api${endpoint}`;

    const response = await requestLib[method.toLowerCase()](
      formattedEndpoint
    ).attach(imageName, imageUri, imageName);
    return response.body;
  } catch (e) {
    if (!e || !e.response) {
      throw new Error(e);
    }

    const { response } = e;

    const { body: errBody } = response;
    const obj = {
      name,
      endpoint,
      method,
      body: errBody || {},
      response,
    };

    let error;
    switch (response.status) {
      case 400:
        error = new BadRequestError(obj);
        break;
      case 403:
        error = new AuthenticationError(obj);
        break;
      case 401:
        throw new AuthorizationError(obj);
      default:
        error = new APIError(obj);
    }

    throw error;
  }
}

/**
 * For the Java API, objects need to be an encoded JSON string. Here, we extract and
 * encode each object and append it to the existing query string
 * @param {*} query The query string containing the potential object(s) to be
 * encoded
 */
const encodeApiObjects = query => {
  const serializedObjects = [];
  const updatedQuery = {};

  for (const property in query) {
    if (query[property] && typeof query[property] === 'object') {
      serializedObjects.push(
        [property, encodeURIComponent(JSON.stringify(query[property]))].join(
          '='
        )
      );
    } else {
      updatedQuery[property] = query[property];
    }
  }

  return [stringify(updatedQuery), ...serializedObjects].join('&');
};

export const get = (name, endpoint, body, encodeFilters = false) =>
  request(
    name,
    `${endpoint}/?${encodeFilters ? encodeApiObjects(body) : stringify(body)}`,
    'GET'
  );

export const post = (name, endpoint, body) =>
  request(name, endpoint, 'POST', JSON.stringify(body));

export const put = (name, endpoint, body) =>
  request(name, endpoint, 'PUT', JSON.stringify(body));

export const patch = (name, endpoint, body) =>
  request(name, endpoint, 'PATCH', JSON.stringify(body));

export const putImage = (name, endpoint, imageName, imageUri) =>
  uploadImage(name, endpoint, 'PUT', imageName, imageUri);

export const httpDelete = (name, endpoint) => request(name, endpoint, 'DELETE');

export const multiPost = (name, endpoint, body) => {
  const formData = new FormData();
  Object.keys(body).forEach(key => {
    if (Array.isArray(body[key])) {
      body[key].forEach(val => formData.append(`${key}[]`, val));
    } else {
      formData.append(key, body[key]);
    }
  });
  return request(name, endpoint, 'POST', formData);
};

export const multiPatch = (name, endpoint, body) => {
  const formData = new FormData();
  Object.keys(body).forEach(key => {
    if (Array.isArray(body[key])) {
      body[key].forEach(val => formData.append(`${key}[]`, val));
    } else {
      formData.append(key, body[key]);
    }
  });
  return request(name, endpoint, 'PATCH', formData);
};

// NOTE: Right now this just curries API names, but in the future could be
// extended to allow APIs to pass other custom data/callbacks
export default class ApiHelper {
  constructor(name) {
    this.name = name;
  }

  GET(...args) {
    return get(this.name, ...args);
  }

  POST(...args) {
    return post(this.name, ...args);
  }

  PUT(...args) {
    return put(this.name, ...args);
  }

  PATCH(...args) {
    return patch(this.name, ...args);
  }

  DELETE(...args) {
    return httpDelete(this.name, ...args);
  }

  MULTIPOST(...args) {
    return multiPost(this.name, ...args);
  }

  MULTIPATCH(...args) {
    return multiPatch(this.name, ...args);
  }

  PUTIMAGE(...args) {
    return putImage(this.name, ...args);
  }
}
