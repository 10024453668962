import moment from 'moment';
import {
  CalendarEventType,
  CalendarComponentEvent,
  TimeZone,
} from '@axiom/validation';

import { EventStates } from './CalendarEventsConst';

type CleanEventType = Omit<CalendarEventType, 'state'>;

type CleanEvent = Omit<CalendarEventType, 'state'>;
type CalendarOnChangeType = {
  newEvents: CleanEvent[];
  modifiedEvents: CleanEvent[];
  deleteIds: string[];
};

export const CalendarEventsUtil = {
  translateFromStartTimeEndTimeProps: (
    events: CalendarEventType[]
  ): CalendarComponentEvent[] => {
    return events.reduce(
      (
        data: CalendarComponentEvent[],
        event: CalendarEventType
      ): CalendarComponentEvent[] => {
        const { endTime, startTime, ...rest } = event;
        const item: CalendarComponentEvent = {
          ...rest,
        };

        if (endTime) {
          item.end = moment(endTime).toDate();
        }
        if (startTime) {
          item.start = moment(startTime).toDate();
        }

        data.push(item);
        return data;
      },
      []
    );
  },
  translateToStartTimeEndTimeProps: (
    events: CalendarComponentEvent[]
  ): CalendarEventType[] => {
    return events.reduce(
      (
        data: CalendarEventType[],
        event: CalendarComponentEvent
      ): CalendarEventType[] => {
        const { end, start, error, ...rest } = event;
        const item: CalendarEventType = {
          ...rest,
        };

        if (end) {
          item.endTime = moment(end).toISOString();
        }

        if (start) {
          item.startTime = moment(start).toISOString();
        }

        if (error) {
          item.error = error;
        }

        data.push(item);
        return data;
      },
      []
    );
  },
  sortEvents: (events: CalendarComponentEvent[]) => {
    if (events.length >= 2) {
      events.sort((a, b) => {
        const aStart = moment(a.start);
        const bStart = moment(b.start);
        const aEnd = moment(a.end);
        const bEnd = moment(b.end);

        if (aStart.isBefore(bStart) || aEnd.isBefore(bEnd)) return -1;

        return 1;
      });
    }
    return events;
  },
  getDeletedEventIds: (
    events: CalendarComponentEvent[],
    formEvents?: CalendarComponentEvent[]
  ) => {
    const formIds = new Set(formEvents?.map(e => e.id) || []);

    return (
      events
        ?.filter(event => {
          return moment(event.end).isAfter(moment(), 'day');
        })
        ?.map(e => e.id) || []
    ).reduce((crnt, id) => {
      if (!formIds.has(id)) {
        crnt.push(id);
      }
      return crnt;
    }, [] as CalendarOnChangeType['deleteIds']);
  },
  clearAndOrganiseEvents: (
    initData: CalendarComponentEvent[],
    changedEvents?: CalendarComponentEvent[]
  ): CalendarOnChangeType => {
    const baseDataset: CalendarOnChangeType = {
      newEvents: [],
      modifiedEvents: [],
      deleteIds: [],
    };

    const cleanEvents = changedEvents?.length
      ? changedEvents.reduce(
          (data, event: CalendarComponentEvent) => {
            const tmpEvent: CalendarComponentEvent = {
              ...event,
              id: event.state === EventStates.NEW ? null : event.id,
              isBackgroundEvent: null,
            };

            const cleanEvent = (toCleanEvent: CalendarEventType) => {
              return Object.entries({
                ...toCleanEvent,
                state: null,
              }).reduce((crnt, [key, value]) => {
                if (value !== null) Object.assign(crnt, { [key]: value });

                return crnt;
              }, {} as CleanEventType);
            };

            if (tmpEvent.state === EventStates.NEW) {
              data.newEvents.push(cleanEvent(tmpEvent));
            } else if (tmpEvent.state === EventStates.MODIFIED) {
              data.modifiedEvents.push(cleanEvent(tmpEvent));
            }

            return data;
          },
          { ...baseDataset } as CalendarOnChangeType
        )
      : baseDataset;

    cleanEvents.deleteIds = CalendarEventsUtil.getDeletedEventIds(
      initData,
      changedEvents
    );

    return cleanEvents;
  },
  getError: (
    formEvents?: CalendarEventType[],
    timeZoneId?: TimeZone['id'],
    noGoodTimes?: boolean
  ): null | string => {
    if (!noGoodTimes) {
      if (formEvents?.some(event => !!event.error)) {
        return 'Please select at least one available time to continue.';
      }

      if (!timeZoneId && !formEvents) {
        return 'Please make at least one edit to continue.';
      }
    }

    return null;
  },
};
