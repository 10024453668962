import { number, shape, string } from 'prop-types';

export const CertificationShape = shape({
  id: string.isRequired,
  candidateId: string,
  award: string.isRequired,
  institution: string.isRequired,
  year: number.isRequired,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
});
