import React from 'react';
import {
  CandidateOpportunitiesConst,
  CurrencyCodeKeyTypes,
  CandidateImageConst,
} from '@axiom/const';
import {
  CandidateRateUtil,
  CondensedHeader,
  CondensedLabel,
  CondensedMedium,
  Gutter,
  ImageCircle,
  Layout,
  LayoutItem,
  Visible,
} from '@axiom/ui';
import { Submission, CandidateOpportunity } from '@axiom/validation';
import { CurrencyUtil } from '@axiom/utils';
import { CandidateYearsOfExperienceModel } from '@axiom/ui-models';

import { CandidateUtil } from '../../utils/candidate-util';

const { PossibleImageSizes } = CandidateImageConst;

export const ClientEngagementsEngagementTalentTile: React.FC<{
  candidateOpp: CandidateOpportunity;
  submission: Submission;
}> = ({ candidateOpp, submission }) => {
  return (
    <Layout horizontalGutter="8px" name="talent-tile">
      <LayoutItem fluid>
        <Layout position="middle" horizontalGutter="8px" wrap>
          <Visible only="largeScreen mobile">
            <LayoutItem>
              <ImageCircle
                size="small"
                imageName={candidateOpp.candidate.calculatedDisplayName}
                src={CandidateUtil.getProfileImageUri(
                  candidateOpp.candidate,
                  PossibleImageSizes.W_100
                )}
              />
            </LayoutItem>
          </Visible>
          <LayoutItem fluid hug>
            <CondensedHeader name="talent-name" maxLines={1}>
              {candidateOpp.candidate.calculatedDisplayName}
            </CondensedHeader>

            <Gutter bottom="8px" />

            <Layout position="middle">
              <div className="max-lines-1">
                <CondensedLabel>Experience</CondensedLabel>
                <Gutter right="8px" />
                <CondensedMedium name="years-of-experience">
                  {candidateOpp?.candidate?.yearsOfExperience
                    ? `${new CandidateYearsOfExperienceModel(
                        candidateOpp.candidate.yearsOfExperience
                      ).getYearsOfExperienceRange()} years`
                    : '--'}
                </CondensedMedium>
              </div>
            </Layout>

            <Layout position="middle">
              <div className="max-lines-1">
                <CondensedLabel>Rate</CondensedLabel>
                <Gutter right="8px" />
                <CondensedMedium name="rate">
                  {candidateOpp.displayBillingRate &&
                  candidateOpp.displayBillingRate !==
                    CandidateOpportunitiesConst.DisplayBillingRate.none
                    ? CandidateRateUtil.formatCandidateRate(
                        { ...candidateOpp, id: candidateOpp.candidate.id },
                        CurrencyUtil.convertToSymbol(
                          submission.currency as CurrencyCodeKeyTypes
                        ),
                        submission
                      )
                    : '--'}
                </CondensedMedium>
              </div>
            </Layout>
          </LayoutItem>
        </Layout>
      </LayoutItem>
    </Layout>
  );
};
