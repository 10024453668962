import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Envoy in the window completely loads before this component even renders hence why a useEffect
 * window load event won't ever fire. If that's the case, we just immediately register the service worker
 */
let windowLoaded = false;
/**
 * Of course, because of nextjs we gotta do an if check on window =.= T.T
 */
if (typeof window !== 'undefined') {
  window.addEventListener('load', () => {
    windowLoaded = true;
  });
}

export const ServiceWorkerProvider = () => {
  // const updateServiceWorker = () => {
  //   window.location.reload();
  // };

  const loadServiceWorker = () => {
    /**
     * No babel optional chains enabled in storybook's build for some reason
     */
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker
        .register('/service-worker.js', {})
        .then(registration => {
          registration.onupdatefound = () => {
            // eslint-disable-next-line no-console
            console.log('~~~onupdatefound FOUND~~~');
            // updateServiceWorker(registration);
          };
          // eslint-disable-next-line no-console
          console.log('SW registered: ', registration);
        })
        .catch(registrationError => {
          // eslint-disable-next-line no-console
          console.log('SW registration failed: ', registrationError);
        });
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      if (windowLoaded) {
        loadServiceWorker();
      } else {
        window.addEventListener('load', () => {
          loadServiceWorker();
        });
      }
    }
  }, [loadServiceWorker]);

  useEffect(() => {
    /**
     * No babel optional chains enabled in storybook's build for some reason
     */
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(registrationsArray => {
        if (registrationsArray.length) {
          registrationsArray[0].update();
        }
      });
    }
  }, [location.pathname, location.search]);

  return null;
};
