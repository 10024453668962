import GenericStore from '../classes/generic-store';
import { CandidateApi } from '../api/candidate/candidate';
import { filtersShape } from '../models/search-filters';
import {
  WHITELISTED_COUNTRY_CODES,
  EXCLUDED_PRACTICE_AREAS,
} from '../utils/talent-search-filter-util';

class PreloadedSearchFiltersStoreClass extends GenericStore {
  load() {
    return this.setState(
      CandidateApi.readCandidateFilters().then(({ data: filters }) => {
        return {
          ...filters,
          practiceArea: filters?.practiceArea?.filter(
            ({ name }) => !EXCLUDED_PRACTICE_AREAS.includes(name)
          ),
          addressCountryCode: filters?.addressCountryCode?.filter(acc =>
            WHITELISTED_COUNTRY_CODES.has(acc.id)
          ),
        };
      })
    );
  }

  getDataShape() {
    return filtersShape;
  }

  selectData(state) {
    return this.select(state)?.data || {};
  }
}

export const PreloadedSearchFiltersStore =
  new PreloadedSearchFiltersStoreClass();
