import qs from 'qs';

export const QueryParamUtil = {
  getQueryParams() {
    const query = qs.parse(window.location.search.slice(1));
    const keys = Object.keys(query);

    return keys.reduce((acc, curVal) => {
      switch (curVal) {
        case 'account':
          acc.accountId = query[curVal];
          break;
        case 'submission':
          acc.submissionId = query[curVal];
          break;
        default:
          acc[curVal] = query[curVal];
      }

      return acc;
    }, {});
  },
};
