import React from 'react';
import { bool, number, func, string } from 'prop-types';
import { Brief } from '@axiom/ui';

import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';

import { TSFCGTemplate } from './TSFCGTemplate';

export const TSFCheckboxGroup = ({
  data,
  disabled,
  fireSubmit,
  parentIndex,
  groupingName,
}) => {
  return (
    <Brief
      name="SHOW_MORE_LESS_CHECKBOX"
      position="left"
      briefMaxChildren={data.alwaysShow.length + 1}
      pattern="secondary"
    >
      {data.alwaysShow.map((item, index) => (
        <div key={JSON.stringify(item.id)}>
          <TSFCGTemplate
            item={item}
            index={index}
            parentIndex={parentIndex}
            disabled={disabled}
            onChange={fireSubmit}
            area="alwaysShow"
            groupingName={groupingName}
            key={JSON.stringify(item.id)}
          />
        </div>
      ))}
      {data.showHide.map((item, index) => (
        <div key={JSON.stringify(item.id)}>
          <TSFCGTemplate
            key={JSON.stringify(item.id)}
            item={item}
            index={index}
            parentIndex={parentIndex}
            disabled={disabled}
            onChange={fireSubmit}
            area="showHide"
            groupingName={groupingName}
          />
        </div>
      ))}
    </Brief>
  );
};

TSFCheckboxGroup.defaultProps = {
  groupingName: null,
};
TSFCheckboxGroup.propTypes = {
  disabled: bool.isRequired,
  data: FormTalentSearchFiltersStore.getDataShape().isRequired,
  fireSubmit: func.isRequired,
  parentIndex: number.isRequired,
  groupingName: string,
};
