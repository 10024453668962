import { CandidateAccountConst } from '@axiom/const';

import ApiHelper from '../../lib/api-helper';

const api = new ApiHelper('ACCOUNTS_CANDIDATES');

export const getAccountCandidates = async accountId => {
  const { data } = await api.GET(`/accounts/${accountId}/candidates`, {
    expand: [CandidateAccountConst.Expansions.PracticeAreaInfo],
  });

  return data;
};

export const patchAccountsCandidates = async (accountId, candidateId, body) => {
  const { data } = await api.PATCH(
    `/accounts/${accountId}/candidates/${candidateId}`,
    body
  );
  return data;
};
