import React, { useEffect, useRef } from 'react';
import { useApi, PDFExport, SubmissionPdf, StringUtil } from '@axiom/ui';
import { useParams } from 'react-router-dom';
import { AccountConst } from '@axiom/const';

import { DateUtil } from '../../utils/date-util';
import { AccountSubmissionsApi } from '../../api/account/account-submissions';
import { AccountLegacyApi } from '../../api/account/account-legacy';
import { AccountSubmissionsLegacyApi } from '../../api/account/account-submissions-legacy';
import { PracticeAreaApi } from '../../api/practiceAreas';
import { CandidateApi } from '../../api/candidate/candidate';
import { TaxonomyApi } from '../../api/taxonomy/taxonomy';
import { EnvUtil } from '../../utils/env-util';

import { PDFWrapper } from './SubmissionPdfDownloadStyles';

export const SubmissionPdfDownload = () => {
  const { accountId, submissionId } = useParams();
  const pdfRoot = useRef(null);
  const [
    { data: account },
    { data: submission },
    { data: practiceAreas },
    { data: taxonomy },
  ] = useApi(
    AccountLegacyApi.getAccount(accountId, {
      expand: [AccountConst.Expansions.accountTeamUserInfo],
    }),
    AccountSubmissionsApi.readAccountSubmissionDetails(
      accountId,
      submissionId,
      { ctaFilters: ['None', 'Hire', 'Interview'] }
    ),
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );

  const candidateIds = submission?.positions?.reduce((crnt, pos) => {
    pos.candidateOpportunities.forEach(co => {
      crnt.push(co.candidate.id);
    });

    return crnt;
  }, []);

  const candidates = useApi(
    ...candidateIds.map(candidateId =>
      AccountSubmissionsLegacyApi.readCandidateAccountSubmissionDetails(
        accountId,
        submissionId,
        candidateId
      )
    )
  ).map(response => response.data);

  const allGroupedExperiences = useApi(
    ...candidateIds.map(candidateId =>
      CandidateApi.readCandidateGroupedExperiences(candidateId, {
        submissionId,
      })
    )
  ).map(response => response.data);

  const cleanCompanyName = StringUtil.cleanStringForFileName(
    submission.jobName
  );
  const fileName = `Axiom Matches_${cleanCompanyName}_${DateUtil.formatAsDate(
    new Date()
  )}.pdf`;

  useEffect(() => {
    if (pdfRoot?.current?.save) {
      setTimeout(() => pdfRoot.current.save(), 500);
    }
  }, [pdfRoot]);

  return (
    <PDFWrapper data-test="HIDE_RENDERING">
      <PDFExport
        fileName={fileName}
        ref={pdfRoot}
        scale={0.5}
        margin={{ top: '0in', right: '0in', bottom: '0in', left: '0in' }}
      >
        <SubmissionPdf
          account={account}
          submission={submission}
          candidates={candidates}
          allGroupedExperiences={allGroupedExperiences}
          practiceAreas={practiceAreas}
          talentFinderUrl={EnvUtil.hubspotTalentFinder}
          clientUrl={EnvUtil.clientEngagementsUrl}
          loginUrl={EnvUtil.loginUrl}
          taxonomy={taxonomy}
        />
      </PDFExport>
    </PDFWrapper>
  );
};
