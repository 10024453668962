// Needs to be refactored
// eslint-disable-next-line no-restricted-syntax
import {
  arrayOf,
  bool,
  exact,
  oneOfType,
  shape,
  string,
  number,
} from 'prop-types';

// These are for the api shape ==== START
export const singleFilterShape = exact({
  id: oneOfType([
    string,
    number,
    shape({
      start: number,
      end: number,
    }),
  ]),
  name: string,
});

export const filterCategoryShape = arrayOf(singleFilterShape);

export const filtersShape = shape({
  profession: filterCategoryShape,
  yearsOfExperience: filterCategoryShape,
  addressCountryCode: filterCategoryShape,
  practiceArea: filterCategoryShape,
  industries: filterCategoryShape,
  state: filterCategoryShape,
  barredLocations: filterCategoryShape,
  offer: filterCategoryShape,
});
// These are for the api shape ==== END

// These are for the UI usages shapes  ==== START
export const itemShape = shape({
  name: string,
  id: oneOfType([
    string,
    number,
    shape({
      start: number,
      end: number,
    }),
  ]),
  checked: bool,
});

const itemsShape = exact({
  alwaysShow: arrayOf(itemShape),
  showHide: arrayOf(itemShape),
});

export const searchFiltersAllItemsShape = oneOfType([
  itemsShape,
  exact({
    unitedStates: itemsShape,
    canada: itemsShape,
    europe: itemsShape,
    apac: itemsShape,
    other: itemsShape,
  }),
]);

export const searchFiltersFullShape = shape({
  categories: arrayOf(
    shape({
      name: string,
      title: string,
      isOpen: bool,
      selectedCount: number,
      items: searchFiltersAllItemsShape,
    })
  ),
});
// These are for the UI usages shapes  ==== END

export type SearchFilters = {
  practiceAreaId?: string[];
  industries?: string[];
  yearsOfExperience?: {
    start: string | number;
    end: string | number;
  }[];
  addressCountryCode?: string[];
  state?: string[];
  profession?: string[];
  weeklyAvailability?: string[];
  barredLocations?: string[];
  offer?: string[];
  excludedCandidateIds?: string[];
};

export type SearchFiltersMeta = {
  page: number;
  search?: string;
  sort?: string;
  filters: SearchFilters;
  pageSize?: number;
};
