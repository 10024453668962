import GenericStore from '../classes/generic-store';
import { CandidateApi } from '../api/candidate/candidate';
import { CandidateAccountShape } from '../models/candidate';

class ExternalCandidateStoreClass extends GenericStore {
  load(id) {
    return this.setState(CandidateApi.readCandidate(id));
  }

  getDataShape() {
    return CandidateAccountShape;
  }

  selectData(state) {
    return this.select(state)?.data || {};
  }
}

export const ExternalCandidateStore = new ExternalCandidateStoreClass();
