/* eslint-disable max-classes-per-file */
import { GenericDto } from './generic-dto';

const FiltersDto = class FiltersDto extends GenericDto {
  constructor(body) {
    super(body, [
      'addressCountryCode',
      'state',
      'profession',
      'barredLocations',
      'industries',
      'occupationType',
      'practiceAreaId',
      'weeklyAvailabilityLabel',
      'yearsOfExperience',
      'excludedCandidateIds',
    ]);
  }
};

const RootDto = class RootDto extends GenericDto {
  constructor(body) {
    super(body, [
      'page', // page is not part of the validator but is needed for the request
      'pageSize',
      'search',
      'sort',
    ]);
  }
};

export const ExternalSearchRequestDto = class ExternalSearchRequestDto {
  constructor(body) {
    const filters = new FiltersDto(body.filters).export();
    const objectRoot = new RootDto(body).export();

    this.data = {
      filters,
      ...objectRoot,
    };
  }

  export() {
    return this.data;
  }
};
