import { arrayOf, bool, shape, string } from 'prop-types';
import { CandidateImageConst, TalkConst } from '@axiom/const';

import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import { postAccessOurNetworkInquireWithAxiom } from '../api/hubspot/hubspot';
import { accountModel } from '../models/user';
import { CandidateUtil } from '../utils/candidate-util';
import { HubspotUtil } from '../utils/hubspot-util';

const { PossibleImageSizes } = CandidateImageConst;

const FormInquireWithAxiomModalValidation = AjvUtil.compileSchema({
  required: ['firstName', 'lastName', 'email', 'requestType', 'accountName'],
  properties: {
    firstName: {
      title: 'First Name',
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    lastName: {
      title: 'Last Name',
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    email: {
      title: 'Email',
      type: 'string',
      format: 'email',
      minLength: 1,
      maxLength: 255,
      errorMessage: 'Please enter a valid email',
    },
    phoneNumber: {
      title: 'Phone Number',
      basicPhoneNumber: 'phoneNumber',
    },
    workDescription: {
      title: 'Work Description',
      type: ['string', 'null'],
      maxLength: 1000,
    },
    candidateId: {
      title: 'Candidate UUID',
      type: 'string',
    },
    createAccount: {
      title: 'Create an Axiom account',
      type: 'boolean',
    },
    requestType: {
      title: 'Request Type',
      type: 'string',
      enum: [TalkConst.requestType.Talk, TalkConst.requestType.Inquire],
      errorMessage: 'Must select a request type',
    },
    accountName: {
      title: 'Account',
      type: 'string',
      errorMessage: 'Must select an account',
      minLength: 1,
    },
  },
});

class FormInquireWithAxiomModalStoreClass extends FormStore {
  getDataShape() {
    return shape({
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string,
      accounts: arrayOf(accountModel),
      workDescription: string,
      candidateId: string,
      candidateName: string,
      candidatePhotoURL: string,
      createAccount: bool,
      requestType: string,
      accountName: string,
    });
  }

  load(candidate, clientUser) {
    return this.setState({
      firstName: clientUser?.firstName,
      lastName: clientUser?.lastName,
      email: clientUser?.email,
      phoneNumber: null,
      accounts: clientUser?.contact?.accounts,
      workDescription: null,
      candidateId: candidate.id,
      candidateFirstName: candidate.calculatedFirstName,
      candidateName: candidate.calculatedDisplayName,
      candidatePhotoURL: CandidateUtil.getProfileImageUri(
        candidate,
        PossibleImageSizes.W_100
      ),
      createAccount: false,
      requestType: TalkConst.requestType.Talk,
      accountName: this.getAccountInformation(clientUser?.contact?.accounts),
      isBookDirectOffer: candidate.isBookDirectOffer || false,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      FormInquireWithAxiomModalValidation,
      formData
    );
  }

  submit(changedFields, formData) {
    const dataUTM = HubspotUtil.parseURLDataUtm();
    const utmArr = Object.entries(dataUTM).map(entry => ({
      name: entry[0],
      value: entry[1],
    }));

    const data = {
      fields: [
        { name: 'firstname', value: formData.firstName },
        { name: 'lastname', value: formData.lastName },
        { name: 'email', value: formData.email },
        {
          name: 'axiom_for_clients_account_display_name',
          value: formData.accountName,
        },
        { name: 'work_description', value: formData.workDescription || 'N/A' },
        { name: 'axiom_for_clients_candidate_id', value: formData.candidateId },
        {
          name: 'i_have_read_and_agree_to_the_privacy_policy_',
          value: true,
        },
        { name: 'create_account', value: formData.createAccount },
        { name: 'request_type', value: formData.requestType },
        {
          name: 'book_direct_offer',
          value:
            formData.requestType === 'Talk' && formData.isBookDirectOffer
              ? 'eligible'
              : 'ineligible',
        },
        ...utmArr,
      ],
    };

    if (formData.phoneNumber) {
      data.fields.push({
        name: 'phone',
        value: formData.phoneNumber.replace(/[+() -]/g, ''),
      });
    }

    return postAccessOurNetworkInquireWithAxiom(data);
  }

  getAccountInformation(accounts) {
    let accountInformation;
    if (!accounts || accounts?.length === 0) {
      accountInformation = 'N/A';
    }
    if (accounts?.length === 1) {
      accountInformation = accounts[0].name;
    }
    if (accounts?.length > 1) {
      accountInformation = null;
    }
    return accountInformation;
  }
}

export const FormInquireWithAxiomModalStore =
  new FormInquireWithAxiomModalStoreClass();
