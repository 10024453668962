import React from 'react';
import qs from 'qs';
import {
  Card,
  CardSection,
  Layout,
  SectionHeader,
  PageHeader,
  Flashy,
  Button,
  Gutter,
  Grid,
  GridRow,
  GridColumn,
  colors,
} from '@axiom/ui';
import { Account } from '@axiom/validation';

import EndCardImage from '../../public/images/DiscoverTransparentBkg.png';

export const DiscoverTalentCarouselEndCard = ({
  account,
}: {
  account: Account;
}) => {
  return (
    <Card stretched name="DISCOVER_TALENT_CAROUSEL_END_CARD">
      <CardSection
        background={{
          image: EndCardImage,
          position: 'right center',
          color: colors?.controlHoverPrimary,
          size: 'contain',
        }}
      >
        <Grid>
          <GridRow>
            <GridColumn largeScreenWidth={8} smallScreenWidth={12}>
              <Layout
                direction="vertical"
                position="left"
                verticalGutter="16px"
              >
                <PageHeader>
                  <Flashy bold color="textReversed">
                    Go <br /> Further.
                  </Flashy>
                </PageHeader>
                <SectionHeader>
                  <Flashy color="textReversed">
                    Browse more of Axiom’s top legal talent
                  </Flashy>
                </SectionHeader>
              </Layout>
              <Gutter bottom="24px" />
              <Button
                name="BROWSE_TALENT_BUTTON"
                to={`/access-legal-talent/page/1${qs.stringify(
                  { industries: [account?.industryValue] },
                  {
                    addQueryPrefix: true,
                  }
                )}`}
              >
                Browse Talent
              </Button>
            </GridColumn>
            <GridColumn />
          </GridRow>
        </Grid>
      </CardSection>
    </Card>
  );
};
