import styled from 'styled-components';

import { GRAYS } from '../../themes/constants';

export const InfoPopoverWrapper = styled.div`
  color: ${({ color }) => color || 'inherit'};
  display: inline-block;

  button,
  button:hover {
    background-color: transparent;
    border: none;
    color: #7d8694;
    line-height: 1;
    padding: 0;
    cursor: pointer;
  }
`;

export const InfoPopoverCircle = styled.span`
  background-color: ${GRAYS.white};
  border-radius: 50%;
  color: ${GRAYS.gray11};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
`;
