import { useParams } from 'react-router-dom';
import { useApiWithErrors, GoneError, ApiError, useApi } from '@axiom/ui';
import { Account, AfcSubmission } from '@axiom/validation';
import { PositionsConst, RolesType } from '@axiom/const';

import { AccountSubmissionsApi } from '../../api/account/account-submissions';
import { CandidateApi } from '../../api/candidate/candidate';

interface Args {
  account?: Account;
  submissionId?: AfcSubmission['id'];
}
const { Roles } = PositionsConst;
export const roleDisplayOrder = [
  Roles.teamLead,
  Roles.teamSecondment,
  Roles.iSecondment,
  Roles.counsel,
  Roles.other,
];

export const sortByObject = roleDisplayOrder.reduce((obj, item, index) => {
  return {
    ...obj,
    [item]: index,
  };
}, {} as Record<RolesType, number>);

export const useClientEngagementsCurrentSubmissionData = (props: Args = {}) => {
  const params = useParams();
  const talentSlug = params?.talentSlug;
  const urlSubmissionId = params?.submissionId;
  const urlAccountId = params?.accountId;
  const accountId = props?.account?.id ?? urlAccountId;
  const submissionId = props?.submissionId ?? urlSubmissionId;
  const [response] = useApiWithErrors(
    accountId &&
      submissionId &&
      AccountSubmissionsApi.readAccountSubmissionDetails(
        accountId,
        submissionId,
        { ctaFilters: ['None', 'Hire'] }
      )
  );
  let invalidSubmissionRequest = false;

  if (response instanceof ApiError) {
    if (response instanceof GoneError) {
      invalidSubmissionRequest = true;
    } else {
      throw response;
    }
  }
  const currentSubmission = response?.data;
  const matchesCount = currentSubmission?.positions
    .map(position => position.candidateOpportunities)
    .flat()?.length;

  const sortedPositions = currentSubmission?.positions.sort(
    (a, b) => sortByObject[a.role] - sortByObject[b.role]
  );

  const filterPositionByRole = (role: RolesType) =>
    currentSubmission?.positions
      .filter(position => position.role === role)
      .map(position => position.candidateOpportunities)
      .flat();

  const groupedCandidateOpps = roleDisplayOrder
    .map(order => ({
      title: order,
      data: filterPositionByRole(order),
    }))
    .filter(item => item?.data?.length > 0);

  const currentSubmissionCandidateWithSlug =
    talentSlug &&
    sortedPositions
      ?.find(p =>
        p?.candidateOpportunities?.find(
          candidateOpp => candidateOpp?.candidate?.id === talentSlug
        )
      )
      ?.candidateOpportunities.find(cOpp => cOpp.candidate.id === talentSlug);

  const currentSubmissionCandidateToDisplay =
    currentSubmissionCandidateWithSlug ??
    sortedPositions?.find(position => position?.candidateOpportunities?.length)
      ?.candidateOpportunities?.[0];

  const [{ data: groupedExperiences } = { data: [] }] = useApi(
    currentSubmissionCandidateToDisplay?.candidate?.id &&
      CandidateApi.readCandidateGroupedExperiences(
        currentSubmissionCandidateToDisplay.candidate.id,
        { submissionId }
      )
  );

  return {
    accountId,
    currentSubmission,
    currentSubmissionCandidateToDisplay,
    currentSubmissionCandidateWithSlug,
    groupedExperiences,
    groupedCandidateOpps,
    invalidSubmissionRequest,
    isDetailsViewUrl: talentSlug && currentSubmissionCandidateWithSlug,
    sortedPositions,
    submissionId,
    talentSlug,
    matchesCount,
  };
};
