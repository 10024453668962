import { Api } from '@axiom/ui';
import { PracticeArea } from '@axiom/validation';

import APIHelper from '../lib/api-helper';
import { EnvUtil } from '../utils/env-util';

const api = new APIHelper('practice area');

export const getPracticeAreas = async () => {
  const { data } = await api.GET('/practiceAreas');
  return { data };
};

class PracticeAreaApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readPracticeAreas() {
    return super.read<{ data: PracticeArea[] }>({
      endpoint: '/practiceAreas',
      method: 'GET',
    });
  }
}

export const PracticeAreaApi = new PracticeAreaApiClass();

export default {
  getPracticeAreas,
};
