/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { string, func, oneOfType, shape, number, bool } from 'prop-types';
import { Field } from 'react-final-form';
import { Label } from '@axiom/ui';

import {
  FormElementError,
  isErroring,
} from '../FormElementError/FormElementError';
import { FlexWrapper } from '../../../themes/components';
import { DropdownOptionsShape } from '../../../models/dropdown-options';

import {
  SingleSelectWrapper,
  KendoDropDownListWrapper,
} from './SingleSelectStyles';

export const RawSingleSelect = ({
  name,
  options,
  displayKeyProp,
  valueKeyProp,
  disabled,
  onBlur,
  onChange,
  onFocus,
  className,
  input,
  meta,
  valueRender,
}) => {
  // TODO: deprecate this.
  const useKeyProps = displayKeyProp && valueKeyProp;

  const OptionComponent = (li, itemProps) => {
    const dataValue = useKeyProps
      ? itemProps.dataItem[valueKeyProp]
      : itemProps.dataItem;

    const liProps = {
      ...li.props,
      'data-value': dataValue,
    };

    const itemChildren = <span>{li.props.children}</span>;

    return React.cloneElement(li, liProps, itemChildren);
  };

  const handleOnChange = event => {
    const value = useKeyProps ? event.value[valueKeyProp] : event.value;

    input.onChange(value);
    onChange(value);
  };

  const handleOnBlur = event => {
    const value = useKeyProps ? event.value?.valueKeyProp || null : event.value;

    input.onBlur(event);
    onBlur(value);
  };

  const handleOnFocus = event => {
    const value = useKeyProps ? event.value?.valueKeyProp || null : event.value;

    input.onFocus(event);
    onFocus(value);
  };

  const getValue = () => {
    // Kendo Dropdown requires reference to one of the options as the value if
    // the options are an array of object
    if ((input.value || input.value === '') && useKeyProps) {
      return options.find(option => option[valueKeyProp] === input.value);
    }

    return input.value;
  };

  // displayKeyProp and valueKeyProp are optional
  // we do not want to set them directly on the dropdown, since they are incompatible
  // with pimitive arrays. Should only be used when the options are an array
  // of objects
  const getOptionalProps = () => {
    const keyProps = {};

    if (displayKeyProp) {
      keyProps.textField = displayKeyProp;
    }

    if (valueKeyProp) {
      keyProps.dataItemKey = valueKeyProp;
    }

    if (valueRender) {
      keyProps.valueRender = valueRender;
    }

    return keyProps;
  };

  return (
    <>
      <SingleSelectWrapper
        disabled={disabled}
        data-test={`${name}_SINGLESELECT`}
        className={className}
        data-value={input.value}
      >
        <KendoDropDownListWrapper
          name={name}
          data={options}
          disabled={disabled}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          value={getValue()}
          itemRender={OptionComponent}
          popupSettings={{ animate: false }}
          {...getOptionalProps()}
        />
      </SingleSelectWrapper>
      {isErroring(meta) && (
        <FlexWrapper>
          <FormElementError finalFormElementMeta={meta} />
        </FlexWrapper>
      )}
    </>
  );
};

RawSingleSelect.propTypes = {
  name: string.isRequired,
  options: DropdownOptionsShape.isRequired,
  // use specified prop in an array of objects to display to the user
  displayKeyProp: string,
  // use specified prop in an array of objects as the value in the select
  valueKeyProp: string,
  disabled: bool,
  onChange: func,
  onBlur: func,
  onFocus: func,
  className: string,
  input: shape({
    value: oneOfType([string, number, shape({})]),
    name: string,
    onChange: func,
    onBlur: func,
    onFocus: func,
  }).isRequired,
  meta: shape({}).isRequired,
  valueRender: func,
};

RawSingleSelect.defaultProps = {
  displayKeyProp: null,
  valueKeyProp: null,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  className: null,
  valueRender: null,
};

export const SingleSelect = ({
  name,
  label,
  options,
  displayKeyProp,
  valueKeyProp,
  disabled,
  onChange,
  onBlur,
  onFocus,
  className,
  valueRender,
}) => {
  const renderLabel = () => {
    if (!label) {
      return null;
    }

    return typeof label === 'string' ? <Label>{label}</Label> : label;
  };

  const filteredOptions = options.filter(
    option => !(option === null || typeof option === 'undefined')
  );

  return (
    <>
      {renderLabel()}
      <Field name={name}>
        {fieldProps => (
          <RawSingleSelect
            input={fieldProps.input}
            meta={fieldProps.meta}
            name={name}
            options={filteredOptions}
            displayKeyProp={displayKeyProp}
            valueKeyProp={valueKeyProp}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            className={className}
            valueRender={valueRender}
          />
        )}
      </Field>
    </>
  );
};

SingleSelect.propTypes = {
  name: string.isRequired,
  label: oneOfType([string, shape(Label.propTypes)]),
  options: DropdownOptionsShape.isRequired,
  // use specified prop in an array of objects to display to the user
  displayKeyProp: string,
  // use specified prop in an array of objects as the value in the select
  valueKeyProp: string,
  disabled: bool,
  onChange: func,
  onBlur: func,
  onFocus: func,
  className: string,
  valueRender: func,
};

SingleSelect.defaultProps = {
  label: null,
  displayKeyProp: 'label',
  valueKeyProp: 'value',
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  className: null,
  valueRender: null,
};
