import React from 'react';
import {
  Button as SemanticButton,
  ButtonProps,
  Popup,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const RawButtonPatterns = {
  primary: 'primary',
  secondary: 'secondary',
  brand: 'brand',
} as const;

export const RawButtonSizes = {
  inline: 'inline',
  small: 'small',
  large: 'large',
} as const;

export const RawButtonVariations = {
  filled: 'filled',
  outline: 'outline',
  minimal: 'minimal',
} as const;

export type RawButtonPattern =
  typeof RawButtonPatterns[keyof typeof RawButtonPatterns];
export type RawButtonVariation =
  typeof RawButtonVariations[keyof typeof RawButtonVariations];

export type RawButtonProps = {
  fluid?: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  Icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  id?: string;
  inverse?: boolean;
  name?: string;
  onClick?: (
    event?: React.MouseEvent<
      HTMLButtonElement | HTMLAnchorElement | HTMLElement | HTMLFormElement
    >
  ) => void;
  pattern?: RawButtonPattern;
  tabIndex?: number;
  title?: string;
  to?: string;
  toTab?: string;
  type?: 'button' | 'submit';
  variation?: RawButtonVariation;
};

export const RawButton = ({
  fluid = false,
  children = null,
  className = '',
  disabled = false,
  Icon = null,
  iconPosition = 'right',
  id = null,
  inverse = false,
  name = null,
  onClick = () => {},
  pattern = RawButtonPatterns.primary,
  title,
  tabIndex,
  to = null,
  toTab = null,
  type = 'button',
  variation = RawButtonVariations.filled,
}: RawButtonProps) => {
  const attrs: ButtonProps = {
    className: AttrsHelper.formatClassname(
      className,
      fluid && 'fluid',
      inverse && 'inverse',
      iconPosition && `icon-position-${iconPosition}`,
      pattern,
      variation
    ),
    disabled,
    'data-test': name,
    id,
    onClick,
    tabIndex,
    type,
  };

  const linkTo = to || toTab;
  if (linkTo) {
    /* eslint-disable react/jsx-props-no-spreading */
    attrs.as = ({
      children: propChildren,
      ...rest
    }: RawButtonProps & {
      target?: string;
      rel?: string;
    }) => {
      if (toTab) {
        rest.target = '_blank';
        rest.rel = 'noopener noreferrer';
      }

      /**
       * We only want to use the Link component if the link sent in isn't external facing
       * that's why we conditionally pick between an <a tag and <Link
       *
       * Otherwise, if you try to do <Link to="http://google.com" /> React router
       * thinks you're trying to call a page in the router.
       */
      if (
        ['http', 'mailto'].some(value => linkTo.toLowerCase().startsWith(value))
      ) {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return (
          <a {...rest} href={linkTo} title={title}>
            {propChildren}
          </a>
        );
      }

      return (
        <Link {...rest} to={linkTo} title={title}>
          {propChildren}
        </Link>
      );
    };
    // it was either this or an eslint-disable
    attrs.as.displayName = 'RawButtonLinkOrAnchor';
    /* eslint-enable react/jsx-props-no-spreading */
  }

  const Button = React.createElement(
    SemanticButton,
    attrs,
    <>
      {iconPosition === 'left' && Icon}
      {children}
      {iconPosition === 'right' && Icon}
    </>
  );
  return title ? (
    <Popup on="hover" trigger={Button}>
      {title}
    </Popup>
  ) : (
    Button
  );
};
