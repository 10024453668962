import React from 'react';
import {
  Gutter,
  Button,
  Grid,
  GridRow,
  GridColumn,
  PageHeader,
  Tabs,
  Tab,
  Paragraph,
  EmptyLayout,
  SmallHeader,
  CardSection,
  CardFooter,
  Card,
} from '@axiom/ui';
import { Submission } from '@axiom/validation';

import EmptyEngagementGraphic from '../../public/svg/empty-engagement.svg';
import { EnvUtil } from '../../utils/env-util';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useSubmissionsData } from '../../hooks/useSubmissionsData';

import { ClientEngagementsCardButton } from './ClientEngagementsCardButton';

export const ClientEngagementsDashboard = (): JSX.Element => {
  const { account } = useUserAccountData();
  const { allActiveSubmissions, allUpcomingSubmissions } = useSubmissionsData({
    account,
  });
  const upcomingTab = 'Upcoming Tab';
  const activeTab = 'Active Tab';
  const startingTab =
    allActiveSubmissions.length && !allUpcomingSubmissions.length
      ? activeTab
      : upcomingTab;

  return (
    <div data-test="ALL_ENGAGEMENTS">
      <Grid stackable verticalAlign="middle">
        <GridRow>
          <GridColumn largeScreenWidth={8} tabletWidth={8} mobileWidth={12}>
            <PageHeader>Engagements</PageHeader>
            <Gutter bottom="16px" />
          </GridColumn>
          <GridColumn
            largeScreenWidth={4}
            tabletWidth={4}
            mobileWidth={12}
            textAlign="right"
          >
            <Gutter bottom="16px" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn>
            <Tabs startTab={startingTab}>
              <Tab
                label="Upcoming"
                name={upcomingTab}
                countBadge={allUpcomingSubmissions.length}
              >
                <Gutter top="16px">
                  {allUpcomingSubmissions.length > 0 ? (
                    allUpcomingSubmissions.map((submission: Submission) => (
                      <Gutter bottom="16px" key={submission.submissionId}>
                        <ClientEngagementsCardButton
                          submission={submission}
                          isUpcomingTab
                        />
                      </Gutter>
                    ))
                  ) : (
                    <Card>
                      <CardSection divider>
                        <EmptyLayout
                          graphic={
                            <EmptyEngagementGraphic
                              width="116px"
                              height="116px"
                            />
                          }
                          name="Empty-State-Card-Upcoming"
                        >
                          <SmallHeader>
                            Start an Axiom Engagement to see it here
                          </SmallHeader>
                          <Gutter bottom="16px" />
                          <Paragraph>
                            Engagements that you submit to Axiom will appear
                            here when your Axiom-curated matches are ready
                            (generally within 1-2 days) and until the work
                            begins.
                            <br />
                            <br />
                            Engagement missing? If an engagement is in progress
                            you'll find it in your Active engagements. If a
                            colleague shared an engagement with you, access it
                            from the link they provided.
                          </Paragraph>
                        </EmptyLayout>
                      </CardSection>
                      <CardFooter stackableOn="mobile">
                        <Button
                          name="AXIOM_SOLUTIONS_BUTTON"
                          toTab={EnvUtil.axiomSolutionsUrl}
                          variation="outline"
                          pattern="primary"
                        >
                          View Axiom Solutions
                        </Button>
                        <Button
                          name="NEW_ENGAGEMENTS_BUTTON"
                          toTab={EnvUtil.hubspotTalentFinder}
                          pattern="primary"
                        >
                          Start a new engagement
                        </Button>
                      </CardFooter>
                    </Card>
                  )}
                </Gutter>
              </Tab>

              <Tab
                label="Active"
                name={activeTab}
                countBadge={allActiveSubmissions.length}
              >
                <Gutter top="16px">
                  {allActiveSubmissions.length > 0 ? (
                    allActiveSubmissions.map((submission: Submission) => (
                      <Gutter bottom="16px" key={submission.submissionId}>
                        <ClientEngagementsCardButton submission={submission} />
                      </Gutter>
                    ))
                  ) : (
                    <Card>
                      <CardSection divider>
                        <EmptyLayout
                          graphic={
                            <EmptyEngagementGraphic
                              width="116px"
                              height="116px"
                            />
                          }
                          name="Empty-State-Card-Active"
                        >
                          <SmallHeader>
                            Find or start an Axiom engagement
                          </SmallHeader>
                          <Gutter bottom="16px" />
                          <Paragraph>
                            Your Axiom engagements will appear here when the
                            work begins until the engagement ends.
                            <br />
                            <br />
                            Engagement missing? If an engagement hasn't started
                            yet you’ll find it in your Upcoming engagements. If
                            a colleague shared an engagement with you, access it
                            from the link they provided.
                          </Paragraph>
                        </EmptyLayout>
                      </CardSection>
                      <CardFooter>
                        <Button
                          name="NEW_ENGAGEMENTS_BUTTON"
                          toTab={EnvUtil.hubspotTalentFinder}
                          pattern="primary"
                        >
                          Start a new engagement
                        </Button>
                      </CardFooter>
                    </Card>
                  )}
                </Gutter>
              </Tab>
            </Tabs>
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
};
