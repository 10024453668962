import React from 'react';
import { bool } from 'prop-types';
import {
  Visible,
  Card,
  CardHeader,
  CardSection,
  ParagraphHeader,
  Gutter,
} from '@axiom/ui';

import { TalentSearchFiltersForm } from '../TalentSearchFiltersForm/TalentSearchFiltersForm';

export const TalentSearchFilters = ({ disabled }) => {
  return (
    <Gutter bottom="1rem">
      <Visible only="largeScreen tablet">
        <Card>
          <CardHeader>
            <ParagraphHeader>Filters</ParagraphHeader>
          </CardHeader>
          <CardSection>
            <TalentSearchFiltersForm disabled={disabled} />
          </CardSection>
        </Card>
      </Visible>
    </Gutter>
  );
};

TalentSearchFilters.propTypes = {
  disabled: bool,
};

TalentSearchFilters.defaultProps = {
  disabled: false,
};
