import { shape, string } from 'prop-types';

import FormStore from '../classes/form-store';
import { AjvUtil } from '../utils/ajv-util';
import { postDigitalBenchRequestRate } from '../api/hubspot/hubspot';

const FormDigitalBenchRequestRateModalValidation = AjvUtil.compileSchema({
  required: [
    'firstName',
    'lastName',
    'email',
    'accountDisplayName',
    'candidateId',
    'description',
  ],
  properties: {
    firstName: {
      title: 'First Name',
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    lastName: {
      title: 'Last Name',
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
    email: {
      title: 'Email',
      type: 'string',
      format: 'email',
      minLength: 1,
      maxLength: 255,
    },
    accountDisplayName: {
      title: 'Account Display Name',
      type: 'string',
      minLength: 1,
    },
    candidateId: {
      title: 'Candidate UUID',
      type: 'string',
    },
    description: {
      title: 'Description',
      type: 'string',
    },
  },
});

class FormDigitalBenchRequestRateModalStoreClass extends FormStore {
  getDataShape() {
    return shape({
      firstName: string,
      lastName: string,
      email: string,
      candidateId: string,
      candidateName: string,
      accountDisplayName: string,
      description: string,
    });
  }

  load(candidate, userClient) {
    return this.setState({
      firstName: userClient.firstName,
      lastName: userClient.lastName,
      email: userClient.email,
      candidateName: candidate.calculatedDisplayName,
      accountDisplayName: userClient?.contact?.accounts?.[0]?.calculatedName,
      candidateId: candidate.id,
      description: undefined,
    });
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(
      FormDigitalBenchRequestRateModalValidation,
      formData
    );
  }

  async submit(changedFields, formData) {
    const data = {
      fields: [
        { name: 'firstname', value: formData.firstName },
        { name: 'lastname', value: formData.lastName },
        { name: 'email', value: formData.email },
        {
          name: 'axiom_for_clients_account_display_name',
          value: formData.accountDisplayName,
        },
        { name: 'axiom_for_clients_candidate_id', value: formData.candidateId },
        { name: 'description', value: formData.description },
      ],
    };

    let result;
    try {
      result = await postDigitalBenchRequestRate(data);
    } catch (e) {
      result = e;
    }
    return result;
  }
}

export const FormDigitalBenchRequestRateModalStore =
  new FormDigitalBenchRequestRateModalStoreClass();
