import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { Button, DataTestUtil, Gutter } from '@axiom/ui';
import { Trans } from '@lingui/macro';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { SubmissionStore } from '../../stores/submission-store';

import {
  SubmissionCardCandidate,
  SubmissionCardCandidates,
  SubmissionCardBody,
} from './SubmissionCardStyles';

const SubmissionCardDetailComponent = ({ download, submissionState }) => (
  <>
    <StoreStateTreatment
      allowOverflow
      name="ACCOUNT_SUBMISSIONS_CARD_DETAIL"
      storeState={submissionState}
      renderLoadedView={({ data: submission }) => {
        const mailto = submission.ownerUser
          ? `mailto:${submission.ownerUser.email}?subject=${encodeURIComponent(
              `Axiom Submission - ${submission.jobName}`
            )}`
          : null;

        return (
          <>
            {(mailto || download) && (
              <SubmissionCardBody>
                {mailto && (
                  <Gutter right="0.5rem" bottom="0.5rem">
                    <Button variation="outline" to={mailto} name="REPLY_LINK">
                      <Trans>Reply</Trans>
                    </Button>
                  </Gutter>
                )}

                {download && (
                  <Gutter>
                    <Button name="DOWNLOAD_PDF_BUTTON" onClick={download}>
                      <Trans>Download</Trans>
                    </Button>
                  </Gutter>
                )}
              </SubmissionCardBody>
            )}

            {submission.candidates.length > 0 && (
              <SubmissionCardBody>
                <SubmissionCardCandidates>
                  {submission.candidates.map(candidate => (
                    <SubmissionCardCandidate
                      key={candidate.id}
                      data-test={DataTestUtil.format('SUBMISSION_CANDIDATE')}
                    >
                      <a href={`#c-${candidate.id}`}>
                        {candidate.calculatedDisplayName}
                      </a>
                    </SubmissionCardCandidate>
                  ))}
                </SubmissionCardCandidates>
              </SubmissionCardBody>
            )}
          </>
        );
      }}
    />
  </>
);

SubmissionCardDetailComponent.defaultProps = {
  download: null,
};

SubmissionCardDetailComponent.propTypes = {
  download: func,
  submissionState: SubmissionStore.getDataShape().isRequired,
};

export const SubmissionCardDetail = connect(state => ({
  submissionState: SubmissionStore.select(state),
}))(SubmissionCardDetailComponent);
