import { stringify } from 'qs';
import { Api } from '@axiom/ui';
import { AfcSubmission } from '@axiom/validation';

import ApiHelper from '../../lib/api-helper';
import { EnvUtil } from '../../utils/env-util';

const api = new ApiHelper('ACCOUNTS_SUBMISSIONS');

export const getAccountSubmissions = async (accountId: string) => {
  const { data } = await api.GET(`/accounts/${accountId}/submissions`);
  return data;
};

class AccountSubmissionsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  readAccountSubmissionDetails(
    accountId: AfcSubmission['accountId'],
    submissionId: AfcSubmission['id'],
    body?: Record<string, unknown>
  ) {
    return super.read<{ data: AfcSubmission }>({
      endpoint: `/accounts/${accountId}/submissions/${submissionId}${stringify(
        body,
        { arrayFormat: 'comma', addQueryPrefix: true }
      )}`,
      method: 'GET',
    });
  }

  refreshAccountSubmissionDetails(
    accountId: AfcSubmission['accountId'],
    submissionId: AfcSubmission['id'],
    body?: Record<string, unknown>
  ) {
    return super.invalidate(
      `/accounts/${accountId}/submissions/${submissionId}${stringify(body, {
        arrayFormat: 'comma',
        addQueryPrefix: true,
      })}`
    );
  }
}

export const AccountSubmissionsApi = new AccountSubmissionsApiClass();
