import React from 'react';
import {
  Button,
  Card,
  CardSection,
  Carousel,
  Gutter,
  Layout,
  LayoutItem,
  SectionHeader,
} from '@axiom/ui';

import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useSubmissionsData } from '../../hooks/useSubmissionsData';

import { EngagementCarouselCardButton } from './EngagementCarouselCardButton';
import { EngagementCarouselEndCard } from './EngagementCarouselEndCard';

type EngagementCarouselType = {
  maxDisplayCount?: number;
};

export const EngagementsCarousel = ({
  maxDisplayCount = 8,
}: EngagementCarouselType) => {
  const { account } = useUserAccountData();
  const { allSubmissions } = useSubmissionsData({
    account,
  });
  const submissions =
    allSubmissions.length > maxDisplayCount
      ? allSubmissions.slice(0, maxDisplayCount)
      : allSubmissions;

  const buildChildren = () => {
    const kids = submissions.map(submission => {
      return (
        <EngagementCarouselCardButton
          submission={submission}
          key={`${submission.accountId}-${submission.submissionId}`}
        />
      );
    });
    kids.push(<EngagementCarouselEndCard key="endCard" />);

    return kids;
  };

  return (
    <Card name="ENGAGEMENTS_CAROUSEL_CONTAINER">
      <CardSection background="light blue">
        <Layout>
          <LayoutItem fluid>
            <SectionHeader name="HEADER">
              Recent engagements ({submissions.length})
            </SectionHeader>
          </LayoutItem>
          <LayoutItem>
            <Button
              to="./engagements"
              pattern="secondary"
              variation="minimal"
              name="VIEW_ENGAGEMENTS"
            >
              View all engagements
            </Button>
          </LayoutItem>
        </Layout>
        <Gutter bottom="24px" />
        <Carousel name="ENGAGEMENTS_CAROUSEL">{buildChildren()}</Carousel>
      </CardSection>
    </Card>
  );
};
