import React from 'react';
import {
  Button,
  Card,
  CardFooter,
  CardSection,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  SmallHeader,
} from '@axiom/ui';

import RocketShip from '../../public/svg/rocket-ship.svg';

export const EngagementCarouselEndCard = () => {
  return (
    <Card name="ENGAGEMENT_CAROUSEL_END_CARD" stretched>
      <CardSection>
        <Layout>
          <LayoutItem rightGutter="24px">
            <RocketShip width="117px" height="117px" />
          </LayoutItem>
          <LayoutItem>
            <SmallHeader>Start a new engagement</SmallHeader>
            <Gutter bottom="16px" />
            <Paragraph>
              We will walk you through finding talent that matches your legal
              needs.
            </Paragraph>
          </LayoutItem>
        </Layout>
      </CardSection>
      <CardFooter>
        <Button
          name="END_CARD_START_ENGAGEMENT"
          to="https://www.axiomlaw.com/talent-finder"
          pattern="secondary"
        >
          Start an Engagement
        </Button>
      </CardFooter>
    </Card>
  );
};
