import qs from 'qs';
import crypto from 'crypto-js/sha256';

import { EnvUtil } from './env-util';
import { QueryParamUtil } from './query-param-util';

let pendoInitialized = false;

export const WindowUtil = {
  getLoginLink() {
    const baseUrl = EnvUtil.loginUrl;
    const relayString = `?RelayState=${this.getRelayString()}`;

    return `${baseUrl}/client-login${relayString}`;
  },
  toLogin() {
    window.location.href = WindowUtil.getLoginLink();
  },
  exists() {
    /**
     * try/catch because next.js will throw error if trying to access window on the server >.<
     */
    try {
      return !!window;
    } catch (e) {
      return false;
    }
  },
  getUrlQuery() {
    return qs.parse(window.location.search.slice(1));
  },
  getUrlSearchParams() {
    return new URLSearchParams(
      qs.stringify(
        qs.parse(window.location.search.slice(1), { addQueryPrefix: true })
      )
    );
  },
  setUrlQuery(paramsObj, replace) {
    let hostUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

    const search = paramsObj && qs.stringify(paramsObj);
    if (search) {
      hostUrl += `?${search}`;
    }

    if (window.location.hash) {
      hostUrl += window.location.hash;
    }

    const method = replace ? 'replaceState' : 'pushState';
    if (window.history && window.history[method]) {
      window.history[method]({ path: hostUrl }, '', hostUrl);
    }
  },
  initializePendo({ user = {} }) {
    if (WindowUtil.exists() && window.pendo && !pendoInitialized) {
      pendoInitialized = true;
      /**
       * Pendo doesnt init every reload without the delay
       */
      const role = user?.roles?.[0] || null;
      const userId = user.id;

      const account = QueryParamUtil.getQueryParams().accountId
        ? user?.contact?.accounts?.find(
            acc => acc.id === QueryParamUtil.getQueryParams().accountId
          )
        : user?.contact?.accounts?.[0];

      setTimeout(() => {
        const payload = {
          visitor: {
            id: userId ? crypto(userId).toString() : null,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            apUserId: user?.id,
            role,
          },
          account: {
            id: account?.id || 'AxiomLaw',
            name: account?.calculatedName || 'AxiomLaw',
            salesforceId: account?.salesforceId,
            creationDate: account?.createdAt,
          },
        };
        // eslint-disable-next-line no-console
        console.log('Initing Pendo As:', payload.visitor, payload.account);
        window.pendo.initialize(payload);
      }, 1000);
    }
  },
  getRelayString() {
    return encodeURIComponent(
      window.location.host +
        window.location.pathname +
        window.location.search +
        window.location.hash
    );
  },

  pendoTrack(name, data) {
    if (WindowUtil.exists()) {
      if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
        // eslint-disable-next-line no-console
        console.log('Pendo tracking event fired:', { name, data });
        return window.pendo.track(name, data);
      }
    }

    setTimeout(function () {
      WindowUtil.pendoTrack(name, data);
    }, 500);

    return null;
  },
  scrollToTop(elm) {
    if (this.exists()) {
      const options = {
        top: elm?.offsetTop || elm?.offsetParent?.offsetTop || 0,
        left: 0,
        behavior: 'smooth',
      };
      window.scrollTo(options);
    }
  },
  copyToClipboard(text) {
    let success = false;
    const targetEl = document.createElement('input');
    targetEl.style = 'position: absolute; top: -200vh; left: -200vw';
    document.body.appendChild(targetEl);
    targetEl.value = text;
    targetEl.select();
    targetEl.setSelectionRange(0, 99999); // For mobile devices

    try {
      navigator.clipboard.writeText(targetEl.value);
      success = true;
    } catch {
      success = false;
    }

    document.body.removeChild(targetEl);

    return success;
  },
};
