import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Suspense,
  Loader,
  Tabs,
  Tab,
  Gutter,
  ToastUtil,
  CondensedLarge,
  Banner,
} from '@axiom/ui';

import { useClientEngagementsCurrentSubmissionData } from './useClientEngagementsCurrentSubmissionData';
import { useClientEngagementsInterviewSubmissionData } from './useClientEngagementsInterviewSubmissionData';

export const ClientEngagementsEngagement = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { matchesCount, currentSubmission } =
    useClientEngagementsCurrentSubmissionData();
  const { interviewCount } = useClientEngagementsInterviewSubmissionData();
  const onKeyDown = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'p') {
      event.preventDefault();
      const toastName = `PREVENTPRINT`;
      const toasts = ToastUtil.getAll();
      const alreadyOpen = toasts.some(toast => toast.name === toastName);
      if (!alreadyOpen) {
        ToastUtil.add({
          name: toastName,
          type: 'info',
          children: (
            <>
              <CondensedLarge>
                To print this page, click “Share” at the top right of the
                screen, and then choose “Download All Matches".
              </CondensedLarge>
            </>
          ),
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const renderStartTab = () => {
    if (location.pathname.includes('interviews')) {
      return 'interviews';
    } else if (location.pathname.includes('engagement-details')) {
      return 'engagement-details';
    }
    return 'matches';
  };
  return (
    <Gutter vertical="24px" name="engagement-view">
      {!currentSubmission?.isQualificationComplete && (
        <Gutter bottom="16px">
          <Banner name="qualification-banner">
            You’ll be able to take action on these talent matches as soon as we
            confirm a few engagement details.
          </Banner>
        </Gutter>
      )}
      <Suspense fallback={<Loader type="bars" />}>
        <Tabs startTab={renderStartTab()}>
          <Tab
            label="Matches"
            name="matches"
            onClick={() => navigate('./matches')}
            countBadge={matchesCount}
          >
            <Gutter bottom="16px" />
            <Outlet />
          </Tab>
          <Tab
            label="Interviews"
            name="interviews"
            onClick={() => navigate('./interviews')}
            countBadge={interviewCount}
          >
            <Gutter bottom="16px" />
            <Outlet />
          </Tab>
          <Tab
            label="Details"
            name="engagement-details"
            onClick={() => navigate('./engagement-details')}
          >
            <Gutter bottom="16px" />
            <Outlet />
          </Tab>
        </Tabs>
      </Suspense>
    </Gutter>
  );
};
