import React from 'react';
import { bool } from 'prop-types';
import {
  Accordion,
  AccordionHeader,
  Accordions,
  AccordionSection,
  CondensedHeader,
  Gutter,
} from '@axiom/ui';

import { FormTalentSearchFiltersStore } from '../../stores/form-talent-search-filters';
import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';

import { TalentSearchSearchBox } from './TalentSearchSearchBox';
import { TalentSearchFiltersAdmissionsGroupings } from './TalentSearchFiltersAdmissionsGroupings';
import { TSFCheckboxGroup } from './TSFCheckboxGroup';

export const TalentSearchFiltersForm = ({ disabled }) => {
  return (
    <FormStateTreatment
      allowOverflow
      name="talent_search_filters_form_treatment"
      formStore={FormTalentSearchFiltersStore}
      showRefetchLoader={false}
      renderLoadedView={({ fireSubmit, formData, form }) => {
        return (
          <>
            <TalentSearchSearchBox
              data={formData}
              onBlur={fireSubmit}
              fireSubmit={fireSubmit}
              form={form}
              disabled={disabled}
            />
            <Gutter bottom="8px" />
            <Accordions minimal>
              {formData.categories.map((item, pIndex) => (
                <Accordion expanded={item.isOpen} key={item.title}>
                  <AccordionHeader name={`${item.title}_${item.selectedCount}`}>
                    <CondensedHeader>
                      {item.title}
                      {item.selectedCount > 0 && ` (${item.selectedCount})`}
                    </CondensedHeader>
                  </AccordionHeader>
                  <AccordionSection>
                    {item.title.toUpperCase() === 'ADMISSION' ? (
                      <TalentSearchFiltersAdmissionsGroupings data={item}>
                        {/**
                         * Passing in the prop 'data' below to prevent JS error in the console
                         * The real data is injected via TSFAdmissionsGroupings
                         * */}
                        <TSFCheckboxGroup
                          data={{}}
                          disabled={disabled}
                          fireSubmit={fireSubmit}
                          parentIndex={pIndex}
                        />
                      </TalentSearchFiltersAdmissionsGroupings>
                    ) : (
                      <TSFCheckboxGroup
                        data={item.items}
                        disabled={disabled}
                        fireSubmit={fireSubmit}
                        parentIndex={pIndex}
                      />
                    )}
                  </AccordionSection>
                </Accordion>
              ))}
            </Accordions>
          </>
        );
      }}
    />
  );
};

TalentSearchFiltersForm.propTypes = {
  disabled: bool,
};

TalentSearchFiltersForm.defaultProps = {
  disabled: false,
};
