import React from 'react';
import { bool, string } from 'prop-types';

import {
  LoadingCurtainCoverWrapper,
  LoadingCurtainSpinnerWrapper,
} from './LoadingCurtainStyles';

export const LoadingCurtain = ({ showSpinner, isFullscreen, className }) => (
  <LoadingCurtainCoverWrapper isFullscreen={isFullscreen} className={className}>
    <LoadingCurtainSpinnerWrapper>
      {showSpinner && <div className="spinner-border" color="primary" />}
    </LoadingCurtainSpinnerWrapper>
  </LoadingCurtainCoverWrapper>
);

LoadingCurtain.propTypes = {
  showSpinner: bool,
  isFullscreen: bool,
  className: string,
};

LoadingCurtain.defaultProps = {
  showSpinner: true,
  isFullscreen: false,
  className: '',
};
