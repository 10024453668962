import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';

export const CandidateAccountShape = shape({
  id: string,
  accountCandidatesStatus: string,
  addressCountry: string,
  addressState: string,
  billingUnitsPerWeek: number,
  calculatedDesiredWeeklyHours: number,
  calculatedDisplayName: string,
  calculatedFirstName: string,
  calculatedLastName: string,
  calculatedNotes: string,
  candidateHeader: string,
  candidateId: string,
  candidateSummary: string,
  daysSinceAvailabilityUpdate: number,
  daysSinceStatusChange: number,
  firstName: string,
  lastName: string,
  lawSchool: string,
  hasOtherEngagements: bool,
  maxDailyHours: number,
  notes: string,
  practiceStartYear: number,
  profileImageKey: string,
  profileImageName: string,
  profileStatus: string,
  publicCandidateSummary: string,
  soonestAccountEngagementEndDate: string,
  soonestEngagementEndDate: string,
  weeklyAvailability: number,
  weeklyTimeUnit: string,
  yearsOfExperience: number,
  barredLocations: arrayOf(
    shape({
      id: string,
      name: string,
    })
  ),
  occupationTypes: arrayOf(string),
  degrees: arrayOf(
    shape({
      id: string,
      degree: string,
      institution: string,
      isPrimary: bool,
      yearAwarded: number,
    })
  ),
  industries: arrayOf(
    shape({
      industryValue: string,
      // limitedCandidates = string
      // external/candidates = number (this is correct, since the number is a FlOAT)
      yearsOfExperience: oneOfType([number, string]),
    })
  ),
  jdEducation: shape({
    degree: string,
    institution: string,
    yearAwarded: number,
  }),
  practiceArea: shape({
    id: string,
    name: string,
    parentId: string,
    parent: shape({
      id: string,
      name: string,
      parentId: string,
    }),
  }),
});
