import GenericStore from '../classes/generic-store';
import { getSubmission } from '../api/submission/submission';
import { SubmissionShape } from '../models/submissions';

class SubmissionStoreClass extends GenericStore {
  load(submissionId) {
    return this.setState(getSubmission(submissionId));
  }

  getDataShape() {
    return SubmissionShape;
  }
}

export const SubmissionStore = new SubmissionStoreClass();
