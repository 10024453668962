import React from 'react';

import { Paragraph } from '../../content/Paragraph/Paragraph';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { Grid } from '../../layout/Grid/Grid';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { GridRow } from '../../layout/Grid/GridRow';
import { Gutter } from '../../layout/Gutter/Gutter';
import { DateUtil } from '../../../utils/date-util';

export const SubmissionPdfDescription = ({
  loginUrl,
}: {
  loginUrl: string;
}) => {
  return (
    <Gutter horizontal="24px">
      <Grid name="SUBMISSION_DESCRIPTION">
        <GridRow gutterBottom="16px">
          <GridColumn>
            <SmallHeader>
              Instantly request an interview or hire one or more of these legal
              pros
            </SmallHeader>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn>
            <Paragraph name="BODY_COPY">
              The talent presented here are available as of{' '}
              {DateUtil.displayLongMonthDayYear(new Date())} - but skills like
              theirs are in high demand. To take action on these or any
              additional talent profiles we've selected for your engagement,{' '}
              <a href={loginUrl} data-test="LOGIN_LINK">
                sign in to your Axiom account
              </a>
              .
            </Paragraph>
          </GridColumn>
        </GridRow>
      </Grid>
    </Gutter>
  );
};
