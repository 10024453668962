import { APP_NAMESPACE, AppStore } from './app-store';
import { PreloadedAppErrorsStore } from './preloaded-app-errors-store';
import { PreloadedUserStore } from './preloaded-user-store';
import { FormNotificationPreferencesStore } from './form-notification-preferences-store';
import { PreloadedPracticeAreasStore } from './preloaded-practice-areas-store';
import { PreloadedFeatureFlagsStore } from './preloaded-feature-flags-store';
import { FormDigitalBenchUpdateAccountDisplayNameStore } from './form-digital-bench-update-account-display-name-store';
import { AccountCandidatesStore } from './account-candidates-store';
import { FormDigitalBenchEditHighlightsModalStore } from './form-digital-bench-edit-highlights-modal-store';
import { FormDigitalBenchRequestRateModalStore } from './form-digital-bench-request-rate-modal-store';
import { FormDigitalBenchRequestUpdateModalStore } from './form-digital-bench-request-update-modal-store';
import { ShareLinkModalStore } from './share-link-modal-store';
import { FormDigitalBenchSortFilterStore } from './form-digital-bench-sort-filter-store';
import { FormDigitalBenchAskUsHowModalStore } from './form-digital-bench-ask-us-how-modal-store';
import { AccountSubmissionsStore } from './account-submissions-store';
import { SubmissionStore } from './submission-store';
import { FormTalentSearchFiltersStore } from './form-talent-search-filters';
import { SearchStore } from './search-store';
import { FormExternalSearchReachOutModalStore } from './form-external-search-reach-out-modal-store';
import { PreloadedSearchFiltersStore } from './preloaded-search-filters-store';
import { ExternalCandidateStore } from './external-candidate-store';
import { CandidateStore } from './candidate-store';
import { PreloadedBarredLocationsStore } from './preloaded-barred-locations-store';
import { FormInquireWithAxiomModalStore } from './form-inquire-with-axiom-modal-store';
import { EndOfTalentSearchStore } from './end-of-talent-search-store';
import { FavoriteCandidateStore } from './favorite-candidate-store';

export const stores = {
  ACCOUNT_CANDIDATES: AccountCandidatesStore,
  ACCOUNT_SUBMISSIONS: AccountSubmissionsStore,
  APP_ERRORS: PreloadedAppErrorsStore,
  [APP_NAMESPACE]: AppStore,
  BARRED_LOCATIONS: PreloadedBarredLocationsStore,
  CANDIDATE: CandidateStore,
  EXTERNAL_CANDIDATE: ExternalCandidateStore,
  FAVORITE_CANDIDATE: FavoriteCandidateStore,
  FEATURE_FLAGS: PreloadedFeatureFlagsStore,
  FORM_DIGITAL_BENCH_ASK_ME_HOW: FormDigitalBenchAskUsHowModalStore,
  FORM_DIGITAL_BENCH_EDIT_HIGHLIGHTS: FormDigitalBenchEditHighlightsModalStore,
  FORM_DIGITAL_BENCH_REQUEST_RATE: FormDigitalBenchRequestRateModalStore,
  FORM_DIGITAL_BENCH_REQUEST_UPDATE: FormDigitalBenchRequestUpdateModalStore,
  FORM_DIGITAL_BENCH_SORT_FILTER: FormDigitalBenchSortFilterStore,
  FORM_DIGITAL_BENCH_UPDATE_ACCOUNT_DISPLAY_NAME:
    FormDigitalBenchUpdateAccountDisplayNameStore,
  FORM_EXTERNAL_SEARCH_REACH_OUT: FormExternalSearchReachOutModalStore,
  FORM_INQUIRE_WITH_AXIOM: FormInquireWithAxiomModalStore,
  FORM_NOTIFICATION_PREFERENCES: FormNotificationPreferencesStore,
  FORM_TALENT_SEARCH_FILTERS: FormTalentSearchFiltersStore,
  OPTED_OUT: EndOfTalentSearchStore,
  PRACTICE_AREAS: PreloadedPracticeAreasStore,
  SEARCH: SearchStore,
  SEARCH_FILTERS: PreloadedSearchFiltersStore,
  SHARE_LINK_MODAL: ShareLinkModalStore,
  SUBMISSION: SubmissionStore,
  USER_CLIENT: PreloadedUserStore,
};

export const initialState = {};

// This whole if statement should automatically be removed during a production build, since
// it only applies to development mode.
if (module.hot) {
  // This solves the problem of GenericStores losing their identities and initialization
  // during hot module reload in development. The problem is that, when the modules are
  // reloaded, the store objects (like FormLoginStore) are re-created. However, the redux store
  // isn't reinitialized from scratch, i.e. store-manager's initializeStore() is not called.
  // This is correct behavior, we don't want to reinitialize the entire store, but it means
  // that the new GenericStore objects never get initializeNamespace and initializeDispatch
  // called on them, so their internal states aren't correct. To solve this, we need intercept
  // the reload and save the old internal states of those objects, then restore them when
  // we are reinitialized.

  // This logic is put in this file because, since this file imports all the GenericStores
  // in the project, it will itself be reloaded any time one of the stores is reloaded. The
  // alternative would be to duplicate (a slightly modified version of) this logic into
  // every file that creates a GenericStore.

  // dispose is called when we are about to be unloaded as part of a hot reload. We can
  // write values into the data object and the reload runtime will persist them into the
  // new version of this module that is being loaded.
  module.hot.dispose(data => {
    Object.keys(stores).forEach(namespace => {
      data[namespace] = { ...stores[namespace] };
    });
  });

  // data that was persisted from the previous version of our module shows up in module.hot.data,
  // so if this has content then it means we are coming after some older version of this module
  // that we replaced during a hot reload. We should attempt to restore any information present
  // in the data that was provided to us, as long as the store doesn't already have its own
  // version.
  if (module.hot.data) {
    Object.keys(module.hot.data).forEach(namespace => {
      const store = stores[namespace];
      const data = module.hot.data[namespace];
      Object.keys(data).forEach(key => {
        store[key] = store[key] || data[key];
      });
    });
  }
}
