export const TalentUtil = {
  formatYearsOfExperience(yearsOfExperience) {
    const stringified = String(yearsOfExperience);
    switch (stringified) {
      case 'null':
        return '';
      case '0':
        return '< 1';
      default:
        return stringified;
    }
  },
};
