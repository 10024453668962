import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, GridColumn, GridRow, Gutter, Header1 } from '@axiom/ui';
import { Trans } from '@lingui/macro';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { SubmissionStore } from '../../stores/submission-store';
import { QueryParamUtil } from '../../utils/query-param-util';
import { WindowUtil } from '../../utils/window-util';
import { NoSubmissions } from '../NoSubmissions/NoSubmissions';
import { SubmissionCard } from '../SubmissionCard/SubmissionCard';
import { Submission } from '../Submission/Submission';
import { CandidateAccountShape } from '../../models/candidate';
import { AccountSubmissionsStore } from '../../stores/account-submissions-store';

const useUpdateCurrentSubmission = (submissionId = null) => {
  const [currentSubmissionId, setCurrentSubmissionId] = useState(submissionId);

  const updateCurrentSubmission = newSubmissionId => {
    if (newSubmissionId !== currentSubmissionId) {
      const params = WindowUtil.getUrlQuery();
      SubmissionStore.load(newSubmissionId);

      WindowUtil.setUrlQuery(
        {
          ...params,
          submission: newSubmissionId,
        },
        true
      );

      setCurrentSubmissionId(newSubmissionId);
    } else {
      const params = WindowUtil.getUrlQuery();
      delete params.submission;
      WindowUtil.setUrlQuery(params, true);
      setCurrentSubmissionId(null);
    }
  };

  return [currentSubmissionId, updateCurrentSubmission];
};

const BenchSubmissionsComponent = ({ account, accountSubmissionsState }) => {
  const pdfRoot = useRef(null);

  const downloadPDF = () => pdfRoot.current && pdfRoot.current.save();

  useEffect(() => {
    if (account.id) {
      AccountSubmissionsStore.load(account.id);
    }
  }, []);

  const [currentSubmissionId, updateCurrentSubmission] =
    useUpdateCurrentSubmission();

  useEffect(() => {
    if (accountSubmissionsState?.data?.length && currentSubmissionId === null) {
      const { submissionId } = QueryParamUtil.getQueryParams();
      const newSubmissionId =
        submissionId || accountSubmissionsState.data[0].submissionId;

      updateCurrentSubmission(newSubmissionId);
    }
  }, [accountSubmissionsState]);

  if (!account.id) {
    return <NoSubmissions />;
  }

  return (
    <>
      <Gutter gutterType="margin" top="40px" bottom="20px">
        <Header1 accent>
          <Trans>Submissions</Trans>
        </Header1>
      </Gutter>

      <StoreStateTreatment
        allowOverflow
        name="ACCOUNT_SUBMISSIONS_PAGE"
        storeState={accountSubmissionsState}
        renderLoadedView={({ data: submissions }) => {
          if (!submissions.length) {
            return <NoSubmissions account={account} />;
          }
          return (
            <Grid stackable>
              <GridRow>
                <GridColumn width={3}>
                  {submissions.map(accountSubmission => {
                    const currentSelectedSubmission =
                      accountSubmission.submissionId === currentSubmissionId;
                    return (
                      <SubmissionCard
                        key={accountSubmission.submissionId}
                        accountId={account.id}
                        download={
                          currentSelectedSubmission ? downloadPDF : null
                        }
                        expanded={currentSelectedSubmission}
                        accountSubmission={accountSubmission}
                        setCurrentSubmissionId={updateCurrentSubmission}
                      />
                    );
                  })}
                </GridColumn>
                <GridColumn width={9}>
                  <Submission pdfRoot={pdfRoot} />
                </GridColumn>
              </GridRow>
            </Grid>
          );
        }}
        renderNonLoadedView={() => <NoSubmissions account={account} />}
      />
    </>
  );
};

BenchSubmissionsComponent.defaultProps = {
  account: {},
};

BenchSubmissionsComponent.propTypes = {
  account: CandidateAccountShape,
  accountSubmissionsState: AccountSubmissionsStore.getStateShape().isRequired,
};

export const BenchSubmissions = connect(state => ({
  accountSubmissionsState: AccountSubmissionsStore.select(state),
}))(BenchSubmissionsComponent);
