import { bool, shape } from 'prop-types';

import GenericStore from '../classes/generic-store';

export const FEATURE_FLAG_NAMES = {
  enablePendo: 'enable-afc-pendo',
  enableConsult: 'enable-afc-consult',
  enableSpvSigninButton: 'enable-afc-spv-signin-button',
  enableSpvProfileRate: 'enable-afc-spv-profile-rate',
};

const DEFAULT_FLAGS = {
  [FEATURE_FLAG_NAMES.enablePendo]: true,
  [FEATURE_FLAG_NAMES.enableConsult]: true,
  [FEATURE_FLAG_NAMES.enableSpvSigninButton]: true,
  [FEATURE_FLAG_NAMES.enableSpvProfileRate]: true,
};

class PreloadedFeatureFlagsStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      [FEATURE_FLAG_NAMES.enablePendo]: bool,
      [FEATURE_FLAG_NAMES.enableConsult]: bool,
      [FEATURE_FLAG_NAMES.enableSpvSigninButton]: bool,
      [FEATURE_FLAG_NAMES.enableSpvProfileRate]: bool,
    });
  }

  /**
   *
   * @param flags - comes in as {"enable-envoy-pendo": value}
   */
  load(flags = {}) {
    return this.setState({
      ...DEFAULT_FLAGS,
      ...flags,
    });
  }

  select(state) {
    return super.select(state).data || { ...DEFAULT_FLAGS };
  }

  isPendoEnabled(flags) {
    return flags[FEATURE_FLAG_NAMES.enablePendo];
  }

  isConsultEnabled(flags) {
    return flags[FEATURE_FLAG_NAMES.enableConsult];
  }

  isSigninButtonEnabled(flags) {
    return flags[FEATURE_FLAG_NAMES.enableSpvSigninButton];
  }

  isSpvProfileRateEnabled(flags) {
    return flags[FEATURE_FLAG_NAMES.enableSpvProfileRate];
  }
}
export const PreloadedFeatureFlagsStore = new PreloadedFeatureFlagsStoreClass();
