import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * ONLY USE THIS IN Router.jsx - see example below or in file
 *
 * 'url' prop is for re-directing to places outside of the app
 * 'to' prop is for re-directing to within the app
 *
 * 'to' is also only to be used when redirecting from/to a
 * path that has many child paths that are NOT being redirected
 * otherwise use the Navigate component from react-router
 *
 * Example:
 * FROM: one/two/three/four
 * TO:   apple/two/three/four
 * going from root of the path 'one' to a new root of 'apple'
 *
 * <Route path="one/*" element={<Redirecter to="../apple" />} />
 *
 * This allows you to redirect by only having to request the root
 * of the path to be changed. If you don't use this component
 * you have to rebuild the entire path tree for the one redirect
 * using the Navigate component
 * */

export const Redirecter = ({
  url,
  to,
}: {
  url?: string;
  to?: string;
}): null => {
  // Info on splats => https://reactrouter.com/en/main/route/route#splats
  const { '*': splat } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (url) {
      window.location.assign(url);
    }
  }, [url]);

  useEffect(() => {
    if (to) {
      const uri = `${to}${splat ? `/${splat}` : ''}`;

      navigate(uri);
    }
  }, [to]);

  return null;
};
