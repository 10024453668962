import styled from 'styled-components';
import { Gutter } from '@axiom/ui';

export const BCEmptyBenchContainer = styled(Gutter)`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.neutrals.lightest};
  text-align: center;
`;

export const StickySidebar = styled.div`
  position: sticky;
  top: 140px;
`;
