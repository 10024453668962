// filters are either a single value or an array or an object of arrays
export const SearchUtils = {
  countFilters(filterObj = {}) {
    const checker = (sum, value) => {
      if (!value) return sum;

      if (Array.isArray(value)) {
        sum += value.length;
      } else if (typeof value === 'string' || typeof value === 'number') {
        sum++;
      } else {
        // must be Object of Arrays
        Object.values(value).forEach(items => {
          return checker(sum, items);
        });
      }

      return sum;
    };

    return Object.values(filterObj).reduce(checker, 0);
  },
};
