import React from 'react';
import {
  Card,
  CardHeader,
  CardSection,
  Gutter,
  EmptyLayout,
  SmallHeader,
  Paragraph,
  useApi,
  ClientEngagementsCandidateHeader,
} from '@axiom/ui';
import { SubmissionCandidate } from '@axiom/validation';
import { useParams } from 'react-router-dom';

import FaceCardsGraphic from '../../public/svg/face-cards.svg';
import { AccountSubmissionsLegacyApi } from '../../api/account/account-submissions-legacy';
import { PracticeAreaApi } from '../../api/practiceAreas';
import { CandidateApi } from '../../api/candidate/candidate';
import { TaxonomyApi } from '../../api/taxonomy/taxonomy';

import { ClientEngagementsInterviewTalentProfile } from './ClientEngagementsInterviewTalentProfile';
import { useClientEngagementsInterviewSubmissionData } from './useClientEngagementsInterviewSubmissionData';

export const ClientEngagementsEngagementInterviewTalent = () => {
  const params = useParams();
  const { accountId, submissionId } = params;
  const { interviewingCandidateIds, currentInterviewSubmission } =
    useClientEngagementsInterviewSubmissionData({ accountId, submissionId });

  const interviewingCandidateSubmissions = useApi(
    ...interviewingCandidateIds.map(candidateId =>
      AccountSubmissionsLegacyApi.readCandidateAccountSubmissionDetails(
        accountId,
        submissionId,
        candidateId
      )
    )
  ).map(r => r.data) as SubmissionCandidate[];

  const allGroupedExperiences = useApi(
    ...interviewingCandidateIds.map(candidateId =>
      CandidateApi.readCandidateGroupedExperiences(candidateId, {
        submissionId,
      })
    )
  ).map(response => response.data);

  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );

  return (
    <div data-test="interview-talent-tab">
      {!interviewingCandidateSubmissions.length && (
        <Card name="INTERVIEW_EMPTY_CARD">
          <CardSection>
            <EmptyLayout
              graphic={
                <FaceCardsGraphic
                  width="117px"
                  height="91px"
                  data-test="face-cards-graphic"
                />
              }
              graphicPosition="left"
            >
              <SmallHeader>Upcoming interviews will appear here</SmallHeader>
              <Gutter bottom="16px" />
              <Paragraph>
                Let us know you want to interview talent on the matches tab.
                Upcoming interviews will appear here where you can provide
                feedback and continue to move them through the hiring process.
              </Paragraph>
            </EmptyLayout>
          </CardSection>
        </Card>
      )}
      {interviewingCandidateSubmissions.map((candidateSubmission, index) => {
        return (
          <Gutter
            bottom="16px"
            key={`interview-${candidateSubmission?.id}-${index + 1}`}
          >
            <Card>
              <CardHeader>
                <ClientEngagementsCandidateHeader
                  candidate={candidateSubmission}
                />
              </CardHeader>
              <CardSection>
                <ClientEngagementsInterviewTalentProfile
                  candidate={candidateSubmission}
                  groupedExperiences={allGroupedExperiences[index]}
                  submission={currentInterviewSubmission}
                  practiceAreas={practiceAreas}
                  taxonomy={taxonomy}
                />
              </CardSection>
            </Card>
          </Gutter>
        );
      })}
    </div>
  );
};
