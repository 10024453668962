import { Api } from '@axiom/ui';
import {
  Calendar,
  CalendarEventType,
  Candidate,
  Contact,
  TimeZoneIana,
  CalendarManualInterview,
  CalendarScheduledInterview,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class CalendarApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiAltUrl,
    });
  }

  createCalendar(body: {
    candidateId?: Candidate['id'];
    contactId?: Contact['id'];
    timezone: TimeZoneIana;
  }) {
    return super.write<{
      data: {
        id: Calendar['id'];
        timezone: TimeZoneIana;
      };
    }>({
      endpoint: `/calendars`,
      method: 'POST',
      body,
    });
  }

  readCalendar(calendarId: Calendar['id']) {
    return super.read<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}`,
      method: 'GET',
    });
  }

  createManualInterview(body: CalendarManualInterview) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/manualInterview`,
      method: 'POST',
      body,
    });
  }

  refreshCalendar(calendarId: Calendar['id']) {
    return super.invalidate(`/calendars/${calendarId}`);
  }

  refreshCalendarEvents(calendarId: Calendar['id']) {
    return super.invalidate(`/calendars/${calendarId}/events`);
  }

  createCalendarEvents(calendarId: Calendar['id'], body: CalendarEventType) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}/events`,
      method: 'POST',
      body,
    });
  }

  updateCalendarEvents(
    calendarId: Calendar['id'],
    eventId: CalendarEventType['id'],
    body: CalendarEventType
  ) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/${calendarId}/events/${eventId}`,
      method: 'PATCH',
      body,
    });
  }

  createScheduledCalendarInterview(body: CalendarScheduledInterview) {
    return super.write<{ data: Calendar }>({
      endpoint: `/calendars/schedule`,
      method: 'POST',
      body,
    });
  }
}

export const CalendarApi = new CalendarApiClass();
