import { shape, string } from 'prop-types';

import FormStore from '../classes/form-store';

class FormDigitalBenchSortFilterStoreClass extends FormStore {
  getDataShape() {
    return shape({
      sort: string,
      practiceAreaId: string,
      status: string,
      keyword: string,
    });
  }

  load(meta) {
    return this.setState({
      sort: meta.sort,
      practiceAreaId: meta.filters.practiceAreaId,
      status: meta.filters.status,
      keyword: meta.filters.keyword,
    });
  }

  validate() {
    return true;
  }
}

export const FormDigitalBenchSortFilterStore =
  new FormDigitalBenchSortFilterStoreClass();
