import React from 'react';
import { GridColumn, GridRow, Gutter, LargeHeader } from '@axiom/ui';

import { MyBenchCta } from './MyBenchCta';
import { BrowseTalentCta } from './BrowseTalentCta';

export const BrowseTalent = () => {
  return (
    <>
      <GridRow gutterBottom="16px">
        <GridColumn>
          <LargeHeader name="BROWSE_TALENT_HEADER">Browse talent</LargeHeader>
        </GridColumn>
      </GridRow>

      <GridRow>
        <GridColumn largeScreenWidth={6} tabletWidth={12} mobileWidth={12}>
          <MyBenchCta />
          <Gutter bottom="16px" only="smallScreen" />
        </GridColumn>

        <GridColumn largeScreenWidth={6} tabletWidth={12} mobileWidth={12}>
          <BrowseTalentCta />
        </GridColumn>
      </GridRow>
    </>
  );
};
